import GlobalHeading from '../../components/Global/GlobalHeading';
import { useEffect, useState } from 'react';
import { usePlatformAction } from '../../store/platform/platformAction';
import VoucherCardOld from './components/VoucherCardOld';
import { EmptyVoucher } from '../../images/svgs';
import { voucherEmptyIcon } from '../../images/others';
import { ReactComponent as Spinner } from '../../SVGIcons/spinner.svg';
const colors = {
  active: ['#48C7FF', '#FFBA53', '#C5A1FF', '#99DFFF', '#72E9CC'],
  redeemed: '#A8C1D3',
  expired: '#858585',
};
const VouchersOld = () => {
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getUserVouchers } = usePlatformAction();

  useEffect(() => {
    handleFetchVouchers();
  }, []);

  const handleFetchVouchers = async () => {
    const response = await getUserVouchers();
    if (response?.data?.data) {
      setVouchers(response.data.data);
    }
    setLoading(false);
  };
  if (loading) {
    return (
      <div className='flex justify-center items-center h-full'>
        <Spinner className="h-6 animate-spin" />
      </div>
    )
  }
  return (
    <div className="">
      <GlobalHeading
        heading={`My Vouchers`}
        className="flex-1 !text-defaultBlack mb-4"
      />
      {vouchers.length > 0 ? (
        <div className="flex flex-col gap-5">
          {
            vouchers.map((voucher: any, index) => (
              <VoucherCardOld
                key={voucher.voucherCode}
                voucher={voucher}
                backgroundColor={
                  voucher.status === 'REDEEMED'
                    ? colors.redeemed
                    : voucher.status === 'EXPIRED'
                      ? colors.expired
                      : colors.active[index % colors.active.length]
                }
              />
            ))
          }
        </div>
      ) : (
        <div className="flex flex-1 bg-white justify-center h-[60vh] items-center w-full rounded-3xl border border-[#E9E9E9]">
          <div className="flex flex-col items-center w-full gap-6">
            <div className="flex items-center justify-center h-auto">
              <div className="relative">
                <EmptyVoucher />
                <img
                  src={voucherEmptyIcon}
                  alt="No vouchers"
                  className="absolute top-7 left-[138px]"
                />
              </div>
            </div>
            <div className="flex flex-col items-center gap-2">
              <span className="text-primary xxs:text-sm  sm:text-[18px] font-medium">
                Sorry, No vouchers available
              </span>
              <span className="text-[#7D7D7D] xxs:text-[10px]  sm:text-xs font-normal">
                Check back later for exciting offers!
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VouchersOld;
