import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CardLayout from '../../../../../components/Cards/CardLayout';
import { ReactComponent as ArrowRight } from '../../../../../SVGIcons/arrow-right.svg';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Navigation } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import GlobalModal from '../../../../../components/Global/GlobalModal';
import SimpleSlider from '../../../../../components/Slider/CardSlider';
import useModalHook from '../../../../../hooks/useModalHook';
import {
  EmptyLicenseList,
} from '../../../../../images/wallets';
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction';
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice';
import { LicenseType } from '../../../../../store/cloudk/cloudkTypes';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import AddMintingToken from './AddMintingToken/AddMintingToken';
import LicenseCard from './LicenseCard';

const AllLicenses = () => {
  const navigate = useNavigate();
  const { open, handleClose, handleOpen } = useModalHook();
  const { allLicienceLoading, allLicencesData } = useSelector(selectCloudSlice);
  const { getActiveLicensesCountFunc, getAllLicenses, getSetCloudkSelectedLicense } = useCloudkActions();
  const sliderRef = useRef<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handlePrev = () => {
    if (sliderRef.current && currentIndex > 0) {
      sliderRef.current.slidePrev();
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNext = () => {
    if (sliderRef.current && !isAtEnd) {
      sliderRef.current.slideNext();
      setCurrentIndex(currentIndex + 1);
    }
  };
  useEffect(() => {
    if (!allLicencesData?.list) {
      getAllLicenses();
    } // if it's null, we need to call te inital api
  }, []);

  const handleMintingToken = (item: LicenseType) => {
    getSetCloudkSelectedLicense(item);
  };

  const licensesList = allLicencesData?.list ?? [];

  return (
    <CardLayout className="col-span-12 p-5 flex flex-col justify-between gap-2 !shadow-boxShadow bg-[#FFFFFF]">
      <section className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <h1 className="text-secondaryBlack font-medium sm:text-lg text-sm">
            Licenses
          </h1>
          {licensesList.length > 0 && (
            <div
              className=" top-5 justify-end right-5 bottom-[-6px] md:bottom-5 md:right-[10px] gap-1 flex cursor-pointer"
              onClick={() => {
                navigate('/defi-360/cloudk/Licenses');
              }}
            >
              <span className="text-sm font-normal">View All</span>
              <div className="h-5 w-5">
                <ArrowRight />
              </div>
            </div>
          )}
        </div>
      </section>

      <div className="relative">
        <div className="flex gap-2 overflow-hidden">
          {allLicienceLoading && licensesList.length === 0 ? (
            <div className="flex justify-center items-center flex-1  h-64 w-full">
              <div className="justify-center items-center self-center">
                <div className="flex flex-col gap-5">
                  <Spinner className="animate-spin h-6" />
                </div>
              </div>
            </div>
          ) : licensesList.length > 0 ? (
            <SimpleSlider
              onSwiper={(swiper) => {
                sliderRef.current = swiper;
              }}
              onSlideChange={(swiper) => {
                setCurrentIndex(swiper.activeIndex);
                setIsAtEnd(swiper.isEnd); // Update end status here
              }}
              className="w-full cursor-grab"
              spaceBetween={8}
              slidesPerView={'auto'}
              modules={[Navigation]}
              navigation={false} // Disable default Swiper navigation
              breakpoints={{
                768: {
                  slidesPerView: 'auto',
                },
              }}
            >
              {allLicencesData.list?.slice(0, 10).map((item, index) => (
                <SwiperSlide key={index} className="!w-56 !h-92 bg-[#FFFFFF]">
                  <LicenseCard key={item._id} item={item} handleMintingToken={handleMintingToken} handleOpen={handleOpen} />
                </SwiperSlide>
              ))}
            </SimpleSlider>
          ) : (
            <div className="flex-1 flex justify-center  items-center w-full xxs:px-2 sm:px-0  ">
              <div className="flex flex-col gap-2 items-center">
                <div className=" w-44 lg:w-64 h-auto">
                  <img src={EmptyLicenseList} alt="empty transactions" />
                </div>
                <div className="flex flex-col gap-2 items-center">
                  <span className="text-primary xxs:text-sm  sm:text-[16px] font-medium">
                    You don’t have any License
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        {licensesList.length > 0 && (
          <>
            {/* Fade effect */}
            <div className="absolute z-10 top-0 right-0 h-full w-[10%] pointer-events-none bg-gradient-to-l from-white md:block hidden"></div>
            <div className="md:hidden flex gap-4 mt-2">
              <button
                onClick={handlePrev}
                disabled={currentIndex === 0}
                className={` rounded-full ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'} transition`}
              >
                <IoIosArrowBack size={20} />
              </button>
              <button
                onClick={handleNext}
                disabled={isAtEnd}
                className={` rounded-full ${isAtEnd ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'} transition`}
              >
                <IoIosArrowForward size={20} />
              </button>
            </div>
          </>
        )}
      </div>

      <GlobalModal
        isOpen={open}
        onClose={handleClose}
        size="3xl"
        childrenClass="!px-0 !py-0 small-scroll"
        title={'Add Utility Minting Token'}
        noHeader={false}
        headerClassName="!p-6  font-medium text-lg text-[#0E173F]"
      >
        <AddMintingToken
          closeFunc={(message: string) => {
            handleClose();
            message === 'refreshLicenseCount' && getActiveLicensesCountFunc();
          }}
          allLicensesOrLinkLicense={true}
        />
      </GlobalModal>
    </CardLayout>
  );
};

export default AllLicenses;
