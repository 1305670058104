import axios, { AxiosError, AxiosResponse } from 'axios';
import { store } from '../../store';
import { showModal } from '../../store/modal/modalSlice';
import { getAccessToken, getLoginToken } from '../storage/protectedStorage';
import { ENV_VARS } from '../constants';

export default function ApiRequestHomnifi() {
  const accessToken = getAccessToken();
  const loginToken = getLoginToken();

  const request = axios.create({
    // baseURL: `${API_INSTANCE_HOMNIFI}`,
    baseURL: `${ENV_VARS.API_INSTANCE_HOMNIFI}`,
    headers: {
      Authorization: `Bearer ${accessToken ? accessToken : null}`,
      Bidtoken: `Token ${loginToken ? loginToken : null}`,
    },
    responseType: 'json',
    socketPath: null,
  });

  request.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => response,
    (error: AxiosError): Promise<AxiosError> | AxiosError => {
      if (error.code === 'ERR_NETWORK') {
        return Promise.reject(error);
      }

      if (error.response?.status === 401) {
        store.dispatch(showModal());
        return Promise.reject(error);
      }

      if (error.response?.status === 403) {
        store.dispatch(showModal());
        return Promise.reject(error);
      }

      if (error.response?.status === 503) {
        window.location.href = '/maintenance';
        // navigate('/maintenance');
        return Promise.reject(error);
      }

      return Promise.reject(error.response);
    }
  );

  return request;
}
