import { JSX } from 'react';
import ListingMembers from '../ListingMembers';

export default function Subordinates({ members, isPopup }: any): JSX.Element {
  return (
    <div>
      <div className="flex items-center justify-center">
        <div className="flex flex-row items-start justify-center ">
          {members?.map((member: any, index: number) => (
            <div key={index}>
              <div className="flex flex-col items-center justify-center">
                <div className="border-l-2 border-current border-dashed h-9"></div>
                <ListingMembers
                  data={member}
                  view={'TreeView'}
                  key={index}
                  isChild={true}
                  isPopup={isPopup}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
