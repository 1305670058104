import { useEffect, useState } from 'react';
import GaugeChart from '../../../../../../../components/Cloud/Test1Chart';
import { toFixedFormat } from '../../../../../../../utils/utils';
import CloudKButton from '../../../../Common/CloudkButton/CloudkButton';
import { useCloudkActions } from '../../../../../../../store/cloudk/cloudkAction';
import {
  IMinterRewards,
  IMinterTokenDetails,
  MinterDetails,
} from '../../../../Common/types';
import GlobalSkeleton from '../../../../../../../components/Global/GlobalSkeleton';
import Tooltips from '../../../../../../../components/Tooltips';
import { setDecimalPlaces } from '../../../../../../../utils/helper';

const MinterRewards = ({
  minter,
  onRefreshRewards,
}: {
  minter: MinterDetails;
  onRefreshRewards: () => void;
}) => {
  const [minterRewards, setMinterRewards] = useState<IMinterRewards>();
  const [loading, setLoading] = useState(false);
  const [loadingToken, setLoadingToken] = useState<string | null>(null);
  const { getMinterRewardFunc, hardwareGeneratedRewardClaimfunc } =
    useCloudkActions();

  useEffect(() => {
    fetchMinterRewards();
  }, []);

  const fetchMinterRewards = async () => {
    setLoading(true);
    const response = await getMinterRewardFunc(minter._id);
    if (response.data.data) {
      setMinterRewards(response.data.data);
    }
    setLoading(false);
  };

  const handleClaimTokenReward = async (token: IMinterTokenDetails) => {
    setLoadingToken(token._id);
    const response = await hardwareGeneratedRewardClaimfunc({
      userMinter: minter._id,
      tokenId: token._id,
    });
    setLoadingToken(null);
    if (response.data?.status) {
      fetchMinterRewards();
      onRefreshRewards();
    }
  };

  const nftStakePercentage =
    100 - (minter?.totalLockNft / minter?.minterData.maxNFTCount) * 100;

  return (
    <div className="flex flex-col flex-wrap items-stretch justify-between gap-4 md:flex-row">
      <div className="">
        <div>
          <div className="text-cardSecondaryText">Rewards Generated</div>
          {loading ? (
            <GlobalSkeleton width={100} height={28} />
          ) : (
            <div className="text-lg font-medium text-primary">
              {setDecimalPlaces(Number(minterRewards?.totalAmount || 0), 3)}
              {minterRewards?.tokenDetails?.tokenName ?? 'mLYK'}
            </div>
          )}
        </div>
        <div className="flex mt-5 md:mt-10 gap-7">
          {loading
            ? renderRewardsLoading()
            : minterRewards?.rewards.map((reward, index) => (
              <div>
                <div className="mb-1 text-sm text-cardSecondaryText">
                  {reward?.tokenDetails?.tokenName}
                </div>
                <div className="flex gap-3 align-baseline">
                  <div className="text-lg font-medium text-secondaryBlack">
                    {setDecimalPlaces(Number(reward.tokenAmount || 0), 3)}
                  </div>
                  <Tooltips
                    wrapperClassName="bg-white p-3 rounded-lg shadow text-sm"
                    content="No rewards available to claim."
                    isDisabled={reward.tokenAmount > 0}
                  >
                    <CloudKButton
                      text="Claim"
                      outlined
                      onClick={() =>
                        handleClaimTokenReward(reward.tokenDetails)
                      }
                      size="sm"
                      disabled={reward.tokenAmount === 0}
                      loading={loadingToken === reward.tokenDetails._id}
                    />
                  </Tooltips>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="flex justify-between p-4 border shadow rounded-xl">
        <div className="">
          <div className="mb-2 text-cardSecondaryText">
            <span className="font-medium text-3xl text-[#001540]">
              {minter?.totalLockNft || 0}
            </span>{' '}
            out of {minter.minterData.maxNFTCount || 0}
          </div>
          <div className="text-sm font-medium text-cardSecondaryText">
            Linked NFTS
          </div>
        </div>
        <div className="relative flex justify-center h-[80px] self-end">
          <GaugeChart
            containerWidth={168}
            containerHeight={90}
            strokeWidth={2}
            value={100 - nftStakePercentage}
            limit={100}
            startText=""
            endText={``}
            showStartText={false}
            showEndText={false}
            dotRadius={4}
          />
          <div className="absolute bottom-0 text-center">
            <div className="text-sm font-medium">
              {nftStakePercentage.toFixed(0)}%
            </div>
            <div className="text-[10px] font-light text-cardSecondaryText">
              Available Capacity
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function renderRewardsLoading() {
    return Array(2)
      .fill(null)
      .map((_, index) => <GlobalSkeleton width={100} height={52} />);
  }
};

export default MinterRewards;
