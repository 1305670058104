import { Suspense } from 'react';

import { createBrowserRouter, Navigate } from 'react-router-dom';

import AuthLayout from '../components/Auth/AuthLayout';

import VerificationLayout from '../components/Auth/VerificationLayout';

import PreLoader from '../components/Global/PreLoader';

import Layout from '../components/Layout';

import SuperNodeLayout from '../components/SuperNodeLayout';

import {
  AccountType,
  Cloudk2PurchasePage,
  // CountryPools,
  Defi,
  EmailSent,
  Cloud2,
  // ExternalApp,
  ForgetPassword,
  GoogleAuth,
  Legal,
  Login,
  Maintenance,
  MyFriendsPage,
  Newsletter,
  Notifiations,
  PageNotFound,
  Profile,
  ReferralLink,
  ResetPassword,
  Signup,
  SSOAuth,
  Success,
  // SuperNode,
  // Support,
  Terms,
  VerifyEmail,
  Walletk,
  // WalletNew,
  // WalletsDeposit,
  WalletsHistory,
  WalletsSwap,
  WalletsWithdraw,
  Wiki,
  Xera,
  // SNGP,
  Impersonate,
  Community,
  SpecialSwap,
  RewardsCalculator,
  // Vouchers,
  CloudKSoftwareUpgrade,
  CloudkOverviewLicenses,
  CloudkProcessedCheckout,
  CloudkLicenseUnlock,
  CloudKTransactionHistoryV2,
  WalleK,
} from '../pages';
import Cloudk from '../pages/Defi/Cloudk';
import RewardHistory from '../pages/Defi/Cloudk/RewardHistory/RewardHistory';
import Dashboard from '../pages/Dashboard';
import SNGP from '../pages/GlobalPool/SNGP';
import CountryPools from '../pages/GlobalPool/CountryPools';
import Support from '../pages/Support';
import Vouchers from '../pages/Vouchers';
import ExternalApp from '../pages/ExternalApp';
import Wallets from '../pages/Wallet';
import WalletsDeposit from '../pages/Wallet/WalletsDeposit';
import SuperNode from '../pages/SuperNode';
import { FEATURES } from './constants';
import VouchersOld from '../pages/Vouchers/VouchersOld';
import CloudKMintersList from '../pages/Defi/Cloudk/MintersList';
import SuperNode2 from '../pages/SuperNode2.0';
import SuperNodeMyTree from '../pages/MyTree';
import SuperNodeReports from '../pages/SuperNodeReports';
import Loader from '../components/loader/Loader';

export enum PAGES {
  LOGIN = '/auth/signin',
  DASHBOARD = '/',
  GOOGLE_AUTH = '/verification/google-authentication',
  VERIFY_EMAIL = '/auth/verify-account',
  REFERRAL_LINK = '/verification/referral-link',
  TERMS = '/verification/terms',
  ACCOUNT_TYPE = '/verification/account-type',
  REWARDS_CALCULATOR = '/defi-360/nodek/rewards-calculator',
}

export const router = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="signin" replace />,
      },
      {
        path: FEATURES.showSignup && 'signup',
        element: FEATURES.showSignup && <Signup />,
      },
      {
        path: 'signup-success',
        element: <Success />,
      },
      {
        path: 'signin',
        element: <Login />,
      },
      {
        path: 'forgot-password',
        element: <ForgetPassword />,
      },
      {
        path: 'forget-password-success',
        element: <Success />,
      },
      {
        path: 'verify-account',
        element: <VerifyEmail />,
      },
      {
        path: 'email-send',
        element: <EmailSent />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: '/verification',
    element: (
      <Suspense fallback={<PreLoader />}>
        <VerificationLayout />
      </Suspense>
    ),
    children: [
      {
        path: 'account-type',
        element: (
          <Suspense fallback={<PreLoader />}>
            <AccountType />
          </Suspense>
        ),
      },
      {
        path: 'terms',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Terms />
          </Suspense>
        ),
      },
      {
        path: 'google-authentication',
        element: (
          <Suspense fallback={<PreLoader />}>
            <GoogleAuth />
          </Suspense>
        ),
      },
      {
        path: 'referral-link',
        element: (
          <Suspense fallback={<PreLoader />}>
            <ReferralLink />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/',
    element: (
      // <Suspense fallback={<PreLoader />}>
      <Layout />
      // </Suspense>
    ),
    children: [
      {
        path: '/',
        element: (
          // <Suspense fallback={<PreLoader />}>
          <Dashboard />
          // </Suspense>
        ),
      },
      {
        path: 'wallet',
        element: (
          // <Suspense fallback={<PreLoader />}>
          <Wallets />
          // </Suspense>
        ),
      },
      {
        path: 'wallet/deposit',
        element: (
          // <Suspense fallback={<PreLoader />}>
          <WalletsDeposit />
          // </Suspense>
        ),
      },
      {
        path: 'wallet/withdraw',
        element: (
          <Suspense fallback={<PreLoader />}>
            <WalletsWithdraw />
          </Suspense>
        ),
      },
      {
        path: 'wallet/swap',
        element: (
          <Suspense fallback={<PreLoader />}>
            <WalletsSwap />
          </Suspense>
        ),
      },
      // {
      //   path: 'wallet/special-swap',
      //   element: (
      //     <Suspense fallback={<PreLoader />}>
      //       <SpecialSwap />
      //     </Suspense>
      //   ),
      // },
      {
        path: 'wallet/special-swap',
        element: (
          <Suspense fallback={<PreLoader />}>
            <SpecialSwap />
          </Suspense>
        ),
      },
      {
        path: 'wallet/history',
        element: (
          <Suspense fallback={<PreLoader />}>
            <WalletsHistory />
          </Suspense>
        ),
      },
      {
        path: 'defi-360',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Defi />
          </Suspense>
        ),
      },
      {
        path: FEATURES.showCloudK && 'defi-360/cloudk',
        element: FEATURES.showCloudK && (
          <Suspense fallback={<PreLoader />}>
            <Cloudk />
          </Suspense>
        ),
      },
      {
        path: 'defi-360/cloudk/transaction-history',
        element: (
          <Suspense fallback={<PreLoader />}>
            <CloudKTransactionHistoryV2 />
          </Suspense>
        ),
      },
      {
        path: 'defi-360/cloudk/reward-history',
        element: (
          <Suspense fallback={<PreLoader />}>
            <RewardHistory />
          </Suspense>
        ),
      },
      {
        path: 'defi-360/cloudk/license-unlock/:id',
        element: (
          <Suspense fallback={<PreLoader />}>
            <CloudkLicenseUnlock />
          </Suspense>
        ),
      },
      {
        path: 'defi-360/cloudk/software-upgrade',
        element: (
          <Suspense fallback={<PreLoader />}>
            <CloudKSoftwareUpgrade />
          </Suspense>
        ),
      },
      {
        path: 'defi-360/cloudk/Licenses',
        element: (
          <Suspense fallback={<PreLoader />}>
            <CloudkOverviewLicenses />
          </Suspense>
        ),
      },
      {
        path: 'defi-360/cloudk/minters',
        element: (
          <Suspense fallback={<PreLoader />}>
            <CloudKMintersList />
          </Suspense>
        ),
      },
      {
        path: '/defi-360/cloudk/checkout',
        element: (
          <Suspense fallback={<PreLoader />}>
            <CloudkProcessedCheckout />
          </Suspense>
        ),
      },
      {
        path: 'defi-360/nodek',
        element: (
          <Suspense fallback={<Loader />}>
            <Cloud2 />
          </Suspense>
        ),
      },
      {
        path: 'defi-360/nodek/purchase',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Cloudk2PurchasePage />
          </Suspense>
        ),
      },
      {
        path: 'defi-360/nodek/rewards-calculator',
        element: (
          <Suspense fallback={<PreLoader />}>
            <RewardsCalculator />
          </Suspense>
        ),
      },
      {
        path: 'walletk',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Walletk />
          </Suspense>
        ),
      },
      {
        path: FEATURES.showNewWallek && 'wallek',
        element: FEATURES.showNewWallek && (
          <Suspense fallback={<PreLoader />}>
            <WalleK />
          </Suspense>
        ),
      },
      {
        path: 'xera',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Xera />
          </Suspense>
        ),
      },
      {
        path: 'wiki',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Wiki />
          </Suspense>
        ),
      },
      {
        path: 'legal',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Legal />
          </Suspense>
        ),
      },
      {
        path: 'profile',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Profile />
          </Suspense>
        ),
      },
      {
        path: 'notification',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Notifiations />
          </Suspense>
        ),
      },
      {
        path: 'newsletter',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Newsletter />
          </Suspense>
        ),
      },
      {
        path: '/community',
        element: (
          <Suspense fallback={<PreLoader />}>
            <Community />
          </Suspense>
        ),
      },
      {
        path: '/community/super-node',
        element: (
          // <Suspense fallback={<PreLoader />}>
          <SuperNode />
          // </Suspense>
        ),
      },
      {
        path: FEATURES.showSuperNodeNew && '/community/super-node-2',
        element: FEATURES.showSuperNodeNew && <SuperNode2 />,
      },
      {
        path: FEATURES.showMyTree && '/community/my-tree',
        element: FEATURES.showMyTree && (
          // <Suspense fallback={<PreLoader />}>
          <SuperNodeMyTree />
          // </Suspense>
        ),
      },
      {
        path: '/community/supernode-reports',
        element: (
          // <Suspense fallback={<PreLoader />}>
          <SuperNodeReports />
          // </Suspense>
        ),
      },
      {
        path: '/community/friends',
        element: (
          <Suspense fallback={<PreLoader />}>
            <MyFriendsPage />
          </Suspense>
        ),
      },
      {
        path: '/global-pool',
        element: (
          // <Suspense fallback={<PreLoader />}>
          <SNGP />
          // </Suspense>
        ),
      },
      {
        path: '/global-pool/sngp',
        element: (
          // <Suspense fallback={<PreLoader />}>
          <SNGP />
          // </Suspense>
        ),
      },
      {
        path: '/global-pool/country-pools',
        element: (
          // <Suspense fallback={<PreLoader />}>
          <CountryPools />
          // </Suspense>
        ),
      },
      {
        path: 'external-apps',
        element: (
          // <Suspense fallback={<PreLoader />}>
          <ExternalApp />
          // </Suspense>
        ),
      },
      {
        path: 'vouchers',
        element:
          // <Suspense fallback={<PreLoader />}>
          FEATURES.showNewVouchers ? <Vouchers /> : <VouchersOld />,
        // </Suspense>
      },
      {
        path: 'support',
        element: (
          // <Suspense fallback={<PreLoader />}>
          <Support />
          // </Suspense>
        ),
      },
    ],
  },
  {
    path: '/maintenance',
    element: (
      <Suspense fallback={<PreLoader />}>
        <Maintenance />
      </Suspense>
    ),
  },
  {
    path: 'sso',
    element: (
      <Suspense fallback={<PreLoader />}>
        <SSOAuth />
      </Suspense>
    ),
  },
  {
    path: '/impersonate',
    element: (
      <Suspense fallback={<PreLoader />}>
        <Impersonate />
      </Suspense>
    ),
  },

  {
    path: '*',
    element: (
      <Suspense fallback={<PreLoader />}>
        <PageNotFound />
      </Suspense>
    ),
  },
]);
