import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

type Props = {
  value: number;
  isBlackThemed?: boolean;
  progressColor?: string;
  backgroundColor?: string;
  height?: number;
  borderRadius?: number;
};

const LinearProgressBar = ({
  value,
  isBlackThemed = false,
  progressColor = '#0E173F',
  backgroundColor = '#D9D9D9',
  height = 10,
  borderRadius = 5,
}: Props) => {
  const progressBarBackground = isBlackThemed
    ? progressColor
    : value < 20
      ? '#F23838'
      : value > 50
        ? '#5BAC0A'
        : '#308fe8';

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: height,
    borderRadius: borderRadius,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: isBlackThemed
        ? backgroundColor
        : theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: borderRadius,
      background: progressBarBackground,
    },
  }));

  return (
    <BorderLinearProgress
      variant="determinate"
      value={value > 100 ? 100 : value}
    />
  );
};

export default LinearProgressBar;
