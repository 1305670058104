import { memo, useEffect, useState } from 'react';
import CloudKTable from '../../../../Common/CloudKTable/CloudKTable';
import { MinterDetails } from '../../../../Common/types';
import { useCloudkActions } from '../../../../../../../store/cloudk/cloudkAction';
import {
  capitalizeFirstLetter,
  toFixedFormat,
} from '../../../../../../../utils/utils';
import moment from 'moment';
import { mapStatusColor } from '../../../../../../../utils/statusColors';
import { useNavigate } from 'react-router-dom';
import { EmptyHardwareList } from '../../../../../../../images/wallets';
import { setDecimalPlaces } from '../../../../../../../utils/helper';

const columns = [
  { label: 'License Name', key: 'licenseName' },
  { label: 'Type', key: 'token' },
  { label: 'Amount', key: 'amount' },
  { label: 'Status', key: 'status' },
  { label: 'Date', key: 'date' },
];

const MinterRewardHistory = ({
  minter,
  refreshRewards,
}: {
  minter: MinterDetails;
  refreshRewards: any;
}) => {
  const [loading, setLoading] = useState(true);
  const [rewards, setRewards] = useState([]);
  const { getCloudKHardwareTransactionHistoryV2 } = useCloudkActions();
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      setLoading(true);
      const response = await getCloudKHardwareTransactionHistoryV2(
        'rewards',
        { limit: 4 },
        minter.identifier
      );
      if (response.data?.data?.list) {
        setRewards(response.data?.data?.list);
      }
      setLoading(false);
    })();
  }, [refreshRewards]);

  const rows = rewards.map((reward: any) => ({
    id: reward._id,
    licenseName: reward.licenseName,
    token: reward.rewardToken?.toUpperCase(),
    amount: setDecimalPlaces(Number(reward.amount || 0), 3),
    status: (
      <span
        className="px-3 py-1 text-xs font-medium rounded-lg"
        style={mapStatusColor(reward.status)}
      >
        {capitalizeFirstLetter(reward.status)}
      </span>
    ),
    date: moment(reward.actionDate).format('lll'),
  }));

  const NoDataCompoent = (
    <div className="flex flex-col items-center w-full mt-3">
      <img
        src={EmptyHardwareList}
        alt="empty transactions"
        className="h-auto w-28"
      />
      <span className="mt-4 text-cardSecondaryText">No rewards found.</span>
    </div>
  );
  return (
    <div className="">
      <CloudKTable
        loading={loading}
        columns={columns}
        rows={rows}
        onlyTable
        wrapperClassNames="!border-0 !p-0 !shadow-none"
        skeletonRows={3}
        renderNoData={NoDataCompoent}
      />
      <div className="mt-5 text-end">
        <button
          onClick={() =>
            navigate(
              `/defi-360/cloudk/reward-history?type=Rewards&minterId=${minter.identifier}`
            )
          }
          className="text-sm underline text-primary"
        >
          View all rewards history
        </button>
      </div>
    </div>
  );
};

export default memo(MinterRewardHistory);
