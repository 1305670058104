//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

const name = 'walletData';

export type TPaginated = {
  totalPages: number;
  currentPage: number;
  totalCount: number;
};

export type TWithdrawAndTransferHistory = {
  list: {
    _id: string;
    id: number;
    time: string;
    type: string;
    withdrawToken: string;
    amount: number;
    destination: string;
    status: string;
    requestId: string;
  }[];
} & TPaginated;

export type TDepositHistory = {
  list: {
    id: string;
    time: string;
    type: string;
    amount: number;
    hash: string;
    status: string;
  }[];
} & TPaginated;

export type TSwapHistory = {
  list: {
    requestId: string;
    type: string;
    time: string;
    fromToken: string;
    toToken: string;
    amount: string;
    status: string;
  }[];
} & TPaginated;

// Define the type for the Network object
interface Network {
  _id: string;
  name: string;
  code: string;
  createdAt: string;
  updatedAt: string;
}

// Define the type for transaction settings
interface TrxSettings {
  swapEnabled: boolean;
  depositEnabled: boolean;
  withdrawEnabled: boolean;
}

// Define the type for the Token object
interface Token {
  _id: string;
  name: string;
  symbol: string;
  color: string;
  iconUrl: string;
  networkList: Network[];
  trxSettings?: TrxSettings;
  valueType?: string;
  showZeroBalance?: boolean;
}

// Define the type for the main object
export interface TokenData {
  tokenBalance: number;
  percentage: string;
  totalStaked?: number;
  id: string;
  token: Token;
  claimableReward?: number;
  totalAvailableToStake?: number;
  dollarBalance?: number | undefined;
  valueType?: string;
  showZeroBalance?: boolean;
}

export interface TokenInfo {
  token: string;
  balance: number;
  color: string;
  borderColor: string;
  iconUrl: string;
  trxSettings: TrxSettings;
  totalStaked?: number;
  claimableReward?: number;
  totalAvailableToStake?: number;
  tokenSymbol?: string;
  dollarBalance?: number | undefined;
  tokenBalance: number;
  valueType?: string;
  showZeroBalance?: boolean;
}

export interface WalletTokenWithPercentage {
  token: string;
  percentage: string;
  color: string;
}

export interface PieChartDataInterface {
  id: string;
  label: string;
  value: string;
  color: string;
  actualValue: string;
  dollarBalance: number;
}

type walletStakeAvailableType = {
  totalStaked: number;
  totalAvailableToStake: number;
};

export type TokenInfoArray = TokenInfo[];

export type TokenDataArray = TokenData[];

type State = {
  walletList: {
    total: number;
    wallets: TokenDataArray;
  } | null;
  walletBasedOnSymbol: {
    wallets: TokenDataArray;
  };
  networkList: any[];
  withdrawHistory: any[];
  transferHistory: any[];
  withdrawAndTransferHistory?: TWithdrawAndTransferHistory | null;
  swapHistory?: TSwapHistory | null;
  specialSwapHistoryLoading: boolean;
  specialSwapHistory: any | null;
  combinedSwapHistory?: any | null;
  depositHistory?: TDepositHistory | null;
  trxSettings: any;
  allTokensLoading: boolean;
  allTokens: any[] | null;
  withdrawFaqsLoading: any;
  withdrawFaqs: any;
  transferFaqsLoading: any;
  transferFaqs: any;
  swapFaqsLoading: boolean;
  swapFaqs: any | null;
  specialSwapFaqsLoading: any;
  specialSwapFaqs: any | null;
  depositFaqsLoading: boolean;
  depositFaqs: any | null;
  depositAddress: any;
  depositStackSettings: any;
  currentAttemptOfDepositAddressLoading: false;
  currentAttemptOfDepositAddress: number;
  maxAttemptsOfDepositAddressLoading: false;
  maxAttemptsOfDepositAddress: number;
  withdrawSettingsLoading: any;
  withdrawExternalSettings: any;
  withdrawInternalSettings: any;
  swapSettingsLoading: any;
  swapSettings: any | null;
  specialSwapSettingsLoading: any;
  specialSwapSettings: any;
  depositSettingsLoading: any;
  depositSettings: any;
  loading: boolean;
  graphLoading: boolean;
  walletDataBasedOnSymbolloading: boolean;
  walletGraphData: any | null;
  setWalletDataWithdrawAndTransferHistoryLoading?: boolean;
  getWalletDataDepositHistoryLoading?: boolean;
  isWalletDataDepositSettingsLoading: boolean;
  getWalletDataSwapHistoryLoading?: boolean;
  qrCodeLoader: boolean;
  getWalletDataLoading: boolean;
  walletUSDKRefresh: {
    lastSavedTime: number;
    isRefreshing: boolean;
    seconds: number;
  };
  walletStakeAvailableLoading: boolean;
  walletStakeAvailable: walletStakeAvailableType;
  selectedOptionForLineGraph: string;
  isOnChainWallet: boolean | null;
};

const initialState: State = {
  walletList: null,
  walletBasedOnSymbol: {
    wallets: [],
  },
  getWalletDataLoading: false,
  networkList: [],
  withdrawHistory: [],
  transferHistory: [],
  trxSettings: null,
  allTokensLoading: false,
  allTokens: null,
  withdrawFaqsLoading: false,
  withdrawFaqs: null,
  transferFaqsLoading: false,
  transferFaqs: null,
  swapFaqsLoading: false,
  swapFaqs: null,
  specialSwapFaqsLoading: null,
  specialSwapFaqs: null,
  depositFaqsLoading: false,
  depositFaqs: null,
  depositAddress: null,
  withdrawSettingsLoading: false,
  withdrawExternalSettings: null,
  withdrawInternalSettings: null,
  swapSettingsLoading: false,
  swapSettings: null,
  swapHistory: null,
  specialSwapHistoryLoading: false,
  specialSwapHistory: null,
  specialSwapSettingsLoading: false,
  specialSwapSettings: null,
  depositSettingsLoading: false,
  depositSettings: null,
  depositStackSettings: null,
  loading: false,
  graphLoading: false,
  walletDataBasedOnSymbolloading: false,
  walletGraphData: null,
  setWalletDataWithdrawAndTransferHistoryLoading: false,
  getWalletDataDepositHistoryLoading: false,
  isWalletDataDepositSettingsLoading: false,
  getWalletDataSwapHistoryLoading: false,
  qrCodeLoader: false,
  currentAttemptOfDepositAddressLoading: false,
  currentAttemptOfDepositAddress: 0,
  maxAttemptsOfDepositAddressLoading: false,
  maxAttemptsOfDepositAddress: 5,
  walletUSDKRefresh: {
    lastSavedTime: 0,
    seconds: 300,
    isRefreshing: false,
  },
  walletStakeAvailableLoading: false,
  walletStakeAvailable: { totalStaked: 0, totalAvailableToStake: 0 },
  withdrawAndTransferHistory: null,
  depositHistory: null,
  combinedSwapHistory: null,
  selectedOptionForLineGraph: 'weekly',
  isOnChainWallet: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setWalletDataWalletsList: (state: State, action: PayloadAction<any>) => {
      return { ...state, walletList: action.payload };
    },

    setWalletStakeAvailable: (
      state: State,
      action: PayloadAction<walletStakeAvailableType>
    ) => {
      return { ...state, walletStakeAvailable: action.payload };
    },

    setwalletStakeAvailableLoading: (
      state: State,
      action: PayloadAction<boolean>
    ) => {
      return { ...state, walletStakeAvailableLoading: action.payload };
    },

    setWalletDataBasedOnSymbol: (state: State, action: PayloadAction<any>) => {
      return { ...state, walletBasedOnSymbol: action.payload };
    },
    setWalletDataNetworksList: (state: State, action: PayloadAction<any>) => {
      return { ...state, networkList: action.payload };
    },
    setWalletDataWithdrawHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, withdrawHistory: action.payload };
    },
    setWalletDataTransferHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, transferHistory: action.payload };
    },
    setWalletDataWithdrawAndTransferHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, withdrawAndTransferHistory: action.payload };
    },
    setWalletDataSwapHistory: (state: State, action: PayloadAction<any>) => {
      return { ...state, swapHistory: action.payload };
    },
    setWalletDataSpecialSwapHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, specialSwapHistory: action.payload };
    },
    setWalletDataCombinedSwapHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, combinedSwapHistory: action.payload };
    },
    setWalletDataDepositHistory: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositHistory: action.payload };
    },
    setWalletDataTrxSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, trxSettings: action.payload };
    },
    setWalletDataAllTokens: (state: State, action: PayloadAction<any>) => {
      return { ...state, allTokens: action.payload };
    },
    setWalletDataWithdrawFaqs: (state: State, action: PayloadAction<any>) => {
      return { ...state, withdrawFaqs: action.payload };
    },
    setWalletDataTransferFaqs: (state: State, action: PayloadAction<any>) => {
      return { ...state, transferFaqs: action.payload };
    },
    setWalletDataSwapFaqs: (state: State, action: PayloadAction<any>) => {
      return { ...state, swapFaqs: action.payload };
    },
    setWalletDataSpecialSwapFaqs: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, specialSwapFaqs: action.payload };
    },
    setWalletDataDepositFaqs: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositFaqs: action.payload };
    },
    setDepositAddress: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositAddress: action.payload };
    },
    setCurrentAttemptDepositAddress: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, currentAttemptOfDepositAddress: action.payload };
    },
    setMaxAttemptsOfDepositAddress: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, maxAttemptsOfDepositAddress: action.payload };
    },
    setIsOnChainWallet: (state: State, action: PayloadAction<any>) => {
      return { ...state, isOnChainWallet: action.payload };
    },
    setQRLoader: (state: State, action: PayloadAction<any>) => {
      return { ...state, qrCodeLoader: action.payload };
    },
    setWithdrawSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setSwapSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, swapSettings: action.payload };
    },
    setSpecialSwapSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, specialSwapSettings: action.payload };
    },
    setDepositSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositSettings: action.payload };
    },
    setDepositStackSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositStackSettings: action.payload };
    },
    setLoading: (state: State, action: PayloadAction<any>) => {
      return { ...state, loading: action.payload };
    },
    setWalletDataSlice: (state: State, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setWalletUSDKRefresh: (state: State, action: PayloadAction<any>) => {
      return { ...state, walletUSDKRefresh: action.payload };
    },
    setSelectedOptionForLineGraph: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, selectedOptionForLineGraph: action.payload };
    },
  },
});

export const WALLETDATA_SLICE_NAME = name;
export const {
  setWalletDataWalletsList,
  setWalletDataBasedOnSymbol,
  setWalletDataNetworksList,
  setWalletDataWithdrawHistory,
  setWalletDataTransferHistory,
  setWalletDataWithdrawAndTransferHistory,
  setWalletDataSwapHistory,
  setWalletDataSpecialSwapHistory,
  setWalletDataCombinedSwapHistory,
  setWalletDataDepositHistory,
  setWalletDataTrxSettings,
  setWalletDataAllTokens,
  setWalletDataWithdrawFaqs,
  setWalletDataTransferFaqs,
  setWalletDataSwapFaqs,
  setWalletDataSpecialSwapFaqs,
  setWalletDataDepositFaqs,
  setDepositAddress,
  setCurrentAttemptDepositAddress,
  setMaxAttemptsOfDepositAddress,
  setWithdrawSettings,
  setSwapSettings,
  setSpecialSwapSettings,
  setDepositSettings,
  setWalletDataSlice,
  setLoading,
  setQRLoader,
  setWalletUSDKRefresh,
  setWalletStakeAvailable,
  setwalletStakeAvailableLoading,
  setDepositStackSettings,
  setSelectedOptionForLineGraph,
  setIsOnChainWallet,
} = slice.actions;
export const selectWalletData = (state: RootState) =>
  state[WALLETDATA_SLICE_NAME];
export default slice.reducer;
