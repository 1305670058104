import { useEffect } from 'react';
import { useControls } from 'react-zoom-pan-pinch';
import ZoomCtrlButton from '../ZoomCtrlButton';

type Props = {
  reset: boolean;
};

const ZoomControls = ({ reset }: Props) => {
  const { zoomIn, zoomOut, resetTransform } = useControls();

  useEffect(() => {
    if (reset) {
      resetTransform();
    }
  }, [reset]);

  return (
    <div className="flex flex-row items-center justify-end gap-3 ">
      <div className="flex gap-2">
        <ZoomCtrlButton text={'+'} onClick={() => zoomIn()} />
        <ZoomCtrlButton text={'-'} onClick={() => zoomOut()} />
      </div>

      <div className="hidden w-px h-10 bg-gray-400 xl:block" />

      <ZoomCtrlButton text={'Re-Center'} onClick={() => resetTransform()} />
    </div>
  );
};

export default ZoomControls;
