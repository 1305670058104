import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import GlobalButton from '../../../../../../components/Global/GlobalButton';
import GlobalModal from '../../../../../../components/Global/GlobalModal';
import GlobalTextInput from '../../../../../../components/Global/GlobalTextInput';
import Tooltips from '../../../../../../components/Tooltips';
import useModalHook from '../../../../../../hooks/useModalHook';
import { useCloudkActions } from '../../../../../../store/cloudk/cloudkAction';
import { selectCloudSlice } from '../../../../../../store/cloudk/cloudkSlice';
import { useGeneralActions } from '../../../../../../store/general/generalActions';
import { selectConvertedPrice } from '../../../../../../store/general/generalSlice';
import { direct_withdraw_percentage } from '../../../../../../utils/data/data';
import { setDecimalPlaces } from '../../../../../../utils/helper';
import { LICENSE_WALLET_BALANCE } from '../../../../../../utils/network/ApiEndpoints';
import ApiRequestCloudk from '../../../../../../utils/network/ApiRequestCloudk';
import { mapColumns } from '../../../shared/ActivityHistoryTable/utils';
import UpgradeStakePeriods from '../UpgradeStakePeriods';



const AddMintingToken = ({ allLicensesOrLinkLicense, closeFunc, page }: any) => {
  const { selectedMintingTokenLicense } = useSelector(selectCloudSlice);
  const [inputAmount, setInputAmount] = useState<number | null>(null)
  const { open, handleClose, handleOpen } = useModalHook();
  const [directAmountPercentage, setDirectAmountPercentage] = useState<number | null>(null)
  const [walletBalance, setWalletBalance] = useState(0);
  const [licenseLockupPeriod, setLicenseLockupPeriod] = useState<any>(selectedMintingTokenLicense?.lockedPeriod && (
    selectedMintingTokenLicense.lockedPeriod === "max"
      ? "Max Period"
      : `${selectedMintingTokenLicense.lockedPeriod} Months`
  ));
  const stakeAbleAmount = setDecimalPlaces(
    Number(selectedMintingTokenLicense.stakable || 0), 2) - setDecimalPlaces(Number(selectedMintingTokenLicense.staked || 0), 2);
  const { createMintingTokenLoading, transactionHistoryType, transactionHistoryFilters } = useSelector(selectCloudSlice)
  const { addMintingTokenFunc, getCloudKTransactionHistoryV2 } = useCloudkActions();
  const { GetConvertedPrice } = useGeneralActions();
  const convertedPrice = useSelector(selectConvertedPrice);

  const tabs = Object.keys(mapColumns).map((key) => ({
    label: key.replace('_', ' '),
    key,
  }));

  useEffect(() => {
    getLicenseWalletBalance();
    GetConvertedPrice();
  }, [])

  useEffect(() => {
    setLicenseLockupPeriod(selectedMintingTokenLicense?.lockedPeriod)
  }, [selectedMintingTokenLicense])


  const getLicenseWalletBalance = () => {
    return ApiRequestCloudk()
      .request({
        method: 'GET',
        url: LICENSE_WALLET_BALANCE,
      })
      .then((response) => {
        const balance = response.data?.data?.balance;
        setWalletBalance(balance);
        return balance;
      })
      .catch((error) => {
        toast.error("Error fetching license wallet balance:", error);
        return error;
      });
  };

  const handleLockupPeriod = () => {
    handleOpen();
  }
  // Compute error message
  const errorMessage =
    (inputAmount ?? 0) < 0
      ? 'You can’t give a negative value'
      : inputAmount && stakeAbleAmount === 0
        ? `Your Stakeable limit reached!`
        : (inputAmount && setDecimalPlaces(Number(inputAmount * convertedPrice || 0), 2) > setDecimalPlaces(
          Number(stakeAbleAmount || 0), 2)
          ? `You can't stake more than $${setDecimalPlaces(
            Number(stakeAbleAmount || 0), 2)}`
          : '');
  const isButtonDisabled = createMintingTokenLoading || !inputAmount || !!errorMessage;
  return (
    <div className="flex flex-col gap-2 border-t border-t-[#D5DBE0] p-6">
      <span className="font-medium text-[#292929]">Selected License</span>
      <span className="font-medium text-[#121212] text-2xl">
        {selectedMintingTokenLicense?.name}{' '}
      </span>

      <div className="flex flex-col gap-4 mt-5">
        <div className="flex flex-col gap-2 w-full">
          <div className="text-defaultBlack text-sm font-medium">
            Amount <span className="text-redDanger">*</span>
          </div>
          <div className="w-full relative">
            <GlobalTextInput
              name="amount"
              placeholder="0.00"
              type="number"
              className={'!rounded-[50px] bg-gray-50'}
              animationClasses="!rounded-[50px]"
              value={inputAmount || ''}
              style={{ cursor: 'default', appearance: 'none' }}

              errorMessage={errorMessage}

              errorStyle={'!text-left'}
              onChange={(e) => {
                const number = Number(e.target.value);
                setDirectAmountPercentage(0);
                setInputAmount(number);
              }}
            // max={setDecimalPlaces(
            //   Number(selectedMintingTokenLicense.stakable || 0), 2) - setDecimalPlaces(Number(selectedMintingTokenLicense.staked || 0), 2)}
            />
            {
              <span className="flex  h-full absolute right-3 top-2 py-2 text-[#AAAAAA] text-xs bg-bgSecondary">
                <span>Balance: </span>{' '}
                <span> {`${Number(walletBalance)?.toFixed(2)} mLYK`}</span>
              </span>
            }
          </div>
          {inputAmount && stakeAbleAmount > 0 ? <div className="ps-3 text-xs">
            Staking Amount {inputAmount}  mLYK = <span className='font-bold'>${setDecimalPlaces(
              Number(inputAmount * convertedPrice || 0), 2)}</span>
          </div> : ""}
          <div className="flex justify-between flex-wrap gap-4">
            <div className="flex gap-6 text-cardSecondaryText text-xs ps-3 xxs:flex-1 xs:flex-auto xxs:justify-between sm:justify-normal items-center">
              <div>
                <span>Staked: ${setDecimalPlaces(
                  Number(selectedMintingTokenLicense.staked || 0), 2)}</span>
              </div>
              <div>
                <span>Stakable: ${setDecimalPlaces(
                  Number(stakeAbleAmount || 0), 2)}</span>
              </div>
            </div>
            <div className="flex items-center gap-2 justify-end">
              {walletBalance
                ? direct_withdraw_percentage?.map(
                  (percentage: number, index: number) => (
                    <button
                      key={index}
                      onClick={() => {
                        setDirectAmountPercentage(percentage);
                        setInputAmount((walletBalance * percentage) / 100);
                      }}
                      className={` rounded-full px-3 py-1 text-xs text-textSecondary border
          ${directAmountPercentage === direct_withdraw_percentage[index]
                          ? 'bg-primary text-white border !border-buttonColor'
                          : 'border-secondary '
                        }
            `}
                    >
                      {percentage}%
                    </button>
                  )
                )
                : ''}
            </div>
          </div>
        </div>

        <div className="flex-1 flex items-start justify-between flex-col gap-5">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex gap-3 my-2 items-center">
              <span className="text-defaultBlack text-sm font-medium">
                Lockup Period :
              </span>
              <span className="text-defaultBlack text-sm  capitalize">

                {(licenseLockupPeriod === "max" || licenseLockupPeriod === "Max Period") ? 'Max Period' : licenseLockupPeriod.includes('Months') ? licenseLockupPeriod : `${licenseLockupPeriod} Months`}
              </span>
              {selectedMintingTokenLicense?.lockedPeriod !== 'max' && (
                // <Tooltips
                //   placement="top"
                //   containerClass=''
                //   content={
                //     selectedMintingTokenLicense.staked
                //       <= 0 ? (
                //       <div className="p-3 text-xs font-normal bg-white rounded-lg shadow-md w-52">
                //         Upgrade available only for licenses with a staked amount.
                //       </div>
                //     ) : (
                //       <></>
                //     )
                //   }
                // >
                  <button
                    className={`border flex justify-center items-center gap-1 text-sm border-[#00A5EF] text-[#00A5EF] rounded-2xl px-1 `}
                  // ${selectedMintingTokenLicense.staked
                  //   <= 0 ? 'border-gray-400 text-gray-400 cursor-not-allowed' : ''}
                    onClick={() => 
                      // selectedMintingTokenLicense.staked> 0 &&
                       handleLockupPeriod()}
                    // disabled={selectedMintingTokenLicense.staked
                    //   <= 0}
                  >
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.5 6L6 3.5L3.5 6M8.5 9.5L6 7L3.5 9.5"
                        stroke={
                          // selectedMintingTokenLicense.staked
                          // <= 0 ? 'gray' :
                           "#00A5EF"}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {`${!selectedMintingTokenLicense?.lockedPeriod ? 'Select' : 'Upgrade'}`}
                  </button>
                // </Tooltips>
              )}
            </div>
          </div>
        </div>

        <GlobalButton
          text="Update Changes"
          type="button"
          loading={createMintingTokenLoading}
          // disabled={!createMintingTokenLoading && !inputAmount}
          disabled={isButtonDisabled}

          classNames={`!w-full rounded-[10px] !text-base !py-[10px] px-5 min-w-max place-item-end 
           ${isButtonDisabled ? 'bg-[#C0D5DF]' : '!bg-updateBtnGredient'} font-medium !text-[#FFFFFF]`}
          onClick={async () => {
            const data = {
              amount: inputAmount,
              license: selectedMintingTokenLicense?._id,
            };
            await addMintingTokenFunc(data, page)
              .then((response) => {
                if (response.data.status) {
                  closeFunc(response.data.data.previousCollateral === 0 ? 'refreshLicenseCount' : null);
                }
                getCloudKTransactionHistoryV2(transactionHistoryType ?? tabs[0].key, transactionHistoryFilters ?? {
                  page: 1,
                  limit: 10,
                  licenseName: '',
                  sort: {},
                })

                return response;
              })
              .catch((error) => {
                return error;
              });
          }}
        />
      </div>

      <GlobalModal
        isOpen={open}
        onClose={handleClose}
        size="3xl"
        childrenClass="!px-0 !py-7 small-scroll"
        title={'Upgrade Stake Periods'}
        noHeader={false}
        headerClassName="!p-6 font-medium text-lg text-[#0E173F] border-b border-b-[#D5DBE0]"
      >
        <UpgradeStakePeriods
          closeFunc={handleClose}
          allLicensesOrLinkLicense={allLicensesOrLinkLicense}
          page={page}
        />
      </GlobalModal>
    </div>
  );
}

export default AddMintingToken
