import { useEffect, useState } from 'react';
import CloudKTable from '../../Common/CloudKTable/CloudKTable';
import classNames from 'classnames';
import { mapColumns } from './utils.tsx';
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction';
import CloudKSelect from '../../Common/CloudKSelect/CloudKSelect.tsx';
import {
  selectCloudSlice,
  setCloudkSlice,
} from '../../../../../store/cloudk/cloudkSlice.ts';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalizeFirstLetter,
  formatStatusToDispaly,
  lowercaseFirstLetter,
  toFixedFormat,
} from '../../../../../utils/utils.ts';
import CloudkDatePicker from '../../Common/CloudKDatepicker/CloudkDatepicker.tsx';
import ApiRequestCloudk from '../../../../../utils/network/ApiRequestCloudk.ts';
import CsvDownload from 'react-csv-downloader';
import { CLOUDK_TRANSACTION_HISTORY_V2 } from '../../../../../utils/network/ApiEndpoints.ts';
import { EmptyWithdrawHistoryGif } from '../../../../../images/wallets';

import { DownloadIcon } from '../../../../../images/svgs';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Tooltips from '../../../../../components/Tooltips/index.tsx';
const tabs = Object.keys(mapColumns).map((key) => ({
  label: key.replace('_', ' '),
  key,
}));

const ActivityHistoryTable = ({ limit = 10, showPreservedData = true }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    transactionHistory,
    transactionHistoryLoading,
    transactionHistoryType,
    transactionHistoryLicenseIdsFilter,
    transactionHistoryStatusesFilter,
    transactionHistoryTotalPages,
    transactionHistoryFilters,
  } = useSelector(selectCloudSlice);

  const [selectedTab, setSelectedTab] = useState(
    transactionHistoryType ?? tabs[0].key
  );
  const [didMount, setDidMount] = useState(false);
  const [filters, setFilters] = useState<any>(
    transactionHistoryFilters
      ? {
          ...transactionHistoryFilters,
          limit: limit,
        }
      : {
          page: 1,
          limit: 10,
          licenseName: '',
          sort: {},
        }
  );

  const { getCloudKTransactionHistoryV2, getCloudKTransactionHistoryFilters } =
    useCloudkActions();

  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (!transactionHistoryLicenseIdsFilter || didMount) {
      getCloudKTransactionHistoryFilters(selectedTab.toLowerCase());
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!showPreservedData || !transactionHistory || didMount) {
      const params = {
        ...filters,
      };
      if (params.fromDate)
        params.fromDate = moment(params.fromDate).format('YYYY-MM-DD');
      if (params.toDate)
        params.toDate = moment(params.toDate).format('YYYY-MM-DD');
      getCloudKTransactionHistoryV2(selectedTab, params);
      dispatch(setCloudkSlice({ transactionHistoryFilters: filters }));
    }
  }, [selectedTab, filters]);

  const handleResetFilters = () => {
    setFilters({ currentPage: 1, limit: limit });
    dispatch(
      setCloudkSlice({
        transactionHistoryFilters: { currentPage: 1, limit: 10 },
      })
    );
  };
  const handleDownloadCSV = async () => {
    const type = selectedTab.toLocaleLowerCase();
    const params = { ...filters };
    if (params.fromDate)
      params.fromDate = moment(params.fromDate).format('YYYY-MM-DD');
    if (params.toDate)
      params.toDate = moment(params.toDate).format('YYYY-MM-DD');
    const response = await ApiRequestCloudk().get(
      `${CLOUDK_TRANSACTION_HISTORY_V2}/${type}`,
      {
        params: {
          ...params,
          limit: 999999999,
          page: 1,
        },
      }
    );
    if (response.data?.data?.list) {
      return Promise.resolve(response.data?.data?.list);
    }
  };

  function renderRowObject(transaction: any): any {
    const tableColumns = mapColumns[selectedTab];
    const rowObject = { ...transaction, id: transaction._id };

    tableColumns.forEach((column: any) => {
      const columnKey = column.key;
      const valueKey = column.mapValueKey?.(transaction) ?? columnKey;
      const mapContent = column.mapContent;

      // Check if mapContent exists for the column, and apply it if so
      if (mapContent && transaction[valueKey]) {
        if (columnKey == 'stakedAmount') {
          rowObject[columnKey] = mapContent(transaction, valueKey);
        } else if (
          columnKey == 'stakePeriod' ||
          columnKey == 'newStakingPeriod' ||
          columnKey == 'previousStakingPeriod'
        ) {
          rowObject[columnKey] = mapContent(
            `${transaction[valueKey] == 'max' ? 'Max Period' : `${transaction[valueKey]} Months`}`
          );
        } else {
          rowObject[columnKey] = mapContent(transaction[valueKey]);
        }
      } else {
        if (columnKey == 'amount' && selectedTab == 'Collaterals') {
          rowObject[columnKey] =
            `${toFixedFormat(transaction?.[valueKey] ?? 0, 2)}  ${lowercaseFirstLetter(`${transaction?.['rewardToken'] ?? ''}`)}`;
        } else {
          rowObject[columnKey] = transaction?.[valueKey] ?? '-'; // Just assign the value if no mapContent function
        }
      }
    });

    return rowObject;
  }
  function mapLicenseFilterOptions() {
    return (transactionHistoryLicenseIdsFilter ?? []).map((license: any) => ({
      label: license.identifier,
      value: license.identifier,
    }));
  }
  const rows = (transactionHistory ?? [])
    .slice(0, limit)
    .map((transaction: any) => renderRowObject(transaction));

  const NoDataCompoent = (
    <div className="flex flex-col items-center w-full mt-5">
      <img
        src={EmptyWithdrawHistoryGif}
        alt="empty transactions"
        className="h-auto w-44"
      />
      <span className="text-primary xxs:text-sm  sm:text-[16px] font-medium mt-2">
        Sorry, No {selectedTab.replace('_', ' ')} Found.
      </span>
    </div>
  );

  return (
    <CloudKTable
      title="Transaction History"
      loading={transactionHistoryLoading}
      currentPage={filters.page}
      totalPages={limit < 10 ? 0 : transactionHistoryTotalPages}
      sorting={filters.sort}
      renderNoData={NoDataCompoent}
      onSortChange={(values) =>
        setFilters((prev: any) => ({ ...prev, sort: values }))
      }
      onPageChange={(page) =>
        setFilters((prev: any) => ({ ...prev, page: page }))
      }
      filters={[
        <CloudKSelect
          options={mapLicenseFilterOptions()}
          placeholder="License ID"
          onChange={(option) =>
            setFilters((prev: any) => ({
              ...prev,
              licenseName: option.value,
              page: 1,
            }))
          } // Handle the option change
          selectedValue={filters.licenseName}
          // disabled={transactionHistory?.length ? false : true}
        />,
        <CloudkDatePicker
          placeholder="From Date"
          date={filters.fromDate}
          maxDate={filters.toDate || new Date()}
          selectsStart={true}
          startDate={filters.fromDate}
          endDate={filters.toDate}
          onDateChange={(date) =>
            setFilters((prev: any) => ({
              ...prev,
              fromDate: date,
              page: 1,
              sort: {
                [mapColumns[selectedTab]?.find((tab: any) =>
                  tab.label.includes('Date')
                ).key]: 'asc',
              },
            }))
          }
          // disabled={transactionHistory?.length ? false : true}
        />,
        <CloudkDatePicker
          placeholder="To Date"
          date={filters.toDate}
          minDate={filters.fromDate}
          maxDate={new Date()}
          selectsEnd={true}
          startDate={filters.fromDate}
          endDate={filters.toDate}
          onDateChange={(date) =>
            setFilters((prev: any) => ({ ...prev, toDate: date, page: 1 }))
          }
          // disabled={transactionHistory?.length ? false : true}
        />,
        <CloudKSelect
          options={mapStatusFilterOptions()}
          placeholder="Status"
          onChange={(option) =>
            setFilters((prev: any) => ({
              ...prev,
              status: option.value,
              page: 1,
            }))
          } // Handle the option change
          selectedValue={filters.status}
          // disabled={transactionHistory?.length ? false : true}
        />,

        <button
          className="h-10 text-xs font-medium text-primary disabled:text-primaryGrey"
          onClick={handleResetFilters}
          disabled={
            !filters.fromDate &&
            !filters.licenseName &&
            !filters.status &&
            !filters.sort
          }
        >
          Reset
        </button>,
        <CsvDownload
          className="flex items-center gap-1.5 text-white bg-primary hover:bg-primary/90 px-3 py-1.5 rounded-lg text-sm cursor-pointer"
          datas={handleDownloadCSV}
          filename={selectedTab + '.csv'}
          columns={mapColumns[selectedTab]?.map((column: any) => ({
            id: column.key,
            displayName: column.label,
          }))}
        >
          <DownloadIcon className="w-4 h-4 text-white" />
          <span>Download</span>
        </CsvDownload>,
      ]}
      tabsFilter={
        <div className="flex gap-6 mb-10 overflow-auto lg:-mt-4">
          {tabs.map((tab) => (
            <button
              key={tab.key}
              className={classNames(
                'text-sm border-b-2 border-b-transparent text-primaryGrey flex-shrink-0 text-nowrap',
                tab.key == selectedTab &&
                  'text-secondaryBlack !border-b-primary font-medium'
              )}
              onClick={() => {
                setSelectedTab(tab.key);
                handleResetFilters();
                setFilters((prev: any) => ({
                  ...prev,
                  page: 1,
                  status: '',
                  fromDate: null,
                  toDate: null,
                  sort: {},
                }));
              }}
            >
              {tab.label}
            </button>
          ))}
        </div>
      }
      rows={rows}
      columns={mapColumns[selectedTab]}
      Footer={
        limit < 10 &&
        transactionHistoryTotalPages > 1 &&
        transactionHistory?.length ? (
          <div className="flex justify-end">
            <button
              onClick={() => navigate('/defi-360/cloudk/transaction-history')}
              className="text-sm underline bg-transparent text-primary"
            >
              View all transactions
            </button>
          </div>
        ) : null
      }
    />
  );

  function mapStatusFilterOptions() {
    return (transactionHistoryStatusesFilter ?? []).map((status) => ({
      label: formatStatusToDispaly(status),
      value: status,
    }));
  }
};

export default ActivityHistoryTable;
