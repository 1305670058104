import React, { ReactElement } from 'react';
import HoverBorderAnimation from '../../../components/HoverBorderAnimation';
import CurrentLossStatistics from './CurrentLossStatistics';
import SuperNodeFilterTabs from './SuperNodeFilterTabs.tsx';
import { Spinner } from '../../../images/svgs';

export interface CurrentLossRewardsCard {
  mainTitle: string;
  statisticsTop: {
    title: string;
    tooltipContent: string;
    amount: number;
    token?: string | null;
  }[];
  statisticsBottom: {
    title: string;
    tooltipContent: string;
    amount: number;
    token?: string | null;
  }[];
  layoutId: string;
  getData: any;
  isLoading?: boolean;
  selectedFilterTab?: any
}

function CurrentLossRewardsCard({
  mainTitle,
  statisticsTop,
  statisticsBottom,
  layoutId,
  getData,
  isLoading,
  selectedFilterTab
}: CurrentLossRewardsCard): ReactElement {
  const onFilterChange = (event: string): void => {
    getData({ period: event });
  };
  return (
    <HoverBorderAnimation className="flex flex-col flex-1 rounded-[8px] border border-solid border-borderBlue bg-white radius-12px min-h-full">
      <div className="flex flex-col justify-between flex-1 h-full">
        <div className="flex flex-wrap justify-between items-center bg-red-500 rounded-t-[8px] px-4 py-2 space-y-3 lg:space-y-0">
          <p className="w-auto font-medium text-white text-md">{mainTitle}</p>
          <SuperNodeFilterTabs
            layoutID={layoutId}
            onFilterChange={onFilterChange}
            filterColor={'bg-gradient-primary-red'}
            hoverTextColor={false}
            selectedFilter={selectedFilterTab}
          />
        </div>
        <div className="relative">
          <CurrentLossStatistics statistics={statisticsTop} />
          <hr />
          <CurrentLossStatistics statistics={statisticsBottom} />
          {isLoading && (
            <div className="opacity-70 w-[100%] bg-white h-[100%] absolute top-1 flex justify-center items-center z-[50] ">
              <Spinner className="h-6 text-primary animate-spin" />
            </div>
          )}
        </div>
      </div>
    </HoverBorderAnimation>
  );
}

export default CurrentLossRewardsCard;
