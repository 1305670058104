import { motion } from 'framer-motion';

interface Props {
  selectedButton: string;
  handleButtonClick: (key: string) => Promise<void>;
  tabs: { key: string; label: string }[];
  className?: string;
  parentClassName?: string;

}

const Tabs = ({
  selectedButton,
  handleButtonClick,
  tabs,
  className = '',
  parentClassName,
}: Props) => {
  return (
    <div
      className={`flex flex-wrap lg:space-y-0 items-center gap-2 py-5 pr-4 xl:pr-0 md:pl-7 pl-4 ${parentClassName} `}
    >
      {tabs.map((tab, index) => (
        <button
          key={tab.key}
          onClick={(): Promise<void> => handleButtonClick(tab.key)}
          className={`px-2 py-1 sm:px-8 sm:py-1.5 max-w-44 font-normal rounded-md transition-all
                      hover:text-primary bg-transparent border flex-shrink-0 ${index !== tabs.length - 1 ? 'mr-2' : ''} ${selectedButton === tab.key && 'border-primary !bg-primary/15 text-primary'} ${className}`}
          style={{
            transformStyle: 'preserve-3d',
          }}
        >
          {selectedButton === tab.key && (
            <motion.span
              layoutId="bubble"
              className="absolute inset-0 bg-inherit rounded-lg !border-primary"
              transition={{
                type: 'spring',
                bounce: 0.2,
                duration: 0.6,
              }}
            />
          )}
          <span
            className={`relative block ${selectedButton === tab.key ? 'text-primary' : 'text-primaryGrey'}`}
          >
            {tab.label}
          </span>
        </button>
      ))}
    </div>
  );
};

export default Tabs;
