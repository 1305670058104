import { JSX } from "react";

type Props = {
  text: any;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function ZoomCtrlButton({
  text,
  onClick = (e) => {},
}: Props): JSX.Element {
  return (
    <button
      className={`text-center text-sm bg-white border-[1px] group rounded-[4px] h-10 
                border-cardText text-cardText
                font-medium py-1 px-4`}
      onClick={onClick}
    >
      {text && text}
    </button>
  );
}
