import React from 'react';
import { InfoPoolIcon } from '../../images/svgs';
import GlobalTooltip from '../Tooltips';
import { formatDateTime, formatDateToString } from '../../utils/helper';

const GlobalImageCardList = ({
  countryName,
  date,
  component,
  amount,
  rewards,
}: {
  countryName: string;
  date: string;
  component: React.ReactNode;
  rewards?: number;
  amount?: number;
}) => {
  return (
    <div className="flex flex-col sm:flex-row gap-3 sm:gap-0 items-start justify-between text-xs lg:text-sm  xl:!text-md   bg-[#FAFAFA] p-4 sm:items-center   mb-3 rounded-lg border border-[#E6E6E6] shadow-sm shadow-[#E6E6E6] relative ">
      <div
        className={'flex flex-row items-center justify-center gap-2 sm:gap-5 '}
      >
        <div className="w-6 lg:w-9">{component}</div>
        <p className="!font-normal text-sm sm:text-xs lg:text-sm ">
          {countryName}
        </p>
      </div>
      <div
        className={
          'flex flex-col sm:flex-row items-start justify-start  md:items-center md:justify-evenly w-full sm:w-1/2 gap-4'
        }
      >
        {amount ? (
          <div
            className={
              'flex flex-row sm:flex-col items-center justify-center gap-2'
            }
          >
            <p className="!font-normal text-xs lg:text-xs text-primaryGrey  ">
              {'Total Points'}
            </p>
            <p className="!font-normal text-xs lg:text-sm ">{amount}</p>
          </div>
        ) : null}
        {rewards ? (
          <div
            className={
              'flex flex-row sm:flex-col  items-center justify-center gap-2'
            }
          >
            <p className="!font-normal text-xs lg:text-xs text-primaryGrey  ">
              {'Rewards'}
            </p>
            <p className="!font-normal text-xs lg:text-sm ">{rewards}</p>
          </div>
        ) : null}
        <div
          className={
            'flex flex-row sm:flex-col  items-center justify-center gap-2'
          }
        >
          <p className="!font-normal text-xs lg:text-xs text-primaryGrey  ">
            {'Start Date'}
          </p>
          <p className=" !font-normal text-xs lg:text-sm">
            {date ? formatDateTime(date) : '-'}
          </p>
        </div>
      </div>

      {/*<div className="flex justify-end absolute sm:relative items-center z-10  right-2 sm:right-0">*/}
      {/*  <GlobalTooltip*/}
      {/*     content={`Coming soon!`}*/}
      {/*    placement="top-end"*/}
      {/*    containerClass="!self-start !text-[10px]"*/}
      {/*    showArrow={true}*/}
      {/*    classContainer={{*/}
      {/*      base: [*/}
      {/*        'before:bg-white before:z-1 before:border-b-[1px] before:border-r-[1px] before:border-[#007AFF33]',*/}
      {/*      ],*/}
      {/*      content: [*/}
      {/*        'py-4 px-2 shadow-sm',*/}
      {/*        'text-black bg-white rounded-md border-[1px] text-[10px] max-w-[150px] border-[#007AFF33]  ',*/}
      {/*      ],*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <InfoPoolIcon></InfoPoolIcon>*/}
      {/*  </GlobalTooltip> */}
      {/*</div>*/}
    </div>
  );
};

export default GlobalImageCardList;
