import { Pagination } from '@nextui-org/pagination';
import { JSX, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

// import { Pagination, Stack } from "@mui/material";
type PaginationButtonsProps = {
  count?: any;
  totalPage?: number; // Add this line
  onChange: any;
  page?: number;
  paginationPosition?: 'center' | 'right' | 'left';
};

const PaginationButtons = ({
  onChange,
  totalPage = 1,
  page,
  paginationPosition = 'center',
}: PaginationButtonsProps): JSX.Element => {
  const pageNumber = useRef(1);
  const position =
    paginationPosition === 'center'
      ? 'justify-center'
      : paginationPosition === 'right'
        ? 'flex justify-end'
        : 'flex justify-start';

  const isSmallDeviceView = useMediaQuery({
    query: '(max-width: 425px)',
  });

  return (
    <div
      className={`flex ${position} max-[320px]:justify-center ${pageNumber.current.toString().length > 5 ? 'overflow-hidden max-[320px]:overflow-x-scroll pl-16 ' : ''}`}
    >
      <div className="flex justify-center p-1 space-x-1 rounded-lg">
        {/* Large Screens */}
        <Pagination
          showControls
          total={totalPage}
          page={page}
          siblings={isSmallDeviceView ? 0 : 1}
          size={isSmallDeviceView ? 'sm' : 'md'}
          onChange={(_page: number) => {
            pageNumber.current = _page;
            onChange(_page);
          }}
          classNames={{
            base: 'inline-block',
            item: 'border border-borderBlue rounded-lg text-primaryGrey font-normal w-fit px-1 max-[400px]:text-[9.8px] text-[14px] lg:text-[16px] max-[400px]:font-700',
            cursor:
              'border border-borderBlue rounded-lg bg-[#0E173F26] text-primaryGrey w-fit px-1 max-[400px]:text-[9.8px] text-[14px] lg:text-[16px] max-[400px]:font-700',
            next: 'border border-borderBlue rounded-lg text-primaryGrey',
            prev: 'border border-borderBlue rounded-lg text-primaryGrey',
          }}
        />
      </div>
    </div>
  );
};

export default PaginationButtons;
