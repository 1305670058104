import Tooltips from '../../../components/Tooltips';
import {
  CommunityIconStroke,
  InfoNobgIcon,
  PercentageUp,
} from '../../../images/svgs';

export interface CurrentLossStatisticsProps {
  statistics: {
    title: string;
    tooltipContent: string;
    amount: number;
    token?: string | null;
  }[];
}

function CurrentLossStatistics({ statistics }: CurrentLossStatisticsProps) {
  return (
    <div className="flex flex-wrap flex-grow justify-between items-center px-4">
      {statistics.map((stat, index) => (
        <div key={index} className="py-4 space-y-2.5 w-auto">
          <div className="flex items-center gap-2">
            <span className="text-xs">{stat.title}</span>
            {stat?.tooltipContent ? (
              <Tooltips
                postionClass={'mt-[-3px]'}
                content={
                  <p className="p-2 text-[.7rem] bg-white rounded-lg shadow-md w-[150px] text-wrap">
                    {stat.tooltipContent}
                  </p>
                }
                classContainer={{
                  content: ['w-auto'],
                }}
                placement={
                  index === 0 ? 'right' : index === 1 ? 'right' : 'left'
                }
              >
                <InfoNobgIcon className="w-3 h-3" />
              </Tooltips>
            ) : null}
          </div>
          <div className="xxs:text-xl  xs:text-2xl md:text-lg lg:text-3xl xl:text-lg 2xl:text-3xl font-medium ">
            {' '}
            {stat.amount > 0 ? stat.amount.toFixed(2) : 0} {stat.token && <span className='text-xs ml-1'>{stat.token}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default CurrentLossStatistics;
