import { ReactNode } from 'react';
import PaginationButtons from '../Global/GlobalPaginatedButtons';
import GlobalSkeleton from '../Global/GlobalSkeleton';
import CollapsibleTableRow from './Components/CollapsibleTableRow';

interface Column {
  label: string;
  key: keyof Row; // Key should be a property of Row
  classNames?: string;
}

interface Row {
  id: string;
  classNames?: string;
  [key: string]: any; // Cell Data
}

;

interface RowObjectWithCollapsedElement extends Row {
  collapsedElement: any;
}
interface Props {
  columns: Column[];
  rows?: RowObjectWithCollapsedElement[];
  loading?: boolean;
  currentPage?: number;
  NoDataComponent?:ReactNode,
  totalPages?: number;
  onPageChange?: (page: number) => void;
}

const CollapsibleTable = ({
  columns,
  rows,
  loading = false,
  currentPage = 1,
  totalPages,
  onPageChange,
  NoDataComponent
}: Props) => {

  const noData: boolean = !loading && rows?.length === 0;

  return (
    <div className="bg-white rounded-xl border border-lightGray shadow">
      <div className="overflow-auto">
        <table className="table-auto w-full">
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  className={`text-[13px] text-start font-normal text-primaryGrey border-b border-chartsCardBorder p-3 whitespace-nowrap ${column.classNames}`}
                >
                  {column.label}
                </th>
              ))}
              <th className="border-b border-chartsCardBorder"></th>
            </tr>
          </thead>
          <tbody>{loading ? renderLoadingSkeleton() : renderTableBody()}</tbody>
        </table>
      </div>
      {totalPages && totalPages > 1 && (
        <div
          className={`pt-6 flex flex-col md:flex-row w-full gap-2 justify-center`}
        >
          <div className={`flex justify-center`}>
            <PaginationButtons
              onChange={onPageChange}
              page={currentPage}
              totalPage={totalPages}
            />
          </div>
        </div>
      )}
    </div>
  );

  function renderTableBody() {
    if(noData){
      return renderNoData();
    }
    if(rows){
      return rows.map((row) => (
        <CollapsibleTableRow
          row={{
            cells: columns.map((column) => row[column.key]),
            collapsedElement: row.collapsedElement,
          }}
        />
      ));
    }
  }

  function renderNoData() {
    return (
      <tr>
        <td colSpan={columns.length} className="text-center py-4">
          {NoDataComponent ?? 'No data.'}
        </td>
      </tr>
    );
  }

  function renderLoadingSkeleton() {
    return columns.map(() => (
      <td className="py-5 px-3 border-b border-[#f5f5f5]">
        <GlobalSkeleton animationValue="pulse" height={40} width={100} />
      </td>
    ));
  }
};

export default CollapsibleTable;
