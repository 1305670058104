import React, { useState } from 'react';
import {ReactComponent as ChevronDown} from '../../../SVGIcons/arrowDown-table.svg'
export interface CollapsibleRow {
  cells: any[];
  collapsedElement: any;
}
interface Props {
  row: CollapsibleRow;
}
const CollapsibleTableRow = ({ row }: Props) => {

  const [collapsed, setCollapsed] = useState(true);
  return (
    <>
      <tr className="cursor-pointer" onClick={() => setCollapsed(!collapsed)}>
        {row.cells.map((cell) => (
          <td className="py-4 px-4 text-secondaryBlack whitespace-nowrap border-b border-[#f5f5f5] text-sm">
            {cell}
          </td>
        ))}
        <td className='border-b border-[#f5f5f5] pe-3'><ChevronDown height={14} className={`text-gray-500 ${!collapsed && 'rotate-180'}`}/></td>
      </tr>

      {!collapsed && (
        <tr>
          <td className='px-4 py-2 shadow-inner border-b border-[#f5f5f5]' colSpan={row.cells.length+1}>{row.collapsedElement}</td>
        </tr>
      )}
    </>
  );
};

export default CollapsibleTableRow;
