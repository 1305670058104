import { useGLTF } from '@react-three/drei';

const Model = (props: any) => {
  const { nodes, materials } = useGLTF('/3dmodels/LayerKmodel.glb');
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Cube001.geometry}
        material={materials['Matal solid paint white']}
        position={[-0.015, 0.001, -0.071]}
        scale={[0.976, 0.358, 0.72]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane.geometry}
        material={materials['Material.005']}
        position={[-0.869, -0.248, -0.796]}
        rotation={[1.6, 0.014, 3.097]}
        scale={[0.011, 0.121, 0.011]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane001.geometry}
        material={materials['Material.005']}
        position={[0.795, -0.243, 0.657]}
        rotation={[1.6, 0.014, 3.097]}
        scale={[0.011, 0.127, 0.01]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane002.geometry}
        material={materials['Dark metal']}
        position={[0.966, -0.157, 0.137]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane003.geometry}
        material={materials['Dark metal']}
        position={[0.966, -0.157, 0.093]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane004.geometry}
        material={materials['Dark metal']}
        position={[0.966, -0.157, 0.049]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane005.geometry}
        material={materials['Dark metal']}
        position={[0.966, -0.157, 0.005]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane006.geometry}
        material={materials['Dark metal']}
        position={[0.966, -0.157, -0.034]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane007.geometry}
        material={materials['Dark metal']}
        position={[0.966, -0.157, -0.077]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane008.geometry}
        material={materials['Dark metal']}
        position={[0.966, -0.157, -0.115]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane009.geometry}
        material={materials['Dark metal']}
        position={[0.966, -0.157, -0.153]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane010.geometry}
        material={materials['Dark metal']}
        position={[0.966, -0.157, -0.192]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane011.geometry}
        material={materials['Dark metal']}
        position={[0.966, -0.157, -0.236]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane012.geometry}
        material={materials['Dark metal']}
        position={[0.966, -0.157, -0.275]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane013.geometry}
        material={materials['Dark metal']}
        position={[-0.999, -0.157, 0.137]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane014.geometry}
        material={materials['Dark metal']}
        position={[-0.999, -0.157, 0.093]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane015.geometry}
        material={materials['Dark metal']}
        position={[-0.999, -0.157, 0.049]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane016.geometry}
        material={materials['Dark metal']}
        position={[-0.999, -0.157, 0.005]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane017.geometry}
        material={materials['Dark metal']}
        position={[-0.999, -0.157, -0.034]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane018.geometry}
        material={materials['Dark metal']}
        position={[-0.999, -0.157, -0.077]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane019.geometry}
        material={materials['Dark metal']}
        position={[-0.999, -0.157, -0.115]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane020.geometry}
        material={materials['Dark metal']}
        position={[-0.999, -0.157, -0.153]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane021.geometry}
        material={materials['Dark metal']}
        position={[-0.999, -0.157, -0.192]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane022.geometry}
        material={materials['Dark metal']}
        position={[-0.999, -0.157, -0.236]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane023.geometry}
        material={materials['Dark metal']}
        position={[-0.999, -0.157, -0.275]}
        rotation={[-0.011, -0.006, -1.534]}
        scale={[0.058, 0.998, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Cube008.geometry}
        material={materials['Cheap Plastic']}
        position={[-0.016, -1.18, -0.072]}
        scale={[1.001, 0.998, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Text.geometry}
        material={materials['Material.004']}
        position={[0.688, -0.263, -0.794]}
        rotation={[1.569, -0.003, -3.141]}
        scale={[0.062, 0.945, 0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Text001.geometry}
        material={materials['Material.004']}
        position={[-0.653, -0.265, -0.794]}
        rotation={[1.532, 0.004, 3.136]}
        scale={[0.062, 0.954, 0.059]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Text002.geometry}
        material={materials['Material.004']}
        position={[-0.759, -0.259, 0.652]}
        rotation={[1.571, 0.002, -0.001]}
        scale={[0.062, 0.945, 0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Text003.geometry}
        material={materials['Material.004']}
        position={[0.573, -0.262, 0.658]}
        rotation={[1.61, -0.004, -0.001]}
        scale={[0.062, 0.954, 0.059]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Curve.geometry}
        material={materials['Cheap Plastic']}
        position={[-0.112, 0.312, 0.006]}
        rotation={[0.019, 0.007, -0.008]}
        scale={[11.446, 1, 11.445]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Curve001.geometry}
        material={materials['Cheap Plastic']}
        position={[0.824, -0.289, -0.792]}
        rotation={[1.563, -0.006, -3.124]}
        scale={[4.518, 0.769, 5.445]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Curve002.geometry}
        material={materials['Cheap Plastic']}
        position={[-0.896, -0.287, 0.651]}
        rotation={[1.579, 0.006, -0.01]}
        scale={[4.518, 0.769, 5.445]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane024.geometry}
        material={materials['Dark metal']}
        position={[-0.974, -0.426, -0.281]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane025.geometry}
        material={materials['Dark metal']}
        position={[-0.974, -0.426, -0.32]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane026.geometry}
        material={materials['Dark metal']}
        position={[-0.973, -0.425, -0.36]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane027.geometry}
        material={materials['Dark metal']}
        position={[-0.973, -0.425, -0.399]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane028.geometry}
        material={materials['Dark metal']}
        position={[-0.973, -0.425, -0.434]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane029.geometry}
        material={materials['Dark metal']}
        position={[-0.972, -0.425, -0.472]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane030.geometry}
        material={materials['Dark metal']}
        position={[-0.972, -0.425, -0.506]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane031.geometry}
        material={materials['Dark metal']}
        position={[-0.972, -0.425, -0.54]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane032.geometry}
        material={materials['Dark metal']}
        position={[-0.971, -0.425, -0.575]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane033.geometry}
        material={materials['Dark metal']}
        position={[-0.971, -0.425, -0.614]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane034.geometry}
        material={materials['Dark metal']}
        position={[-0.971, -0.425, -0.649]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane035.geometry}
        material={materials['Dark metal']}
        position={[-0.974, -0.426, 0.121]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane036.geometry}
        material={materials['Dark metal']}
        position={[-0.974, -0.426, 0.082]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane037.geometry}
        material={materials['Dark metal']}
        position={[-0.973, -0.425, 0.042]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane038.geometry}
        material={materials['Dark metal']}
        position={[-0.973, -0.425, 0.003]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane039.geometry}
        material={materials['Dark metal']}
        position={[-0.973, -0.425, -0.032]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane040.geometry}
        material={materials['Dark metal']}
        position={[-0.972, -0.425, -0.07]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane041.geometry}
        material={materials['Dark metal']}
        position={[-0.972, -0.425, -0.105]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane042.geometry}
        material={materials['Dark metal']}
        position={[-0.972, -0.425, -0.138]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane043.geometry}
        material={materials['Dark metal']}
        position={[-0.971, -0.425, -0.173]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane044.geometry}
        material={materials['Dark metal']}
        position={[-0.971, -0.425, -0.213]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane045.geometry}
        material={materials['Dark metal']}
        position={[-0.971, -0.425, -0.247]}
        rotation={[-0.004, -0.019, -0.957]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane046.geometry}
        material={materials['Dark metal']}
        position={[-0.971, -0.425, 0.518]}
        rotation={[0.07, 0.01, -0.882]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane047.geometry}
        material={materials['Dark metal']}
        position={[-0.974, -0.424, 0.487]}
        rotation={[-0.012, -0.019, -0.946]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane048.geometry}
        material={materials['Dark metal']}
        position={[-0.974, -0.425, 0.447]}
        rotation={[-0.012, -0.019, -0.946]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane049.geometry}
        material={materials['Dark metal']}
        position={[-0.974, -0.425, 0.408]}
        rotation={[-0.012, -0.019, -0.946]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane050.geometry}
        material={materials['Dark metal']}
        position={[-0.973, -0.425, 0.373]}
        rotation={[-0.012, -0.019, -0.946]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane051.geometry}
        material={materials['Dark metal']}
        position={[-0.973, -0.425, 0.335]}
        rotation={[-0.012, -0.019, -0.946]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane052.geometry}
        material={materials['Dark metal']}
        position={[-0.973, -0.425, 0.301]}
        rotation={[-0.012, -0.019, -0.946]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane053.geometry}
        material={materials['Dark metal']}
        position={[-0.973, -0.426, 0.267]}
        rotation={[-0.012, -0.019, -0.946]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane054.geometry}
        material={materials['Dark metal']}
        position={[-0.972, -0.426, 0.232]}
        rotation={[-0.012, -0.019, -0.946]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane055.geometry}
        material={materials['Dark metal']}
        position={[-0.972, -0.426, 0.193]}
        rotation={[-0.012, -0.019, -0.946]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane056.geometry}
        material={materials['Dark metal']}
        position={[-0.972, -0.426, 0.158]}
        rotation={[-0.012, -0.019, -0.946]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane057.geometry}
        material={materials['Dark metal']}
        position={[0.945, -0.419, 0.15]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane058.geometry}
        material={materials['Dark metal']}
        position={[0.945, -0.419, 0.19]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane059.geometry}
        material={materials['Dark metal']}
        position={[0.944, -0.42, 0.229]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane060.geometry}
        material={materials['Dark metal']}
        position={[0.944, -0.42, 0.268]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane061.geometry}
        material={materials['Dark metal']}
        position={[0.943, -0.42, 0.303]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane062.geometry}
        material={materials['Dark metal']}
        position={[0.943, -0.42, 0.342]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane063.geometry}
        material={materials['Dark metal']}
        position={[0.943, -0.42, 0.376]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane064.geometry}
        material={materials['Dark metal']}
        position={[0.942, -0.42, 0.41]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane065.geometry}
        material={materials['Dark metal']}
        position={[0.942, -0.42, 0.444]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane066.geometry}
        material={materials['Dark metal']}
        position={[0.941, -0.42, 0.484]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane067.geometry}
        material={materials['Dark metal']}
        position={[0.941, -0.42, 0.519]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane068.geometry}
        material={materials['Dark metal']}
        position={[0.946, -0.419, -0.252]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane069.geometry}
        material={materials['Dark metal']}
        position={[0.946, -0.419, -0.212]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane070.geometry}
        material={materials['Dark metal']}
        position={[0.945, -0.419, -0.173]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane071.geometry}
        material={materials['Dark metal']}
        position={[0.945, -0.419, -0.134]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane072.geometry}
        material={materials['Dark metal']}
        position={[0.944, -0.419, -0.099]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane073.geometry}
        material={materials['Dark metal']}
        position={[0.944, -0.419, -0.06]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane074.geometry}
        material={materials['Dark metal']}
        position={[0.943, -0.419, -0.026]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane075.geometry}
        material={materials['Dark metal']}
        position={[0.943, -0.419, 0.008]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane076.geometry}
        material={materials['Dark metal']}
        position={[0.943, -0.419, 0.042]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane077.geometry}
        material={materials['Dark metal']}
        position={[0.942, -0.419, 0.082]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane078.geometry}
        material={materials['Dark metal']}
        position={[0.942, -0.419, 0.117]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane079.geometry}
        material={materials['Dark metal']}
        position={[0.947, -0.418, -0.657]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane080.geometry}
        material={materials['Dark metal']}
        position={[0.947, -0.418, -0.617]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane081.geometry}
        material={materials['Dark metal']}
        position={[0.946, -0.418, -0.578]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane082.geometry}
        material={materials['Dark metal']}
        position={[0.946, -0.418, -0.539]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane083.geometry}
        material={materials['Dark metal']}
        position={[0.945, -0.418, -0.504]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane084.geometry}
        material={materials['Dark metal']}
        position={[0.945, -0.418, -0.465]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane085.geometry}
        material={materials['Dark metal']}
        position={[0.944, -0.418, -0.431]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane086.geometry}
        material={materials['Dark metal']}
        position={[0.944, -0.418, -0.397]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane087.geometry}
        material={materials['Dark metal']}
        position={[0.944, -0.419, -0.363]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane088.geometry}
        material={materials['Dark metal']}
        position={[0.943, -0.419, -0.323]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane089.geometry}
        material={materials['Dark metal']}
        position={[0.943, -0.419, -0.288]}
        rotation={[-3.133, 0.02, 2.028]}
        scale={[0.014, 0.878, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane090.geometry}
        material={materials['Dark metal']}
        position={[-0.488, -0.42, 0.638]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane091.geometry}
        material={materials['Dark metal']}
        position={[-0.532, -0.42, 0.638]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane092.geometry}
        material={materials['Dark metal']}
        position={[-0.576, -0.42, 0.638]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane093.geometry}
        material={materials['Dark metal']}
        position={[-0.619, -0.42, 0.637]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane094.geometry}
        material={materials['Dark metal']}
        position={[-0.658, -0.42, 0.637]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane095.geometry}
        material={materials['Dark metal']}
        position={[-0.702, -0.42, 0.637]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane096.geometry}
        material={materials['Dark metal']}
        position={[-0.74, -0.42, 0.637]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane097.geometry}
        material={materials['Dark metal']}
        position={[-0.778, -0.42, 0.636]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane098.geometry}
        material={materials['Dark metal']}
        position={[-0.816, -0.42, 0.636]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane099.geometry}
        material={materials['Dark metal']}
        position={[-0.86, -0.42, 0.636]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane100.geometry}
        material={materials['Dark metal']}
        position={[-0.899, -0.42, 0.636]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane101.geometry}
        material={materials['Dark metal']}
        position={[-0.038, -0.42, 0.637]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane102.geometry}
        material={materials['Dark metal']}
        position={[-0.083, -0.42, 0.637]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane103.geometry}
        material={materials['Dark metal']}
        position={[-0.127, -0.42, 0.637]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane104.geometry}
        material={materials['Dark metal']}
        position={[-0.17, -0.42, 0.636]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane105.geometry}
        material={materials['Dark metal']}
        position={[-0.209, -0.42, 0.636]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane106.geometry}
        material={materials['Dark metal']}
        position={[-0.252, -0.42, 0.636]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane107.geometry}
        material={materials['Dark metal']}
        position={[-0.291, -0.42, 0.636]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane108.geometry}
        material={materials['Dark metal']}
        position={[-0.328, -0.42, 0.635]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane109.geometry}
        material={materials['Dark metal']}
        position={[-0.367, -0.42, 0.635]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane110.geometry}
        material={materials['Dark metal']}
        position={[-0.411, -0.42, 0.635]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane111.geometry}
        material={materials['Dark metal']}
        position={[-0.45, -0.42, 0.635]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane112.geometry}
        material={materials['Dark metal']}
        position={[0.414, -0.42, 0.636]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane113.geometry}
        material={materials['Dark metal']}
        position={[0.37, -0.42, 0.636]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane114.geometry}
        material={materials['Dark metal']}
        position={[0.326, -0.42, 0.636]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane115.geometry}
        material={materials['Dark metal']}
        position={[0.283, -0.42, 0.635]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane116.geometry}
        material={materials['Dark metal']}
        position={[0.243, -0.42, 0.635]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane117.geometry}
        material={materials['Dark metal']}
        position={[0.2, -0.42, 0.635]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane118.geometry}
        material={materials['Dark metal']}
        position={[0.162, -0.42, 0.635]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane119.geometry}
        material={materials['Dark metal']}
        position={[0.124, -0.42, 0.635]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane120.geometry}
        material={materials['Dark metal']}
        position={[0.085, -0.42, 0.634]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane121.geometry}
        material={materials['Dark metal']}
        position={[0.041, -0.42, 0.634]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane122.geometry}
        material={materials['Dark metal']}
        position={[0.002, -0.42, 0.634]}
        rotation={[-0.467, 1.553, -0.684]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane123.geometry}
        material={materials['Dark metal']}
        position={[0.868, -0.422, 0.633]}
        rotation={[0.62, 1.563, -1.756]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane124.geometry}
        material={materials['Dark metal']}
        position={[0.824, -0.422, 0.633]}
        rotation={[0.62, 1.563, -1.756]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane125.geometry}
        material={materials['Dark metal']}
        position={[0.779, -0.421, 0.633]}
        rotation={[0.62, 1.563, -1.756]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane126.geometry}
        material={materials['Dark metal']}
        position={[0.736, -0.421, 0.633]}
        rotation={[0.62, 1.563, -1.756]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane127.geometry}
        material={materials['Dark metal']}
        position={[0.697, -0.42, 0.633]}
        rotation={[0.62, 1.563, -1.756]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane128.geometry}
        material={materials['Dark metal']}
        position={[0.654, -0.42, 0.634]}
        rotation={[0.62, 1.563, -1.756]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane129.geometry}
        material={materials['Dark metal']}
        position={[0.616, -0.419, 0.634]}
        rotation={[0.62, 1.563, -1.756]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane130.geometry}
        material={materials['Dark metal']}
        position={[0.578, -0.419, 0.634]}
        rotation={[0.62, 1.563, -1.756]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane131.geometry}
        material={materials['Dark metal']}
        position={[0.539, -0.418, 0.634]}
        rotation={[0.62, 1.563, -1.756]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane132.geometry}
        material={materials['Dark metal']}
        position={[0.495, -0.418, 0.634]}
        rotation={[0.62, 1.563, -1.756]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane133.geometry}
        material={materials['Dark metal']}
        position={[0.456, -0.417, 0.634]}
        rotation={[0.62, 1.563, -1.756]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane134.geometry}
        material={materials['Dark metal']}
        position={[0.457, -0.42, -0.779]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane135.geometry}
        material={materials['Dark metal']}
        position={[0.501, -0.42, -0.779]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane136.geometry}
        material={materials['Dark metal']}
        position={[0.545, -0.42, -0.778]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane137.geometry}
        material={materials['Dark metal']}
        position={[0.588, -0.42, -0.778]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane138.geometry}
        material={materials['Dark metal']}
        position={[0.628, -0.42, -0.778]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane139.geometry}
        material={materials['Dark metal']}
        position={[0.671, -0.42, -0.777]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane140.geometry}
        material={materials['Dark metal']}
        position={[0.709, -0.42, -0.777]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane141.geometry}
        material={materials['Dark metal']}
        position={[0.747, -0.42, -0.777]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane142.geometry}
        material={materials['Dark metal']}
        position={[0.786, -0.42, -0.776]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane145.geometry}
        material={materials['Dark metal']}
        position={[0.008, -0.42, -0.78]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane146.geometry}
        material={materials['Dark metal']}
        position={[0.052, -0.42, -0.78]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane147.geometry}
        material={materials['Dark metal']}
        position={[0.096, -0.42, -0.779]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane148.geometry}
        material={materials['Dark metal']}
        position={[0.139, -0.42, -0.779]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane149.geometry}
        material={materials['Dark metal']}
        position={[0.178, -0.42, -0.779]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane150.geometry}
        material={materials['Dark metal']}
        position={[0.222, -0.42, -0.778]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane151.geometry}
        material={materials['Dark metal']}
        position={[0.26, -0.42, -0.778]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane152.geometry}
        material={materials['Dark metal']}
        position={[0.298, -0.42, -0.777]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane153.geometry}
        material={materials['Dark metal']}
        position={[0.336, -0.42, -0.777]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane154.geometry}
        material={materials['Dark metal']}
        position={[0.38, -0.42, -0.777]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane155.geometry}
        material={materials['Dark metal']}
        position={[0.419, -0.42, -0.776]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane156.geometry}
        material={materials['Dark metal']}
        position={[-0.445, -0.42, -0.781]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane157.geometry}
        material={materials['Dark metal']}
        position={[-0.401, -0.42, -0.781]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane158.geometry}
        material={materials['Dark metal']}
        position={[-0.356, -0.42, -0.78]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane159.geometry}
        material={materials['Dark metal']}
        position={[-0.313, -0.42, -0.78]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane160.geometry}
        material={materials['Dark metal']}
        position={[-0.274, -0.42, -0.78]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane161.geometry}
        material={materials['Dark metal']}
        position={[-0.231, -0.42, -0.779]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane162.geometry}
        material={materials['Dark metal']}
        position={[-0.193, -0.42, -0.779]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane163.geometry}
        material={materials['Dark metal']}
        position={[-0.155, -0.42, -0.778]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane164.geometry}
        material={materials['Dark metal']}
        position={[-0.116, -0.42, -0.778]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane165.geometry}
        material={materials['Dark metal']}
        position={[-0.072, -0.42, -0.778]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane166.geometry}
        material={materials['Dark metal']}
        position={[-0.033, -0.42, -0.777]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane167.geometry}
        material={materials['Dark metal']}
        position={[-0.899, -0.42, -0.781]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane168.geometry}
        material={materials['Dark metal']}
        position={[-0.854, -0.42, -0.781]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane169.geometry}
        material={materials['Dark metal']}
        position={[-0.81, -0.42, -0.78]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane170.geometry}
        material={materials['Dark metal']}
        position={[-0.767, -0.42, -0.78]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane171.geometry}
        material={materials['Dark metal']}
        position={[-0.728, -0.42, -0.78]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane172.geometry}
        material={materials['Dark metal']}
        position={[-0.685, -0.42, -0.779]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane173.geometry}
        material={materials['Dark metal']}
        position={[-0.646, -0.42, -0.779]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane174.geometry}
        material={materials['Dark metal']}
        position={[-0.609, -0.42, -0.778]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane175.geometry}
        material={materials['Dark metal']}
        position={[-0.57, -0.42, -0.778]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane176.geometry}
        material={materials['Dark metal']}
        position={[-0.526, -0.42, -0.778]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Plane177.geometry}
        material={materials['Dark metal']}
        position={[-0.487, -0.42, -0.777]}
        rotation={[-2.753, -1.549, 2.379]}
        scale={[0.014, 0.802, 0.007]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Cube002.geometry}
        material={materials['Dark metal']}
        position={[-0.015, -0.366, -0.076]}
        scale={[0.98, 0.017, 0.721]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Cube003.geometry}
        material={materials['Dark metal']}
        position={[-0.015, -0.352, -0.076]}
        scale={[0.98, 0.017, 0.721]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Cube004.geometry}
        material={materials['Dark metal']}
        position={[-0.015, -0.335, -0.076]}
        scale={[0.98, 0.017, 0.721]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Cube005.geometry}
        material={materials['Dark metal']}
        position={[-0.015, -0.319, -0.076]}
        scale={[0.98, 0.017, 0.721]}
      />
      <mesh
        castShadow
        receiveShadow
        //@ts-ignore
        geometry={nodes.Cube006.geometry}
        material={materials['Dark metal']}
        position={[-0.015, -0.302, -0.076]}
        scale={[0.98, 0.017, 0.721]}
      />
    </group>
  );
};

export default Model;
