import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPlatorfom } from '../../../store/platform/platformSlice.ts';
import { usePlatformAction } from '../../../store/platform/platformAction.ts';
import { IColors, IStatusTabs } from '../types/vouchers.type.ts';

// const colors: IColors = {
//   active: ['#48C7FF', '#FFBA53', '#C5A1FF', '#99DFFF', '#72E9CC'],
//   redeemed: '#A8C1D3',
//   expired: '#858585',
// };

let statusTabs: IStatusTabs[] = [
  { key: 'active', label: 'Active Vouchers' },
  { key: 'redeemed', label: 'Redeemed Vouchers' },
  { key: 'expired', label: 'Expired Vouchers' },
];
const colors = [
  {
    primary: 'linear-gradient(269.94deg, #46B393 0.05%, #A8DFD0 99.95%)',
    mainBox: '#2BB48B',
    secondBox: '#498D79',
  },
  {
    primary: 'linear-gradient(269.94deg, #6194DD 0.05%, #B5D0F8 99.95%)',
    mainBox: '#5696F0',
    secondBox: '#224A82',
  },
  {
    primary: 'linear-gradient(269.94deg, #FAC741 0.05%, #F8D881 99.95%)',
    mainBox: '#F9C53C',
    secondBox: '#EAB015',
  },
];
export default function useVouchers() {
  const [vouchers, setVouchers] = useState([] as any);
  const [activeTab, setActiveTab] = useState('active');

  const { vouchersData, userVouchersLoading } = useSelector(selectPlatorfom);
  const { getUserVouchers } = usePlatformAction();

  useEffect(() => {
    if (!userVouchersLoading && vouchersData.length === 0) {
      getUserVouchers();
    }
  }, []);
  useEffect(() => {
    if (vouchersData.length > 0) {
      setVouchers(
        vouchersData.filter(
          (voucher: any): boolean =>
            // voucher.redeemedAt === null &&
            // new Date(voucher.expiredAt) > new Date()
            voucher.status === 'GENERATED' || voucher.status === 'ACTIVE'
        )
      );
    }
  }, [vouchersData]);

  const handleTimeTabClick = useCallback(
    (buttonText: any): void => {
      if (buttonText.key === 'redeemed') {
        setActiveTab('redeemed');
        setVouchers(
          vouchersData.filter(
            (voucher: any): boolean =>
              // voucher.redeemedAt !== null
              voucher.status === 'REDEEMED'
          )
        );
      }
      if (buttonText.key === 'active') {
        setActiveTab('active');
        setVouchers(
          vouchersData.filter(
            (voucher: any): boolean =>
              // voucher.redeemedAt === null
              // && new Date(voucher.expiryAt) > new Date()
              voucher.status === 'GENERATED' || voucher.status === 'ACTIVE'
          )
        );
      }

      if (buttonText.key === 'expired') {
        setActiveTab('expired');
        setVouchers(
          vouchersData.filter(
            (voucher: any): boolean =>
              // new Date(voucher.expiryAt) < new Date()
              voucher.status === 'EXPIRED'
          )
        );
      }
    },
    [setActiveTab, vouchersData, setVouchers]
  );

  return {
    vouchers,
    userVouchersLoading,
    vouchersData,
    activeTab,
    setActiveTab,
    colors,
    statusTabs,
    handleTimeTabClick,
  };
}
