import React from 'react';
import { InfoNobgIcon } from '../../../images/svgs';
import { AmountType, setDecimalPlaces } from '../../../utils/utils';
import Tooltips from '../../../components/Tooltips';
import { useMediaQuery } from 'react-responsive';

const DividerInfoComponent = ({
  data,
  mainTitle,
  totalLostSection = false,
  textColor = 'black',
  totalLoss,
}: {
  data: any[];
  mainTitle: string;
  totalLostSection?: boolean;
  textColor?: string;
  totalLoss?: number;
}) => {
  const isMobileView = useMediaQuery({
    query: '(max-width: 767px)',
  });
  return (
    <div className="flex flex-col h-[100%] justify-between  ">
      <p className={`text-lg font-medium w-full`} style={{ color: textColor }}>
        {mainTitle}
      </p>
      <section className="flex flex-col md:flex-row md:justify-between ">
        {data?.map((stats, index) => (
          <React.Fragment key={index}>
            <div
              className={`flex flex-col xxs:w-full md:w-auto md:items-start xxs:flex-row xxs:justify-between `}
              style={{ color: textColor }}
            >
              <div className="flex md:flex-col md:justify-start xxs:flex-row xxs:w-full xxs:justify-between xxs:items-end  md:items-start gap-3">

                <div className="flex items-center gap-2">
                  <span className="text-xs">{stats?.title}</span>
                  <Tooltips content={stats?.tooltipContent}
                    classContainer={{
                      content: [
                        `${isMobileView ? 'max-w-[280px] w-auto' : 'w-auto'} `,

                      ],
                    }}
                    placement={`${isMobileView ? 'top' : (index == 2 ? 'left' : 'top')}`}

                  >
                    <InfoNobgIcon className={`w-3 h-3`} />
                  </Tooltips>
                </div>
                <div className="">
                  <span className="xxs:text-xl  xs:text-2xl md:text-lg lg:text-3xl xl:text-lg 2xl:text-3xl font-medium">
                    {!isNaN(Number(stats.value?.toFixed(6)))
                      ? setDecimalPlaces(Number(stats.value), AmountType.DOLLAR)
                      : 0}
                  </span>
                  {stats.unit && (
                    <span className="text-xs font-medium ml-1">
                      {stats.unit}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {
              index < data.length - 1 && (
                <div
                  className="hidden md:block w-[1px] bg-gray-300"
                  style={{ backgroundColor: textColor }}
                />
              )
            }
          </React.Fragment>
        ))}
      </section>
      {
        totalLostSection && (
          <div
            className={`flex gap-5  xxs:justify-between md:justify-start items-end`}
            style={{ color: textColor }}
          >
            <span className="text-sm font-medium">Total Lost</span>
            <span className="xxs:text-3xl md:text-4xl font-medium ">
              {totalLoss
                ? setDecimalPlaces(Number(totalLoss), AmountType.DOLLAR)
                : 0.0}
            </span>
          </div>
        )
      }
    </div >
  );
};

export default DividerInfoComponent;
