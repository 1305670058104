/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import QrCode from '../../../../components/QrCode';
import useCopyTextHook from '../../../../hooks/useCopyTextHook';
import { copy, danger, tick } from '../../../../images/others';
import { RootState } from '../../../../store';
import { useWalletDataAction } from '../../../../store/walletData/walletDataAction';
import { selectWalletData } from '../../../../store/walletData/walletDataSlice';
import { DEPOSIT_SLICE_NAME } from '../../../../store/wallets/depositSlice';
import DepositStepper from './DepositStepper';
import GenerateAddressWarning from './GenerateAddressWarning';
import GenerateAddressInfo from './GenerateAddressInfo';
import { DepositTransactionResponse } from '../../../../types/wallet.types';
import useSocketEvent from '../../../../hooks/socketIO/useSocketEvent';
import { TransactionEvents } from '../../../../utils/allConstants';
import useUserCheck from '../../../../hooks/useUserCheck';
import { Alert } from '@mui/material';
import { FEATURES } from '../../../../utils/constants';

interface GenerateAddressState {
  generateButton: boolean;
  understandButton: boolean;
}

interface Props {
  onReset: () => void;
}

const DepositStepAddress = ({ onReset }: Props) => {
  const { selectedCoin, selectedNetwork, currentStep }: any = useSelector(
    (state: RootState) => state[DEPOSIT_SLICE_NAME]
  );
  const { user } = useUserCheck();
  const [generateAddress, setGenerateAddress] = useState<GenerateAddressState>({
    generateButton: false,
    understandButton: false,
  });

  const [isQrCodeCopied, setIsQrCodeCopied] = useState(false);
  const { writeClipboardText } = useCopyTextHook();

  const { getDepositAddress, getDepositAddressCount } = useWalletDataAction();
  const { qrCodeLoader } = useSelector(selectWalletData);
  const [addressCountLoader, setAddressCountLoader] = useState(false);

  const {
    depositAddress,
    currentAttemptOfDepositAddress,
    maxAttemptsOfDepositAddress,
    isOnChainWallet: onChainWallet,
  } = useSelector(selectWalletData);

  const isOnChainWallet = FEATURES.isOnChainWallet ? onChainWallet : false;

  useSocketEvent<DepositTransactionResponse>({
    eventName: `${user?.id}`,
    onData: (data) => {
      if (
        data.body.eventAction ===
          TransactionEvents.DEPOSIT_TRANSACTION_SUCCESS ||
        data.body.eventAction === TransactionEvents.DEPOSIT_TRANSACTION_FAILURE
      ) {
        onReset();
      }
    },
  });

  const remainingAttemptOfDepositAddress =
    maxAttemptsOfDepositAddress - currentAttemptOfDepositAddress;

  const fetchDepositAddressCount = async (data: {
    token: string;
    network: string;
  }) => {
    const response = await getDepositAddressCount(data);
    const depositAddressCount = response.data.data;
    if (response.status === 200 && !depositAddressCount.isOnChainWallet) {
      await getDepositAddress(data);
    }
  };

  useEffect(() => {
    const getUDTAddressCount = async () => {
      setAddressCountLoader(true);
      const data = {
        token: selectedCoin?._id,
        network: selectedNetwork._id,
      };
      if (FEATURES.isOnChainWallet) fetchDepositAddressCount(data);
      else await getDepositAddress(data);
      setAddressCountLoader(false);
    };
    if (selectedCoin && selectedNetwork) {
      getUDTAddressCount();
    }
    setGenerateAddress({
      generateButton: false,
      understandButton: false,
    });
  }, [selectedCoin, selectedNetwork]);

  const handleCopyClick = (text: string) => {
    writeClipboardText(text);
    toast.success('Copied!');
    setIsQrCodeCopied(true);
    setTimeout(() => {
      setIsQrCodeCopied(false);
    }, 2000);
  };

  return (
    <div className="flex">
      {selectedNetwork && (
        <div className="w-10 lg:w-14">
          <DepositStepper
            step={3}
            enableBackground={currentStep === 3 && selectedNetwork}
            lineEnable={false}
          />
        </div>
      )}

      {((selectedNetwork && !isOnChainWallet) ||
        generateAddress.understandButton) && (
        <div className="flex flex-col w-[80%] gap-2">
          {isOnChainWallet !== null ? (
            <div className="text-defaultBlack text-sm font-medium">
              Deposit Address <span className="text-redDanger">*</span>
            </div>
          ) : (
            <div className="w-[150px] h-[28px] bg-gray-200 rounded p-2 "></div>
          )}

          {selectedNetwork && isOnChainWallet !== null ? (
            <>
              <div className="flex items-center flex-col 2xl:flex-row gap-4">
                <QrCode
                  value={
                    !qrCodeLoader && depositAddress
                      ? depositAddress?.address
                      : ''
                  }
                />
                <div className="w-full">
                  <span className="text-defaultBlack text-sm">
                    Copy Address to Deposit:
                  </span>
                  <div className="relative flex items-center justify-between outline-none border py-2 px-2 lg:px-4 text-xs rounded-md">
                    {!qrCodeLoader && depositAddress ? (
                      <>
                        <div className="whitespace-normal truncate">
                          {depositAddress?.address}
                        </div>
                        <img
                          src={isQrCodeCopied ? tick : copy}
                          alt="icon"
                          className="cursor-pointer"
                          onClick={() => {
                            handleCopyClick(
                              !qrCodeLoader && depositAddress
                                ? depositAddress?.address
                                : ''
                            );
                            // navigator.clipboard.writeText(
                            //   !qrCodeLoader && depositAddress
                            //     ? depositAddress?.address
                            //     : ''
                            // );
                            //   toast.success('Copied!');
                            //   setIsQrCodeCopied(true);
                            //   setTimeout(() => {
                            //     setIsQrCodeCopied(false);
                            //   }, 2000);
                          }}
                        />
                      </>
                    ) : (
                      <input
                        disabled={true}
                        type="text"
                        placeholder="Your Deposit Address Will Appear Here..."
                        value={!qrCodeLoader ? depositAddress : ''}
                        className={`bg-transparent w-full`}
                      />
                    )}
                  </div>
                </div>
              </div>
              {isOnChainWallet && (
                <div className="flex">
                  <Alert
                    severity="info"
                    icon={<img src={danger} alt="danger" />}
                    sx={{
                      '& .MuiAlert-message': {
                        width: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      },
                    }}
                  >
                    <div className="text-primary py-1">
                      <div className="text-xs">
                        <span>
                          This is a one-time address and should not be saved for
                          future deposits.
                        </span>
                      </div>
                    </div>
                  </Alert>
                </div>
              )}
              {/* <Alert
              severity="info"
              icon={<img src={danger} alt="danger" />}
              style={{ padding: '4px 8px' }}
              sx={{
                '& .MuiAlert-message': {
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: '0.75rem',
                },
              }}
              className="w-full sm:w-[80%] lg:w-full "
            >
              <div className="text-primary">
                Your asad will be credited within the next 2 hours.
              </div>
            </Alert> */}
            </>
          ) : (
            <div className="flex flex-col w-full md:w-[80%] gap-1 mt-2">
              <div className="w-full h-[24px] bg-gray-200 rounded mb-1"></div>
              <div className="w-full h-[24px] bg-gray-200 rounded"></div>
            </div>
          )}
        </div>
      )}
      {selectedNetwork &&
        isOnChainWallet &&
        !generateAddress.understandButton && (
          <div
            className={`flex flex-col gap-4 w-full transition-all duration-300 ease-in-out ${generateAddress.generateButton ? 'pb-32' : 'pb-0'}`}
          >
            <button
              onClick={() =>
                setGenerateAddress((prev) => ({
                  ...prev,
                  generateButton: true,
                }))
              }
              disabled={!remainingAttemptOfDepositAddress}
              className={`border ${remainingAttemptOfDepositAddress ? 'border-[#1B9FDA]' : 'border-[#E6E6E6]'} w-fit py-[10px] px-[25px] rounded-lg text-[14px] font-normal leading-[17px] text-center ${remainingAttemptOfDepositAddress ? 'text-[#1B9FDA]' : 'text-[#E6E6E6]'} ${remainingAttemptOfDepositAddress ? 'shadow-[0px_2px_0px_#1B9FDA] ' : 'shadow-[0px_2px_0px_#E6E6E6] '} ${remainingAttemptOfDepositAddress && 'hover:shadow-[0px_4px_4px_0px_#007BB2]'} ${remainingAttemptOfDepositAddress ? 'cursor-pointer' : 'cursor-normal'}`}
            >
              Generate Address
            </button>

            <div className="relative">
              {remainingAttemptOfDepositAddress > 2 && (
                <GenerateAddressWarning
                  color="#1B9FDA"
                  shadowColor="#bbe4f7"
                  text={`You have total ${remainingAttemptOfDepositAddress} attempts left out of ${maxAttemptsOfDepositAddress}`}
                  loading={addressCountLoader}
                />
              )}
              {remainingAttemptOfDepositAddress <= 2 &&
                remainingAttemptOfDepositAddress !== 0 && (
                  <GenerateAddressWarning
                    color="#E9B602"
                    shadowColor="#E9B60214"
                    text={`You have total ${remainingAttemptOfDepositAddress} attempts left out of ${maxAttemptsOfDepositAddress}`}
                    loading={addressCountLoader}
                  />
                )}

              {!generateAddress.generateButton &&
                remainingAttemptOfDepositAddress === 0 && (
                  <GenerateAddressWarning
                    color="#FF0000"
                    shadowColor="#FF00000F"
                    text="You have reached the maximum attempts for today.  Come Back Tomorrow."
                  />
                )}
              <GenerateAddressInfo
                show={generateAddress.generateButton}
                handleClick={() => {
                  const data = {
                    token: selectedCoin?._id,
                    network: selectedNetwork._id,
                  };
                  getDepositAddress(data);
                  setGenerateAddress((prev) => ({
                    ...prev,
                    understandButton: true,
                  }));
                }}
                handleClickOutisde={() => {
                  setGenerateAddress((prev) => ({
                    ...prev,
                    generateButton: false,
                  }));
                }}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default DepositStepAddress;
