import React, { useState, memo, useMemo, FC, useRef } from 'react';
import GlobalTable from '../../../../../components/Global/GlobalTable';
import useDebounce from '../../../../../hooks/useDebounceHook';

import LinearProgressBar from '../../../../../components/Progress';
import {
  formatDateTime,
  formatDateToString,
} from '../../../../../utils/helper';
import moment from 'moment';
import { useEffect } from 'react';
import { IActivePool } from '../../../../../types/countryPool.type';
import HoverBorderAnimation from '../../../../../components/HoverBorderAnimation';
import PlatformComingSoon from '../../../../../components/ExternApps/PlatformComingSoon';
import { FEATURES } from '../../../../../utils/constants';
import { useCountryPoolActions } from '../../../../../store/countryPool/poolAction';
import GlobalDropdown from '../../../../../components/Global/GlobalDropdown.tsx';

type ActivePoolProps = {
  data: IActivePool;
};
const ActivePool: FC<ActivePoolProps> = ({ data }) => {
  const [resetFilter, setResetFilter] = useState(false);
  const [isLoading, setLoading] = useState({
    searchLoading: false,
    dataFilter: false,
  });

  const { getCountryPool } = useCountryPoolActions();

  const [filterActiveValue, setFilterActiveValue] = React.useState<any>({
    page: 1,
    limit: 10,
    query: '',
    fromDate: null,
    toDate: null,
    status: '',
  });

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        label: 'Name',
        dataClass: '!text-primaryGrey !text-sm !font-normal !w-[20%] ',
      },
      {
        id: 'startDate',
        label: 'Start Date',
        dataClass: '!text-primaryGrey !text-sm !font-normal !w-[20%] md:w-[15%] sm:w-[25%]',
      },
      {
        id: 'progress',
        label: 'Progress',
        dataClass: '!text-primaryGrey !text-sm !font-normal !w-[30%]',
      },

      {
        id: 'total',
        label: 'Total Points',
        dataClass: '!text-primaryGrey !text-sm !font-normal !w-[15%] pl-[50px] ',
      },
      {
        id: 'maximum',
        label: 'Maximum Reward Points',
        dataClass: '!text-primaryGrey !text-sm !font-normal !w-[15%] ',
      },
      {
        id: 'status',
        label: 'Status',
        dataClass: '!text-primaryGrey !text-sm !font-normal !w-[10%]',
      },
    ],
    []
  );

  useEffect(() => {
    getCountryPool(filterActiveValue);
    if (filterActiveValue?.fromDate || filterActiveValue?.toDate) {
      setResetFilter(false);
    }
  }, [filterActiveValue]);

  const rows = data?.list?.map((ele: any) => {
    return {
      name: (
        <div className="flex items-start justify-start h-5 gap-1">
          <div className="w-2.5 h-2.5 rounded-full bg-[#AF52DE]"></div>
          <p className="min-w-32 text-sm font-normal leading-none text-black whitespace-pre-wrap break-words">
            {ele?.name}{' '}
          </p>
        </div>
      ),
      startDate: (
        <span className="!text-black whitespace-nowrap overflow-hidden text-ellipsis">
          {ele?.startDate ? formatDateTime(ele?.startDate) : '-'}
        </span>
      ),
      progress: (
        <div className=" flex flex-col justify-center  text-black text-xs w-full !min-w-[140px] !max-w-[28rem]">
          <div className="flex flex-row items-start justify-between pb-1 text-xs font-normal ">
            <p>Generated</p> <p>Available</p>
          </div>
          <LinearProgressBar
            isBlackThemed={true}
            value={
              (Number(ele?.generatedPoints) / Number(ele?.totalPoints)) * 100
            }
          />
          <div className="flex flex-row items-start justify-between pt-1 text-xs font-normal ">
            <p>{Number(ele?.generatedPoints)}</p>{' '}
            <p>{Number(ele?.remainingPoints)}</p>
          </div>
        </div>
      ),
      total: (
        <div className="flex flex-row items-center justify-start">
          {/* <img src={ele?.tokenIcon} alt={ele.name} className="w-5 h-5" /> */}
          <p className="ml-1 text-sm font-normal text-black">
            {`${ele?.totalPoints}`}
          </p>
        </div>
      ),
      maximum: (
        <div className="flex flex-row items-center justify-start">
          {/* <img src={ele?.tokenIcon} alt={ele.name} className="w-5 h-5" /> */}
          <p className="ml-1 text-sm font-normal text-black">
            {`${ele?.rewardAmount}`}
          </p>
        </div>
      ),
      status: (
        <div className="flex flex-row items-center justify-start">
          {/* <img src={ele?.tokenIcon} alt={ele.name} className="w-5 h-5" /> */}
          <p className="ml-1 text-sm font-normal text-black">
            {`${ele?.status?.charAt(0).toUpperCase()}${ele?.status?.slice(1).toLowerCase()}`}
          </p>
        </div>
      ),
    };
  });

  const handleDateDate = (startDate: any, endDate: any) => {
    const from = moment(startDate).format('YYYY-MM-DD');
    const to = moment(endDate).format('YYYY-MM-DD');
    setFilterActiveValue({
      ...filterActiveValue,
      ...{ fromDate: from, toDate: to },
    });
  };

  const typeDropdownOptions = [
    {
      key: 'active',
      label: 'Active',
    },
    {
      key: 'inactive',
      label: 'Inactive',
    },
  ];

  const emptyCheck = () =>
    !filterActiveValue.query &&
    !filterActiveValue.fromDate &&
    !filterActiveValue.toDate &&
    !filterActiveValue.status;

  return (
    <HoverBorderAnimation
      className={`h-full border radius-5px !rounded-lg  w-full col-span-12 bg-white  relative !p-0`}
      hoverAnimation={true}
    >
      <p className="p-6 pb-3 mb-3 text-lg font-semibold ">Pools</p>
      <div className={`${!FEATURES.hideCountryPoolComingSoon && 'blur-md'}`}>
        <GlobalTable
          isCellClass={true}
          stopHoverEffect={true}
          filterClass="!px-6"
          containerStyles="!border-none rounded-lg"
          isLoading={isLoading?.dataFilter}
          bodyStyles="!text-black !font-normal !h-[5rem]"
          totalPages={1}
          searchIsLoading={isLoading?.searchLoading}
          searchFieldPlaceholderText="Search"
          handlePageChange={(page: any) => {
            setFilterActiveValue({ ...filterActiveValue, ...{ page } });
          }}
          setSearchValue={(e: any) => {
            setFilterActiveValue({ ...filterActiveValue, query: e });
          }}
          searchValue={filterActiveValue?.query}
          // tableWidth="true"
          handleDateDate={handleDateDate}
          // tableDropdowns={tableDropdowns}
          resetDateFilter={resetFilter}
          filterButtonWrapper={
            <>
              <GlobalDropdown
                buttonClassName={
                  'py-2 text-primaryGrey text-xs font-normal !gap-5 !bg-transparent'
                }
                data={typeDropdownOptions}
                buttonText={'Status'}
                id={'Type'}
                dropDownWithValues={true}
                callBack={(key: any) => {
                  setFilterActiveValue((prev: any) => ({
                    ...prev,
                    status: key,
                    page: 1,
                  }));
                }}
                value={filterActiveValue.status}
              />

              <button
                className={`${emptyCheck() ? 'text-gray-400' : 'text-primary'} h-10 text-xs font-medium `}
                type="button"
                onClick={() => {
                  setResetFilter(true);
                  setFilterActiveValue({
                    page: 1,
                    limit: 10,
                    fromDate: null,
                    toDate: null,
                    status: '',
                  });
                }}
                disabled={emptyCheck()}
              >
                Reset
              </button>
            </>
          }
          toolTipItems={['remarks']}
          colorItems={['status']}
          fromDateClassName="py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]"
          toDateClassName="py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]"
          dateArrowAllow={true}
          paginationPosition="right"
          rows={rows?.length ? rows : []}
          headCells={headCells}
          showTotalRecords={false}
          bodyClass={'!min-h-0 !rounded-md'}
        ></GlobalTable>
      </div>

      {!FEATURES.hideCountryPoolComingSoon && <PlatformComingSoon />}
    </HoverBorderAnimation>
  );
};

export default memo(ActivePool);
