import React, { ReactElement } from 'react';
import HoverBorderAnimation from '../../../components/HoverBorderAnimation';
import SuperNodeFilterTabs from './SuperNodeFilterTabs';
import BonusStatistics from './BonusStatistics';
import { Spinner } from '../../../images/svgs';

export interface BonusMainCardProps {
  key: string;
  mainTitle: string;
  statisticsUser: {
    title: string;
    tooltipContent: string;
    amount: number;
    statusPercentage: number;
  }[];
  statisticsRewards: {
    title: string;
    tooltipContent: string;
    amount: number;
    statusPercentage: number;
  }[];
  layoutId: string;
  getData: (event: string) => void;
  isLoading?: boolean;
  selectedFilterTab?: any
}

function BonusMainCard({
  key,
  mainTitle,
  statisticsUser,
  statisticsRewards,
  layoutId,
  getData,
  isLoading,
  selectedFilterTab
}: BonusMainCardProps): ReactElement {
  return (
    <HoverBorderAnimation className="min-h-full flex flex-col flex-1 rounded-[8px] border border-solid border-borderBlue bg-white radius-12px">
      <div className="box-border flex flex-col justify-between">
        <div className="flex flex-wrap justify-between items-center bg-gradient-primary-blue rounded-t-[8px] px-4 py-2 space-y-3 lg:space-y-0">
          <p className="w-auto font-medium text-white text-md">{mainTitle}</p>
          <SuperNodeFilterTabs
            layoutID={layoutId}
            onFilterChange={(event: string): void => getData(event)}
            hoverTextColor={true}
            selectedFilter={selectedFilterTab}
          />
        </div>
        <div className="relative">
          <BonusStatistics statistics={statisticsUser} />
          <hr />
          <BonusStatistics statistics={statisticsRewards} />
          {isLoading && (
            <div className="opacity-70 w-[100%] bg-white h-[100%] absolute top-1 flex justify-center items-center z-[50] ">
              <Spinner className="h-6 text-primary animate-spin" />
            </div>
          )}
        </div>
      </div>
    </HoverBorderAnimation>
  );
}

export default BonusMainCard;
