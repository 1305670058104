import React, { StrictMode, useEffect, useLayoutEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.css';
import { NextUIProvider } from '@nextui-org/react';
import LogoutModal from './components/Modal/LogoutModal';
import { store } from './store';
import { router } from './utils/routers';
import logo from '../src/assets/snowfall.png';
import {
  ENV_VARS,
  PLATFORM_ENVIRONMENT,
  TOASTER_AUTOCLOSE_TIME,
} from './utils/constants';
import { MAINTENANCE } from './utils/network/ApiEndpoints';

import Maintenance from './pages/Maintenance';

const snowflake = document.createElement('img');
snowflake.src = logo;

const images = [snowflake];

const App = () => {
  const [isMaintenance, setIsMaintenance] = useState<boolean>(false);
  const [maintenanceData, setMaintenanceData] = useState<any>(null); // Initialize as null

  useEffect(() => {
    // Load Google Analytics script if not in DEV environment
    if (PLATFORM_ENVIRONMENT !== 'DEV') {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-9QJGRHQ6NH';
      script.async = true;
      document.head.appendChild(script);

      const inlineScript = document.createElement('script');
      inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-9QJGRHQ6NH');
      `;
      document.head.appendChild(inlineScript);
    }
  }, []);

  const getMaintenance = async (): Promise<void> => {
    try {
      const response = await fetch(
        ENV_VARS.API_INSTANCE_HOMNIFI + MAINTENANCE,
        {
          method: 'GET',
        }
      );
      if (response.ok) {
        const data = await response.json();
        setMaintenanceData(data.data); // Always set this

        if (data.status && data.data) {
          setIsMaintenance(true);
          if (window.location.pathname !== '/maintenance') {
            window.location.href = '/maintenance'; // Redirect
          }
        } else {
          setIsMaintenance(false);
          if (window.location.pathname === '/maintenance') {
            window.location.href = '/'; // Redirect
          }
        }
      } else {
        console.error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching maintenance:', error);
    }
  };

  useEffect(() => {
    getMaintenance();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={Number(TOASTER_AUTOCLOSE_TIME)}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <LogoutModal />
      {isMaintenance ? (
        // Render maintenance page if in maintenance mode
        <Maintenance maintenance={maintenanceData} />
      ) : (
        // Render normal routing
        <RouterProvider router={router} />
      )}
    </>
  );
};

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <StrictMode>
  <NextUIProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </NextUIProvider>
  //</StrictMode>
);
