export const usdtRound = '/img/other/usdtround.svg';
export const verified = '/img/other/verified.svg';
export const unverified = '/img/other/unverified.png';

export const pusd = '/img/other/PUSD.svg';
export const ausd = '/img/other/AUSD.svg';
export const usdt = '/img/other/USDT.svg';

export const danger = '/img/other/danger.svg';
export const lock = '/img/other/lock.svg';
// import { TriangleIcon } from '../../../../../images/svgs';
export const dangerYellow = '/img/other/danger-yellow.svg';
export const coins = '/img/other/coins.svg';
export const coinsYellow = '/img/other/coins-yellow.svg';
export const tick = '/img/other/tick.svg';

export const copy = '/img/other/copy.svg';
export const pasteIcon = '/img/other/paste.svg';
export const FeatureProductImage = '/img/other/product.png';
export const voucherEmptyIcon = '/img/voucherEmptyIcon.png';

export const homnifi_bg = '/img/wallek/bg-homnifi.png';
