export const homnifiLogo = '/img/Logo/homnifiLogo.svg';
export const profileImg = '/img/Profile/profile.jpg';
export const superNodeImg = '/img/Profile/supernode.jpg';
export const homnifiStake = '/img/Stake/HomnifiStake.png';
export const homnifiMachine = '/img/Stake/machine.png';
export const horysmallStake = '/img/Stake/HorysmallStake.png';
export const SmStake = '/img/Stake/SmStake.png';
export const USDKPromoStake = '/img/Stake/USDKPromoStake.png';
export const editIcon = '/img/icons/edit.svg';
export const dummyUser = '/img/Profile/user1.png';
export const emptyBox = '/img/empty-box.png';
export const dummyUserLarge = '/img/Profile/dummyUserLarge.png';
export const bestValue = '/img/best-value.png';
export const tickIcon = '/img/tick-icon.png';
export const cloudLykLogo = '/img/cloud/LYK 1.png';
export const voucherBgLines = '/img/vouchers/voucher_bg_lines.png';
export const voucherLine1 = '/img/vouchers/voucher_line_1.png';
export const voucherLine2 = '/img/vouchers/voucher_line_2.png';
export const voucherStars = '/img/vouchers/voucher_stars.png';
export const voucherSeperator = '/img/vouchers/voucher_seperator.png';
export const voucherTop = '/img/vouchers/voucher_top.png';
export const voucherBottom = '/img/vouchers/voucher_bottom.png';
