import { useEffect, useState } from 'react'
import GlobalModal from '../Global/GlobalModal'
import { TPSuccess } from '../../images/svgs';
import GlobalButton from '../Global/GlobalButton';
import { useWindowSize } from '../../utils/helper';
import Confetti from 'react-confetti'
interface Props {
    isOpen?: boolean;
    onClose?: () => void;
}
const TrustPilotSuccessModal = ({ isOpen, onClose }: Props) => {
    const { width, height } = useWindowSize()
    const [isConfettiRunning, setIsConfettiRunning] = useState(false);
    const [confettiRecycle, setConfettiRecycle] = useState(true);
    useEffect(() => {
        if (isOpen) {
            setIsConfettiRunning(true);
            setConfettiRecycle(true);
            const recycleTimer = setTimeout(() => {
                setConfettiRecycle(false);
            }, 4000);
            const timer = setTimeout(() => {
                setIsConfettiRunning(false);
            }, 5500);
            return () => {
                clearTimeout(recycleTimer);
                clearTimeout(timer);
            }
        }
    }, [isOpen]);
    return (
        <>
            {isOpen && isConfettiRunning &&
                <Confetti
                    width={width}
                    height={height}
                    recycle={confettiRecycle}
                    style={{ zIndex: 999999999999 }}
                    numberOfPieces={150}
                    gravity={0.1}
                    initialVelocityY={15}
                />
            }
            <GlobalModal
                isOpen={isOpen}
                onClose={onClose}
                size='md'
            >
                <div className='flex justify-center'>
                    <TPSuccess />
                </div>
                <div className='flex flex-col gap-3 items-center justify-center'>
                    <div className='text-xl font-medium'>Thank you for Your Rating</div>
                    <div className='text-sm font-medium text-center text-primaryGrey'>Sharing your experience with us is extremely helpful. </div>
                </div>
                <div className='flex justify-center gap-1 mb-3'>
                    <GlobalButton
                        classNames="bg-white border border-primary rounded text-primary shadow-btnBlueShadow"
                        text="Close"
                        onClick={onClose}
                    />
                </div>
            </GlobalModal>
        </>
    )
}

export default TrustPilotSuccessModal