import { JSX } from "react";

type TListingMemberDetails = {
  content: any;
  data: any;
};

export default function ListingMemberDetails({
  content,
  data,
}: TListingMemberDetails): JSX.Element {
  return (
    <div className="flex gap-2  flex-col font-montserrat ">
      {content}
    </div>
  );
}
