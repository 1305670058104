import { PaginationButtons } from '../../../../../components/Table';
import GlobalSkeleton from '../../../../../components/Global/GlobalSkeleton';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { ReactComponent as ArrowUpIcon } from '../../../../../SVGIcons/up-arrow.svg';
import { ReactComponent as EmptyIcon } from '../../../../../SVGIcons/empty-drawer.svg'
import { setCloudkSlice } from '../../../../../store/cloudk/cloudkSlice';
import { useDispatch } from 'react-redux';
import Tooltips from '../../../../../components/Tooltips';
import classNames from 'classnames';

interface Column {
  label: string;
  key: keyof Row; // Key should be a property of Row
  classNames?: string;
  width?: string;
  sortable?: boolean;
}

interface Row {
  id: string;
  classNames?: string;
  [key: string]: any; // Cell Data
}

interface FooterNavigation {
  text: string;
  url: string;
}
interface Props {
  title?: string;
  columns: Column[];
  rows: Row[];
  filters?: any[];
  sorting?: {
    [key: string]: any; // Cell Data
  };
  onSortChange?: (sort: object) => void;

  loading?: boolean;
  footerNavigation?: FooterNavigation;
  currentPage?: number;
  totalPages?: number;
  onPageChange?: (page: number) => void;
  customHeader?: React.ReactElement;
  renderNoData?: React.ReactElement;
  dataRowClass?: string;
  tableBodyStyles?: string;
  tabsFilter?: React.ReactNode;
  Footer?: React.ReactNode;
  onlyTable?: boolean;
  wrapperClassNames?: string;
  skeletonRows?: number;
}

const CloudKTable = ({
  title,
  columns,
  rows,
  filters,
  sorting = {},
  onSortChange,
  loading = false,
  currentPage = 1,
  totalPages = 0,
  onPageChange,
  footerNavigation,
  customHeader,
  renderNoData,
  dataRowClass = '',
  tableBodyStyles,
  tabsFilter,
  Footer,
  onlyTable = false,
  wrapperClassNames,
  skeletonRows,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const noData: boolean = !loading && rows.length === 0;
  const rowsData = loading ? Array(skeletonRows ?? 5).fill({}) : rows;
  return (
    <div className={classNames("bg-white rounded-xl border border-borderBlue p-6 shadow-cardShadow", wrapperClassNames)}>
      {!onlyTable && <div className="flex justify-between mb-6 md:mb-11 flex-wrap gap-4">
        {customHeader ?? <h4 className="text-lg font-medium">{title}</h4>}
        <div className="flex gap-3 flex-wrap items-center">{filters}</div>
      </div>}
      {tabsFilter}
      <div className={`overflow-y-scroll ${tableBodyStyles}`}>
        <table className=" w-full overflow-y-scroll ">
          <thead className="sticky top-0 bg-white ">
            <tr>
              {columns?.map((column) => (
                <th
                  key={column.key}
                  className={`group text-sm text-start font-normal text-primaryGrey border-b border-chartsCardBorder p-3 whitespace-nowrap ${column?.classNames} w-[${column?.width}] ${column.sortable ? 'hover:bg-gray-50 cursor-pointer' : ''}`}
                  onClick={() => {
                    if (column.sortable) {
                      const sortKey = column.key;
                      const updatedSorting = {} as any;
                      updatedSorting[sortKey] =
                        sorting[sortKey] == 'asc' ? 'desc' : 'asc';
                      onSortChange && onSortChange(updatedSorting);
                    }
                  }}
                >
                  <div className="flex items-center gap-3">
                    {column.label}
                    {column.sortable && (
                      <ArrowUpIcon
                        className={`h-2 ${!sorting[column.key] ? 'text-gray-300 group-hover:block' : ''}   ${sorting[column.key] == 'desc' ? 'rotate-180' : ''}`}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="min-h-[2rem] md:min-h-[15rem]  overflow-y-scroll">
            {!loading && noData && (
              <tr>
                <td
                  colSpan={columns?.length}
                  className="text-center text-primaryGrey text-sm py-6"
                >
                  {renderNoData ?? (
                    <div className="flex items-center gap-3 justify-center">
                      <EmptyIcon className="w-6 h-6 text-primaryGrey" />{' '}
                      <span>No data found.</span>
                    </div>
                  )}
                </td>
              </tr>
            )}
            {rowsData?.map((row) => (
              <tr key={row?.id} className={`${dataRowClass} `}>
                {columns?.map((column) => (
                  <td
                    key={column.key}
                    className={`py-5 px-3 text-secondaryBlack whitespace-nowrap border-b border-[#f5f5f5] text-sm w-[${column?.width}]`}
                  >
                    {loading ? (
                      <GlobalSkeleton
                        animationValue="pulse"
                        height={'100%'}
                        width={`100%`}
                      />
                    ) : row[column.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {(footerNavigation || totalPages > 1) &&
        <div
          className={`py-6 flex flex-col md:flex-row w-full gap-2 justify-${footerNavigation ? 'between' : 'center'}`}
        >
          {footerNavigation && (
            <button
              className="text-primary text-sm underline"
              onClick={() => {
                dispatch(setCloudkSlice({ hardwareHistory: null }));
                navigate(`${footerNavigation.url}`)
              }
              }

            >
              {footerNavigation.text}
            </button>
          )}
          <div className={`flex justify-center`}>
            <PaginationButtons
              onChange={onPageChange}
              page={currentPage}
              totalPage={totalPages}
            />
          </div>
        </div>
      }
      {Footer}
    </div>
  );
};

export default CloudKTable;
