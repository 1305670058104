import moment from 'moment';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import CardLayout from '../../../components/Cards/CardLayout/index.tsx';
import GetCSVData from '../../../components/GetCSVData.tsx';
import GlobalSelect, {
  IGlobalSelect,
} from '../../../components/Global/GlobalSelect.tsx';
import GlobalTable from '../../../components/Global/GlobalTable.tsx';
import useDebounce from '../../../hooks/useDebounceHook.ts';
import { useSuperNodeActions } from '../../../store/superNode/superNodeActions.ts';
import {
  selectSuperNodeSlice,
  setSuperNodeFilter,
} from '../../../store/superNode/superNodeSlice';
import {
  ISuperNodeSuperNodeRewardsList,
  SUPERNODE_BONUS_TYPE,
} from '../../../types/superNodes.type.ts';
import { formatDateFns } from '../../../utils/helper.ts';
import { toFixedWithoutRoundingOff } from '../../../utils/utils.ts';
import { store } from '../../../store/index.ts';

const headCells = [
  {
    id: 'date',
    label: 'Date',
    dataClass: 'w-[35%]',
  },
  {
    id: 'from',
    label: 'From User',
    dataClass: 'w-[30%]',
  },
  {
    id: 'level',
    label: 'Bonus Level',
    dataClass: 'w-[15%]',
  },
  {
    id: 'type',
    label: 'Type',
    dataClass: 'w-[30%]',
  },
  {
    id: 'rewards',
    label: 'Total Rewards',
    dataClass: 'w-[30%]',
  },
  {
    id: 'gask',
    label: 'Remaining GASK',
    dataClass: 'w-[25%]',
  },
  {
    id: 'loss',
    label: 'Total Loss',
    dataClass: 'w-[25%]',
  },
];

const RewardsTable = () => {
  const { getRewardsTableData, setSuperNodeFilters } = useSuperNodeActions();

  const {
    superNodeSuperNodeRewards,
    superNodeSuperNodeRewardsLoading,
    superNodeFilter,
  } = useSelector(selectSuperNodeSlice);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const [downloadTransaction, setDownloadTransaction] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(
    superNodeSuperNodeRewards ? superNodeSuperNodeRewards?.totalPages : 1
  );
  const [totalCount, setTotalCount] = useState(
    superNodeSuperNodeRewards ? superNodeSuperNodeRewards?.totalCount : 10
  );
  const [resetFilter, setResetFilter] = useState(false);
  const { debouncedValue: debouncedSearchValue } = useDebounce(
    superNodeFilter.query,
    1000
  );
  const [resetDisable, setResetDisable] = useState(!superNodeSuperNodeRewards);

  const filterEmptyCheck = () =>
    !superNodeFilter.query &&
    !superNodeFilter.type &&
    !superNodeFilter.fromDate &&
    !superNodeFilter.toDate;

  const handleDateDate = (startDate: any, endDate: any) => {
    const from = moment(startDate).format('YYYY-MM-DD');
    const to = moment(endDate).format('YYYY-MM-DD');

    setSuperNodeFilters({
      ...superNodeFilter,
      fromDate: from,
      toDate: to,
      page: 1,
    });

    const updatedFilters = {
      ...superNodeFilter,
      fromDate: from,
      toDate: to,
    };
    fetchData(updatedFilters);
  };

  const handleReset = () => {
    const newFilter = {
      limit: 10,
      page: 1,
      type: null,
      fromDate: null,
      toDate: null,
      query: null,
      totalPages: 0,
      totalCount: 0,
    };
    setSuperNodeFilters({
      ...newFilter,
    });
    fetchData(newFilter);
    setResetFilter(true);
  };

  const superNodesRewardsType: IGlobalSelect[] = [
    {
      name: 'Base Referral',
      value: SUPERNODE_BONUS_TYPE.BASE_REFERRAL,
    },
    {
      name: 'Builder Generational',
      value: SUPERNODE_BONUS_TYPE.BUILDER_GENERATIONAL,
    },
    {
      name: 'Builder Referral',
      value: SUPERNODE_BONUS_TYPE.BUILDER_REFERRAL,
    },
    {
      name: 'Supernode Global Pool',
      value: SUPERNODE_BONUS_TYPE.SUPERNODE_GLOBAL_POOL,
    },
  ];

  const tableDropdowns = (
    <div className="flex flex-row space-x-3 sm:items-center">
      <GlobalSelect
        name="type"
        placeholder="Type"
        isError={false}
        value={superNodeFilter.type}
        getSelectedValue={(type: string) => {
          handleSelectChange(type);
        }}
        options={superNodesRewardsType}
        className={'!rounded-md !min-w-24 !w-auto'}
        classNameSelect="!border-defaultBorder !rounded-md py-2.5 px-5 mr-6 "
        optionsWidth="w-max"
        selectdisable={resetDisable}
      />
      <button
        className={`${filterEmptyCheck() ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10`}
        type="button"
        onClick={handleReset}
        disabled={filterEmptyCheck()}
      >
        Reset
      </button>
    </div>
  );

  const fetchData = async (filters: any) => {
    let rewardsResponse;
    rewardsResponse = await getRewardsTableData({
      page: filters?.page,
      limit: 10,
      query: filters.query,
      fromDate: filters?.fromDate,
      toDate: filters?.toDate,
      type: filters?.type,
    });
    if (
      rewardsResponse?.data?.data &&
      rewardsResponse?.data?.data?.list?.length > 0
      // &&
      // resetDisable
    ) {
      setResetDisable(false);
      setTotalPages(rewardsResponse?.data?.data?.totalPages || 0);
      setTotalCount(rewardsResponse?.data?.data?.totalCount || 0);
    }
  };

  useEffect(() => {
    if (!superNodeSuperNodeRewardsLoading && !superNodeSuperNodeRewards) {
      fetchData(superNodeFilter);
    }
  }, []);

  // const handleSelectChange = (type: string) => {

  //   fetchData({ ...superNodeFilter, type });
  //   setSuperNodeFilters({ ...superNodeFilter, type });
  // };
  const handleSelectChange = (type: string) => {
    // Access the latest state directly from the Redux store
    const superNodeFilter = store.getState().superNode.superNodeFilter;

    const updatedFilter = { ...superNodeFilter, type };

    fetchData(updatedFilter);
    setSuperNodeFilters(updatedFilter); // Update if setSuperNodeFilters is necessary
  };

  return (
    <CardLayout className="col-span-12 " isBorder={false}>
      <span className="mb-2 text-lg font-medium text-black">
        SuperNode Rewards
      </span>

      <section className="flex flex-col items-start justify-between gap-4">
        <GlobalTable
          rows={
            superNodeSuperNodeRewards &&
            superNodeSuperNodeRewards?.list.length > 0
              ? superNodeSuperNodeRewards?.list?.map(
                  (item: ISuperNodeSuperNodeRewardsList) => {
                    const {
                      createdAt,
                      type,
                      gaskRemaining,
                      loss,
                      tokenAmount,
                      token,
                      rewardData,
                      fromUser,
                    } = item;

                    return {
                      date: (
                        <p className="text-xs font-normal text-primaryGrey">
                          {formatDateFns(createdAt, 'yyyy-MM-dd HH:mm') || '-'}
                        </p>
                      ),
                      from: (
                        <p className="text-xs font-normal text-black">
                          {fromUser?.email || fromUser?.username || ''} <br />
                          <span className="text-gray-400">
                            {fromUser?.blockchainId}
                          </span>
                        </p>
                      ),
                      level: (
                        <p className="text-xs font-normal text-black">
                          {rewardData?.currentLevel || '-'}
                        </p>
                      ),

                      type: (
                        <p className="text-xs font-normal text-black">
                          {type || '-'}
                        </p>
                      ),
                      rewards: (
                        <p className="text-xs font-normal text-black">
                          {toFixedWithoutRoundingOff(tokenAmount, 6) || 0}{' '}
                          {token?.name}
                        </p>
                      ),
                      gask: (
                        <p className="text-xs font-normal text-black">
                          {gaskRemaining ? gaskRemaining.toFixed(6) : 0}
                        </p>
                      ),
                      loss: (
                        <p className="text-xs font-normal text-black">
                          {loss.toFixed(6) || 0}
                        </p>
                      ),
                    };
                  }
                )
              : []
          }
          tableContainerClassname="!rounded-md"
          headCells={headCells}
          searchIsLoading={false}
          resetDateFilter={resetFilter}
          paginationPosition={isMobile ? 'left' : 'right'}
          page={superNodeFilter?.page}
          totalPages={totalPages}
          handlePageChange={(page) => {
            setSuperNodeFilters({ ...superNodeFilter, page });
            fetchData({ ...superNodeFilter, page });
          }}
          copyItems={['destination']}
          handleDateDate={handleDateDate}
          emptyDataMessage="No Rewards History!"
          setSearchValue={(query: string) => {
            setSuperNodeFilters({ ...superNodeFilter, query });
            setTimeout(() => {
              fetchData({ ...superNodeFilter, query });
            }, 1000);
          }}
          searchValue={superNodeFilter.query}
          showTotalRecords={false}
          searchFieldPlaceholderText="Search"
          toolTipItems={['remarks']}
          colorItems={['status']}
          dateArrowAllow={true}
          tableDropdowns={tableDropdowns}
          headStyles="h-[2.5rem] !font-[400] md:!text-[11px] xxs:!text-[12px] text-primaryGrey"
          bodyStyles="flex h-[3.5rem]"
          DownloadCSV={
            <GetCSVData
              fileName="rewards.csv"
              disabled={totalPages <= 0}
              transactionData={downloadTransaction}
              onclick={() => {
                getRewardsTableData({
                  page: superNodeFilter?.page,
                  limit: totalCount,
                  query: debouncedSearchValue,
                  fromDate: superNodeFilter?.fromDate,
                  toDate: superNodeFilter?.toDate,
                  type: superNodeFilter?.selectedStatus,
                }).then((response) => {
                  const list = response?.data?.data?.list;
                  if (list) {
                    let csvData = list?.map((item: any) => {
                      const {
                        createdAt,
                        type,
                        gaskRemaining,
                        loss,
                        tokenAmount,
                        token,
                        rewardData,
                        fromUser,
                      } = item;
                      return {
                        Date:
                          formatDateFns(createdAt, 'yyyy-MM-dd HH:mm') || '-',
                        From: fromUser?.username || fromUser?.email,
                        Level: rewardData?.currentLevel || '-',
                        Type: type || '-',
                        Rewards: `${toFixedWithoutRoundingOff(tokenAmount, 6) || 0} ${token?.name}`,
                        GASK: gaskRemaining.toFixed(6) || 0,
                        Loss: loss.toFixed(6) || 0,
                      };
                    });
                    setDownloadTransaction(csvData);
                  }
                });
              }}
              onDownloadReady={() => {
                // Callback function to handle any additional logic if needed
              }}
            />
          }
          isLoading={superNodeSuperNodeRewardsLoading}
          tableWidth={'w-full'}
          searchdisable={resetDisable}
          filterSearchClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
          fromDateDisable={resetDisable}
          toDateDisable={resetDisable}
          fromDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''} py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]`}
          toDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''} py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]`}
          fromDate={superNodeFilter.fromDate}
          toDate={superNodeFilter.toDate}
        />
      </section>
    </CardLayout>
  );
};

export default RewardsTable;
