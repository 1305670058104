import { useEffect, useState } from 'react';
import { SearchIcon, CloseSvg } from '../../../../../images/svgs';

let timeoutId: NodeJS.Timeout;

interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}
const CloudKSearchBar = ({ value, onChange, placeholder }: Props) => {
  const [internalValue, setInternalValue] = useState(value || '');

  useEffect(() => {
    if (value != internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  const handleChange = (text: string) => {
    setInternalValue(text);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      onChange && onChange(text);
    }, 700);
  };
  return (
    <div className="relativemin-w-auto px-3 py-1.5 inline-flex items-center gap-2 bg-white border border-blueGrey rounded-lg shadow-sm hover:bg-gray-50">
      <SearchIcon className="w-4 h-4 text-searchColor" />
      <input
        placeholder={placeholder}
        className="text-[13px] placeholder:text-[13px] bg-transparent  text-gray-700 border-0  focus:outline-none"
        onChange={({ target }) => handleChange(target.value)}
        value={internalValue}
      />
      {internalValue && (
        <CloseSvg
          onClick={() => handleChange('')}
          className="w-4 h-4 cursor-pointer text-searchColor hover:text-primary"
        />
      )}
    </div>
  );
};

export default CloudKSearchBar;
