import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IActivePool,
  IRewardHistory,
  IUpComingPool,
  TInitialState,
} from '../../types/countryPool.type';

const initialState: TInitialState = {
  activePool: null,
  activePoolLoading: false,
  scoreHistory: null,
  rewardHistory: null,
  myScore: null,
  upcomingPools: null,
  tokenType: null,
  countryBannerLoading: false,
  countryBannerData: null,
  countryBannerToken: '',
  claimLoading: false,
  myScorehistory: null,
  myScorehistoryLoading: false,
};

const COUNTRY_POOL_SLICE_NAME = 'countryPool';

const countryPoolSlice = createSlice({
  name: COUNTRY_POOL_SLICE_NAME,
  initialState,
  reducers: {
    setActivePool: (state: TInitialState, action: PayloadAction) => {
      return { ...state, activePool: action.payload };
    },
    setActivePoolLoading: (
      state: TInitialState,
      action: PayloadAction<boolean>
    ) => {
      return { ...state, activePoolLoading: action.payload };
    },
    setScoreHistory: (state: TInitialState, action: PayloadAction<any>) => {
      return { ...state, scoreHistory: action.payload };
    },
    setRewardHistory: (
      state: TInitialState,
      action: PayloadAction<IRewardHistory>
    ) => {
      return { ...state, rewardHistory: action.payload };
    },
    setUpComingPools: (
      state: TInitialState,
      action: PayloadAction<IUpComingPool>
    ) => {
      return { ...state, upcomingPools: action.payload };
    },
    setMyScore: (state: TInitialState, action: PayloadAction<any>) => {
      return { ...state, myScore: action.payload };
    },
    setTokenType: (state: TInitialState, action: PayloadAction<any>) => {
      return { ...state, tokenType: action.payload };
    },
    setCountryPool: (state: TInitialState, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setMyScoreHistory: (state: TInitialState, action: PayloadAction<any>) => {
      return { ...state, myScorehistory: action.payload };
    },
    setMyScoreHistoryLoading: (
      state: TInitialState,
      action: PayloadAction<any>
    ) => {
      return { ...state, myScorehistoryLoading: action.payload };
    },
  },
});

export const {
  setActivePool,
  setActivePoolLoading,
  setScoreHistory,
  setRewardHistory,
  setUpComingPools,
  setMyScore,
  setTokenType,
  setCountryPool,
  setMyScoreHistory,
  setMyScoreHistoryLoading,
} = countryPoolSlice.actions;

export const selectGlobalPoolSlice = (state: {
  [COUNTRY_POOL_SLICE_NAME]: TInitialState;
}) => state[COUNTRY_POOL_SLICE_NAME];

export const selectPoolSlice = (state: { countryPool: TInitialState }) =>
  state.countryPool.activePool;
export const selectscoreHistorySlice = (state: {
  countryPool: TInitialState;
}) => state.countryPool.scoreHistory;
export const selectrewardHistorySlice = (state: {
  countryPool: TInitialState;
}) => state.countryPool.rewardHistory;
export const selectmyScoreSlice = (state: { countryPool: TInitialState }) =>
  state.countryPool.myScore;
export const selectTokenTypeSlice = (state: { countryPool: TInitialState }) =>
  state.countryPool.tokenType;
export const selectupComingPoolsSlice = (state: {
  countryPool: TInitialState;
}) => state.countryPool.upcomingPools;

export default countryPoolSlice.reducer;
