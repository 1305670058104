const InfoSection = ({ label, value, icon }: any) => (
  <div className="flex flex-col items-center justify-center gap-1">
    <span className="text-[10px] font-normal text-[#7D7D7D]">{label}</span>
    <span className="flex items-center justify-center gap-2 text-sm font-normal">
      {icon}
      {value}
    </span>
  </div>
);

export default InfoSection;
