import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MemberContainer from '../MemberContainer';
import { CircularProgress, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMyTreeSlice,
  setTreeExpand,
} from '../../../store/myTree/myTreeSlice';
import ParentDialogLG from '../../Dialogs/ParentDialogLG';
import ListingMemberDetails from '../../../pages/SuperNode/_components/ListingTreeMemberDetails';
import { ReactComponent as ActiveIcon } from '../../../SVGIcons/MyTree/active.svg';
import { ReactComponent as InactiveIcon } from '../../../SVGIcons/MyTree/inactive.svg';
import InfoSection from '../InfoSection';
import SelectedUserInfo from '../SelectedUserInfo';
import { profileImg } from '../../../images';
import { useMyTreeActions } from '../../../store/myTree/myTreeActions';
import {
  formatCustomDate,
  toFixedWithoutRoundingOff,
} from '../../../utils/utils';
import { ArrowRightIcon, Spinner } from '../../../images/svgs';
import { FEATURES } from '../../../utils/constants';
import GlobalButton from '../../Global/GlobalButton';
import { back } from '../../../images/wallets';

const selectedUserId: any[] = [];

const ListingTreeNode = ({
  data,
  parentId,
  view,
  isChild,
  isPopup,
  searchQuery,
}: any) => {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
  const [userDialog, setUserDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    getSuperNodeTreeChildrenLoading,
    supernodeTreeParentInfo,
    supernodeTreeSearch,
    expand,
  } = useSelector(selectMyTreeSlice);

  const {
    getSuperNodeTreeChildren,
    getSuperNodeTreeChildrenDetails,
    getSuperNodeTreeSearch,
  } = useMyTreeActions();

  const toggleExpansion = async (e: any, data: any) => {
    setLoading(true);
    if (data && !expanded) {
      await getSuperNodeTreeChildren({
        id: data?._id,
        limit: 200,
      });
    }
    setLoading(false);
    setExpanded(!expanded);
  };

  const showChildDetails = (e: any) => {
    e.preventDefault();
    const idExists = selectedUserId.includes(data?._id);
    if (!idExists) {
      getSuperNodeTreeChildrenDetails(data?._id);
      selectedUserId.push(data?._id);
      setUserDialog(true);
    }
  };

  const ShowChilds = (data: any) => (
    <div className="ml-8">
      {data?.map((child: any, index: number) => (
        <React.Fragment key={index}>
          <ListingTreeNode
            data={child}
            parentId={data?._id}
            view={view}
            isChild={true}
            isPopup={isPopup}
          />
        </React.Fragment>
      ))}
    </div>
  );

  const level = (data?.depth ?? 1) - (supernodeTreeParentInfo?.depth ?? 0);

  const skyShades: Record<string, string> = {
    100: '#09374c',
    200: '#116388',
    300: '#188fc5',
    400: '#3ab1e7',
    500: '#77c9ee',
    600: '#b3e1f6',
  };

  const clampedLevel = Math.min(level, 6);

  const shadeKey = clampedLevel * 100;
  const backgroundColor = skyShades[shadeKey];

  const circleBorderStyle = `1px solid ${backgroundColor}`;

  const IsIdExistsParent = (id: string, children: any[]): boolean => {
    return children.some((child) => child._id === id);
  };

  useEffect(() => {
    if (expanded) {
      setExpanded(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    setExpanded(expand);
  }, [expand]);

  const deleteId = () => {
    if (selectedUserId) {
      selectedUserId.pop();
      const id = selectedUserId.length - 1;
      if (selectedUserId.length > 0) {
        getSuperNodeTreeChildrenDetails(selectedUserId[id]);
      }
    }
  };

  const handleClose = () => {
    deleteId();
    setUserDialog(false);
  };

  return (
    <div className={`min-w-[1000px] pt-3`}>
      <div className="relative w-full">
        <div className={`bg-secondary pt-[5px] mx-4 rounded-t-lg`} />
      </div>
      <div
        className={`h-[10px] mx-[13px] rounded-t-lg -mb-1`}
        style={{
          backgroundColor: backgroundColor,
        }}
      ></div>
      <div
        className="bg-white border-[1px] mx-3 rounded-lg shadow-md 
                font-semibold text-base relative"
      >
        <div className="flex gap-5 cursor-pointer" onClick={showChildDetails}>
          <div className="flex items-center gap-2 py-2 ml-5 ">
            <div className="relative w-[50px] h-[50px]">
              <img
                src={data?.profilePicture || profileImg}
                alt="user icon"
                className="w-[50px] h-[50px] rounded-full p-[2px] border-[3px] border-secondary"
              />
              <div
                className={`absolute bg-white font-semibold text-[10px] h-[20px] justify-center items-center flex w-[20px] text-center rounded-full -top-1 right-0 z-50 `}
                style={{
                  border: circleBorderStyle,
                  color: backgroundColor,
                }}
              >
                {level === 0 ? 'ME' : level}
              </div>
            </div>

            <div className="flex flex-col items-start justify-center gap-1">
              <span className="text-base font-semibold">{data?.username}</span>
              {level < Number(FEATURES?.myTreeEmailLevel) && (
                <span className="text-sm font-normal text-[#7D7D7D]">
                  {data.email}
                </span>
              )}
            </div>
          </div>
          <div className="flex items-center justify-end w-full gap-8 pr-10">
            <InfoSection
              label="Membership"
              value={data?.isMembership ? 'Active' : 'Inactive'}
              icon={data?.isMembership ? <ActiveIcon /> : <InactiveIcon />}
            />
            <InfoSection
              label="Base Referral"
              value={data?.isBaseReferralActive ? 'Active' : 'Inactive'}
              icon={
                data?.isBaseReferralActive ? <ActiveIcon /> : <InactiveIcon />
              }
            />
            <InfoSection
              label="Builder Generational"
              value={data?.isBuilderGenerationActive ? 'Active' : 'Inactive'}
              icon={
                data?.isBuilderGenerationActive ? (
                  <ActiveIcon />
                ) : (
                  <InactiveIcon />
                )
              }
            />
            <InfoSection
              label="Production"
              value={toFixedWithoutRoundingOff(data?.myProduction, 2)}
            />
            {/* <InfoSection label="My Rewards" value="-" /> */}
            <InfoSection
              label="Date Joined"
              value={formatCustomDate(data?.dateJoined)}
            />
          </div>
        </div>

        {supernodeTreeSearch ? (
          <div
            className={`absolute ${
              data.uplineId &&
              level > 1 &&
              IsIdExistsParent(data._id, supernodeTreeSearch.user.children)
                ? 'top-[20%]'
                : 'top-[30%]'
            } w-5 h-5 
              -left-[10px]`}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              {/* First Button: Visible for all users at the first level */}
              {data.uplineId &&
                level > 1 &&
                IsIdExistsParent(
                  data._id,
                  supernodeTreeSearch.user.children
                ) && (
                  <button
                    className="flex items-center justify-center w-4 h-4 font-normal leading-none -rotate-90 border rounded-full cursor-pointer"
                    onClick={async () => {
                      setLoading(true);
                      if (data) {
                        await getSuperNodeTreeSearch({
                          searchQuery: data?.uplineId?.blockchainId,
                          limit: 200,
                          showParent: true,
                        });
                        dispatch(setTreeExpand(true));
                        setExpanded(true);
                      }
                      setLoading(false);
                    }}
                  >
                    <ArrowRightIcon className="w-2 h-2" />
                  </button>
                )}

              {/* Second Button: Hide if the user has nested children */}
              {data?.firstLineNode > 0 && !data?.children?.length && (
                <button
                  className="flex items-center justify-center w-4 h-4 font-normal leading-none rotate-90 border rounded-full cursor-pointer"
                  onClick={(e) => {
                    toggleExpansion(e, data);
                  }}
                >
                  <ArrowRightIcon className="w-2 h-2" />
                </button>
              )}
            </div>
          </div>
        ) : (
          <>
            {data?.firstLineNode > 0 && (
              <div
                className="absolute top-[38%] border rounded-full w-5 h-5 
              border-cardText -left-[10px] bg-white cursor-pointer"
                onClick={(e) => {
                  toggleExpansion(e, data);
                }}
              >
                <div className="flex justify-center">
                  <div className="text-lg font-normal leading-none">
                    {loading ? (
                      <CircularProgress size={'1.3rem'} />
                    ) : expanded ? (
                      '-'
                    ) : (
                      '+'
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <AnimatePresence>
        {expanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            {ShowChilds(data?.children)}
          </motion.div>
        )}
        {expanded && data?.page < data?.totalPages && !loading && (
          <div className="flex items-center justify-center mt-5 text-primary">
            <button
              className="bg-white border-[1px] border-t-[6px] rounded-lg shadow-md 
                font-normal border-primary text-xs flex gap-1 justify-center items-center cursor-pointer px-4 py-2 text-primary"
              style={{ borderTop: `4px solid #1B9FDA` }}
              onClick={() => {
                setCurrentPage(currentPage + 1);
                getSuperNodeTreeChildren({
                  id: data?._id,
                  limit: 200,
                  page: currentPage + 1,
                });
              }}
            >
              {getSuperNodeTreeChildrenLoading ? (
                <Spinner className={`h-4 w-4 animate-spin`} />
              ) : (
                <div className="w-4 h-4">
                  <svg
                    className="hidden screen1070:block"
                    style={{ marginTop: '2px' }}
                    width="12"
                    height="12"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.00004 5.66536C2.2556 5.66536 1.62504 5.40703 1.10837 4.89036C0.591707 4.3737 0.333374 3.74314 0.333374 2.9987C0.333374 2.25425 0.591707 1.6237 1.10837 1.10703C1.62504 0.590365 2.2556 0.332031 3.00004 0.332031C3.38337 0.332031 3.75004 0.411142 4.10004 0.569365C4.45004 0.727587 4.75004 0.954031 5.00004 1.2487V0.332031H5.66671V2.66536H3.33337V1.9987H4.73337C4.5556 1.68759 4.3126 1.44314 4.00437 1.26536C3.69615 1.08759 3.36137 0.998698 3.00004 0.998698C2.44449 0.998698 1.97226 1.19314 1.58337 1.58203C1.19449 1.97092 1.00004 2.44314 1.00004 2.9987C1.00004 3.55425 1.19449 4.02648 1.58337 4.41536C1.97226 4.80425 2.44449 4.9987 3.00004 4.9987C3.42782 4.9987 3.81393 4.87648 4.15837 4.63203C4.50282 4.38759 4.74449 4.06536 4.88337 3.66536H5.58337C5.42782 4.25425 5.11115 4.73481 4.63337 5.10703C4.1556 5.47925 3.61115 5.66536 3.00004 5.66536Z"
                      fill="#1B9FDA"
                    />
                  </svg>
                </div>
              )}
              <span className="text-center">Load More</span>
            </button>
          </div>
        )}
      </AnimatePresence>
      <ParentDialogLG
        visible={userDialog}
        close={handleClose}
        content={
          <div className="flex flex-col items-start w-full gap-4 lg:flex-row">
            <div className="w-full lg:w-[29%] max-h-[80vh] overflow-y-auto">
              <SelectedUserInfo data={data} />
            </div>
            <div className="rotate-0 lg:rotate-90">
              <Divider />
            </div>
            <div className=" w-full lg:w-[70%] overflow-auto max-h-[80vh]">
              <ListingMemberDetails
                content={
                  <ListingMembers
                    data={data}
                    view={view}
                    isChild={false}
                    isPopup={true}
                    setUserDialog={setUserDialog}
                  />
                }
                data={data}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

const ListingTree = ({ data, view, isChild, isPopup, searchQuery }: any) => {
  return (
    <div className="">
      <ListingTreeNode
        data={data}
        parentId={data?._id}
        view={view}
        isChild={isChild}
        isPopup={isPopup}
        searchQuery={searchQuery}
      />
    </div>
  );
};

function ListingMembers({
  data,
  view,
  isChild = false,
  isPopup = false,
  setUserDialog,
  searchQuery,
}: any) {
  const { expand } = useSelector(selectMyTreeSlice);

  const { getSuperNodeTreeChildrenDetails } = useMyTreeActions();

  if (!view) return <div></div>;
  else if (
    (view === 'TreeView' && !isPopup) ||
    (view === 'TreeView' && isPopup)
  )
    return (
      <div className="flex flex-row items-start justify-start gap-2 font-montserrat">
        {data && Array.isArray(data) ? (
          data?.map((item: any, index: number) => (
            <MemberContainer
              member={item}
              isPopup={isPopup}
              key={index}
              expand={expand}
            />
          ))
        ) : (
          <MemberContainer member={data} isPopup={isPopup} expand={expand} />
        )}
      </div>
    );
  else
    return (
      <div className="font-montserrat ">
        {isPopup && (
          <div className="flex items-center justify-between mt-4">
            <span className="text-lg font-semibold text-black">Community</span>
            <GlobalButton
              classNames="border border-secondary rounded text-sm text-primary !w-32 !py-2.5 !px-4 !text-center !text-defaultBlack"
              outlined={true}
              onClick={() => {
                setUserDialog(false);
                selectedUserId.pop();
                const id = selectedUserId.length - 1;
                if (selectedUserId.length > 0) {
                  getSuperNodeTreeChildrenDetails(selectedUserId[id]);
                }
              }}
              text="Go Back"
              StartIcon={<img src={back} alt="back" className="w-4 h-4 " />}
            />
          </div>
        )}

        <div className="flex flex-col w-full gap-2 mt-2 ">
          {data && Array.isArray(data) ? (
            data?.map((item: any, index: number) => (
              <ListingTree
                data={item}
                view={view}
                isChild={isChild}
                isPopup={isPopup}
                key={index}
                searchQuery={searchQuery}
              />
            ))
          ) : (
            <ListingTree
              data={data}
              view={view}
              isChild={isChild}
              isPopup={isPopup}
              searchQuery={searchQuery}
            />
          )}
        </div>
      </div>
    );
}

export default ListingMembers;
