import React, { ReactElement } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export interface SuperNodeFilterTabsProps {
  layoutID: string;
  onFilterChange?: (event: string) => void;
  filterColor?: string;
  hoverTextColor?: boolean;
  selectedFilter?: any
}

function SuperNodeFilterTabs({
  layoutID,
  onFilterChange = (): void => { },
  filterColor = 'bg-gradient-primary-blue',
  hoverTextColor,
  selectedFilter
}: SuperNodeFilterTabsProps): ReactElement {
  const [filters, setFilters] = React.useState({ type: selectedFilter || 'day' });
  const filterType = [
    { label: 'Yesterday', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Year', value: 'year' },
  ];
  return (
    <div className="relative flex justify-between items-center bg-white w-full md:w-auto p-1 rounded-md text-primaryGrey ml-auto lg:ml-0">
      {filterType.map(
        (t): ReactElement => (
          <motion.div
            key={t.value}
            onClick={(): void => {
              onFilterChange(t.value);
              setFilters({ type: t.value });
            }}
            className={`relative flex w-full md:w-auto items-center justify-center px-3 py-1   rounded-md cursor-pointer text-xs ${t.value === filters.type
              ? `text-white `
              : `hover:${hoverTextColor === true ? 'text-primary' : 'text-[#FF2727]'}`
              }`}
          >
            <span className="relative z-10">{t.label}</span>

            {t.value === filters.type && (
              <motion.div
                layoutId={layoutID}
                className={`absolute inset-0 ${filterColor} rounded-md`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  type: 'spring',
                  bounce: 0.2,
                  duration: 0.6,
                }}
              />
            )}
          </motion.div>
        )
      )}
    </div>
  );
}

export default SuperNodeFilterTabs;
