import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const WALLEK_SLICE_NAME = 'wallek';

export interface WALLEK_CLAIM_HISTORY {
  stakedAmount: number;
  token: string;
  startDate: Date;
  lookupPeriod: string;
  claimedDate: Date;
  status: string;
}

export interface StateWallek {
  wallekClaimHistoryList: WALLEK_CLAIM_HISTORY[];
  wallekStakedList: any;
  wallekClaimHistoryLoading: boolean;
  wallekStakedLoading: boolean;
}

const initialState: StateWallek = {
  wallekClaimHistoryList: [],
  wallekStakedList: [],
  wallekClaimHistoryLoading: false,
  wallekStakedLoading: false,
};

const slice = createSlice({
  name: WALLEK_SLICE_NAME,
  initialState,
  reducers: {
    setWallekSlice: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setWallekClaimHistory: (state, action: PayloadAction<any>) => {
      return { ...state, wallekClaimHistoryList: action.payload };
    },
    setStakedWallek: (state, action: PayloadAction<any>) => {
      return { ...state, wallekStakedList: action.payload };
    },
  },
});

export const { setWallekClaimHistory, setStakedWallek, setWallekSlice } =
  slice.actions;
export const selectWallekPoolSlice = (state: {
  [WALLEK_SLICE_NAME]: StateWallek;
}) => state[WALLEK_SLICE_NAME];
export default slice.reducer;
