import { RotatingLines } from 'react-loader-spinner';

const DataLoader = ({
  color,
  className,
  width = 20,
}: {
  color?: string;
  className?: string;
  width?: number;
}) => {
  return (
    <div className={`inline-block ${className}`}>
      <RotatingLines
        width={width?.toString()}
        strokeWidth="3"
        animationDuration="0.75"
        strokeColor={color ?? '#382F10'}
      />
    </div>
  );
};

export default DataLoader;
