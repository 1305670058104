import GlobalButton from '../../../components/Global/GlobalButton';
import CloudkDatePicker from '../../Defi/Cloudk/Common/CloudKDatepicker/CloudkDatepicker';
import CloudKSelect from '../../Defi/Cloudk/Common/CloudKSelect/CloudKSelect';
import { ReactComponent as EyeIcon } from '../../../SVGIcons/view-eye.svg';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSuperNodeActions } from '../../../store/superNode/superNodeActions';
import moment from 'moment';
import { toFixedFormat } from '../../../utils/utils';
import { Skeleton } from '@mui/material';
import CsvDownload from 'react-csv-downloader';
import { DownloadIcon } from '../../../images/svgs';

interface Report {
  level: number;
  levelNumber: number;
  fromDate: string;
  toDate: string;
  values: {
    key: string;
    label: string;
    value: string | number;
  }[];
}

interface Props {
  type: string;
}

const levels = Array.from({ length: 30 }, (_, index) => ({
  label: (index + 1).toString(),
  value: (index + 1).toString(),
}));

const csvColumns = [
  { id: 'level', displayName: 'Level' },
  { id: 'total-reward', displayName: 'Total Rewards' },
  { id: 'base-referral', displayName: 'Base Referal' },
  { id: 'builder-generational', displayName: 'Builder Generational' },
  { id: 'builder-referral', displayName: 'Builder Referal' },
  { id: 'matching-bonus', displayName: 'Matching Bonus' },
  { id: 'fromDate', displayName: 'From Date' },
  { id: 'toDate', displayName: 'To Date' },
];

const SupernodeMyReports = ({ type }: Props) => {
  const navigate = useNavigate();
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [dateRange, setDateRange] = useState<any>({});
  const { getMyReport } = useSuperNodeActions();

  useEffect(() => {
    handleFetchReports();
  }, [type, filters]);


  const generateFilterParams = () => {
    const params = { ...filters };
    if (params.fromDate)
      params.fromDate = moment(params.fromDate).format('YYYY-MM-DD');
    if (params.toDate)
      params.toDate = moment(params.toDate).format('YYYY-MM-DD');
    return params;
  }
  const generateFilterDates = (report: Report) => {
    return {
      fromDate: moment(report.fromDate).format('YYYY-MM-DD'),
      toDate: moment(report.toDate).format('YYYY-MM-DD'),
    } as any
  }
  const handleFetchReports = async () => {
    setLoading(true);

    const response = await getMyReport({ type, ...generateFilterParams() });

    if (response.data?.status && response.data?.data) {
      const responseData = response.data?.data;
      const reports: Report[] = responseData?.map((report: any) => ({
        level: `${report.level < 10 ? '0' : ''}${report.level}`,
        levelNumber: report.level,
        fromDate: moment(report.fromDate).utc().format('YYYY-MM-DD'),
        toDate: moment(report.toDate).utc().format('YYYY-MM-DD'),
        values: report?.values?.map((value: any) => ({
          label: value?.type
            ?.toLowerCase()
            ?.replace(/-/g, ' ') // Replace all dashes with spaces
            .replace(/\b\w/g, (char: any) => char.toUpperCase()),
          value: `${toFixedFormat(value?.amount, 4)} ${value?.token}`,
          key: value?.type,
        })),
      }));

      setReports(reports);
    }
    setLoading(false);
  };

  const handleDownloadCSV = async () => {
    let data = [];
    data = reports.map((report) => ({
      level: report.level,
      fromDate: report.fromDate,
      toDate: report.toDate,
      ...Object.fromEntries(report.values.map((v) => [v.key, v.value])),
    }));
    return Promise.resolve(data);
  };

  const handleDateChange = (date: { type: 'fromDate' | 'toDate', value: Date | null }) => {
    const dates = { ...dateRange, [date.type]: date.value };

    setDateRange(dates);
    if (dates.fromDate && dates.toDate) {
      setFilters({ ...filters, ...dates });
    }
  }

  return (
    <>
      <div className="flex justify-between items-center gap-y-2 flex-wrap">
        <div className="flex items-center gap-2 flex-wrap">
          <CloudKSelect
            options={levels}
            placeholder={'Level'}
            onChange={(e) => setFilters({ ...filters, level: e.value })}
            selectedValue={filters.level}
          />
          <CloudkDatePicker
            placeholder="From Date"
            onDateChange={(date) => {
              handleDateChange({ type: 'fromDate', value: date });
            }}
            date={dateRange?.fromDate}
            selectsStart={true}
            startDate={dateRange.fromDate}
            endDate={dateRange.toDate}
            maxDate={dateRange.toDate || new Date()}
          />
          <CloudkDatePicker
            placeholder="To Date"
            onDateChange={(date) => {
              handleDateChange({ type: 'toDate', value: date });
            }}
            minDate={dateRange.fromDate}
            maxDate={new Date()}
            selectsEnd={true}
            startDate={dateRange.fromDate}
            endDate={dateRange.toDate}
            date={dateRange?.toDate}
          />
          <button
            className="text-primary text-xs font-medium h-10 disabled:text-primaryGrey"
            onClick={() => {
              setFilters({});
              setDateRange({})
            }}
            disabled={Object.values(filters).length === 0}
          >
            Reset
          </button>
        </div>

        {reports.length > 0 && (
          <div className="">
            <CsvDownload
              className="flex items-center gap-1.5 text-white bg-primary hover:bg-primary/90 px-3 py-1.5 rounded-lg text-sm cursor-pointer"
              datas={handleDownloadCSV}
              filename={type + '.csv'}
              columns={csvColumns}
            >
              <DownloadIcon className="text-white w-4 h-4" />
              <span>Download</span>
            </CsvDownload>
          </div>
        )}
      </div>
      <div className="border overflow-y-auto border-[#DDDDDD] rounded-xl mt-5 p-2 mb-5">
        {loading ? (
          <div className="flex flex-col gap-3">
            {Array(3)
              .fill('')
              .map((_) => (
                <Skeleton sx={{ transform: 'unset' }} height={60} />
              ))}
          </div>
        ) : (
          <div className="flex flex-col gap-3 w-full min-w-fit">
            {reports.length === 0 && (
              <div className="text-center py-6 w-full">No reports found.</div>
            )}
            {reports.map((report: Report) => (
              <div className="flex w-full min-w-max flex-shrink-0 bg-white items-center rounded-xl shadow-lg border-t-4 border-primary">
                <div className="flex-shrink-0 bg-gradient-to-r from-[#A5E3FF] from-[0%] to-[#1B9FDA] flex items-center justify-center text-white py-2 px-6 rounded-l-xl shadow-sm">
                  <div className="text-center">
                    <span className="text-xs">Reward Level</span>
                    <div className="font-bold text-2xl">{report.level}</div>
                  </div>
                </div>
                <div className="flex-shrink-0 flex-grow flex items-center justify-between gap-6 px-4">
                  {report.values.map((value) => (
                    <LabelValue
                      label={value.label}
                      value={value.value?.toString()}
                    />
                  ))}

                  <LabelValue label="From Date" value={report.fromDate} />
                  <LabelValue label="To Date" value={report.toDate} />
                  <button
                    className="text-primary text-sm flex items-center gap-1"
                    onClick={() =>
                      navigate({
                        pathname: `/community/supernode-reports`,
                        search: `?${createSearchParams({ type, ...generateFilterDates(report), level: report.levelNumber })}`
                      })
                    }
                  >
                    <EyeIcon className="h-4" />
                    View
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SupernodeMyReports;

const LabelValue = ({ label, value }: { label: string; value: string }) => (
  <div className="p-2 flex flex-col gap-1">
    <small className="block text-black text-[13px] font-medium text-nowrap">
      {label}
    </small>
    <span className="text-[14px] text-[#1B1B1B] text-nowrap">{value}</span>
  </div>
);
