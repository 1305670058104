import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import ListingMembers from '../ListingMembers';
import ParentDialogLG from '../../Dialogs/ParentDialogLG';
import ListingMemberDetails from '../../../pages/SuperNode/_components/ListingTreeMemberDetails';
import Divider from '../../Divider';
import SelectedUserInfo from '../SelectedUserInfo';
import { ReactComponent as ActiveIcon } from '../../../SVGIcons/MyTree/active.svg';
import { ReactComponent as InactiveIcon } from '../../../SVGIcons/MyTree/inactive.svg';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ZoomControls from '../ZoomControls';
import GlobalButton from '../../Global/GlobalButton';
import { useDispatch, useSelector } from 'react-redux';
import { profileImg } from '../../../images';
import {
  selectMyTreeSlice,
  setTreeExpand,
} from '../../../store/myTree/myTreeSlice';
import { FEATURES } from '../../../utils/constants';
import { useMyTreeActions } from '../../../store/myTree/myTreeActions';
import { ArrowRightIcon } from '../../../images/svgs';

const legendData = ['Membership', 'Base Referral', 'Builder Generational'];

const selectedUserId: any[] = [];

const MemberCard = ({
  member,
  collapsed,
  toggleCollapse,
  isPopup,
  randomColor,
}: any) => {
  const dispatch = useDispatch();
  const { expand, supernodeTreeParentInfo, supernodeTreeSearch } =
    useSelector(selectMyTreeSlice);

  const [expandedFlag, setExpandedFlag] = useState<boolean>(false);
  const [userDialog, setUserDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    getSuperNodeTreeChildrenDetails,
    getSuperNodeTreeChildren,
    getSuperNodeTreeSearch,
  } = useMyTreeActions();

  const showChildDetails = (e: any) => {
    e.preventDefault();
    const idExists = selectedUserId.includes(member?._id);
    if (!idExists) {
      getSuperNodeTreeChildrenDetails(member?._id);
      selectedUserId.push(member?._id);
      setUserDialog(true);
    }
  };

  const level = member?.depth - supernodeTreeParentInfo?.depth;

  const skyShades: Record<string, string> = {
    100: '#09374c',
    200: '#116388',
    300: '#188fc5',
    400: '#3ab1e7',
    500: '#77c9ee',
    600: '#b3e1f6',
  };

  const clampedLevel = Math.min(level, 6);

  const shadeKey = clampedLevel * 100;
  const backgroundColor = skyShades[shadeKey];

  const circleBorderStyle = `1px solid ${level === 0 ? '#5BAC0A' : backgroundColor}`;

  const IsIdExistsParent = (id: string, children: any[]): boolean => {
    return children.some((child) => child._id === id);
  };

  const deleteId = () => {
    if (selectedUserId) {
      selectedUserId.pop();
      const id = selectedUserId.length - 1;
      if (selectedUserId.length > 0) {
        getSuperNodeTreeChildrenDetails(selectedUserId[id]);
      }
    }
  };

  const handleClose = () => {
    deleteId();
    setUserDialog(false);
  };

  // useEffect(() => {
  //   setExpanded(expand);
  // }, [expand]);

  return (
    <div className="pt-[35px] w-[250px]">
      <div
        className="bg-white rounded-lg shadow relative border-t-[6px] m-2"
        style={{
          borderTop: `6px solid ${level === 0 ? '#5BAC0A' : backgroundColor}`,
        }}
      >
        <div className="relative cursor-pointer" onClick={showChildDetails}>
          <div
            className={`w-20 h-20 overflow-hidden absolute z-10  rounded-full bg-white`}
            style={{
              top: '-40px',
              left: '50%',
              transform: 'translateX(-50%)',
              border: `3px solid ${level === 0 ? '#5BAC0A' : backgroundColor}`,
            }}
          >
            <img
              src={member?.profilePicture || profileImg}
              alt=""
              className="align-middle border-gray-200 rounded-full shadow"
            />
          </div>
          <div
            className={`absolute bg-white font-semibold text-[10px] h-[20px] justify-center items-center flex w-[20px] text-center rounded-full -top-10 right-20 z-50 `}
            style={{
              border: circleBorderStyle,
              color: level === 0 ? '#5BAC0A' : backgroundColor,
            }}
          >
            {level === 0 ? 'ME' : level}
          </div>

          <div
            className={`flex flex-col relative items-center justify-center gap-6 px-6 pt-10 pb-4 rounded-b-xl`}
            style={{ border: circleBorderStyle }}
          >
            <div className="absolute flex flex-col items-start justify-center gap-1 top-1 left-1">
              {member?.isMembership ? <ActiveIcon /> : <InactiveIcon />}
              {member?.isBaseReferralActive ? <ActiveIcon /> : <InactiveIcon />}
              {member?.isBuilderGenerationActive ? (
                <ActiveIcon />
              ) : (
                <InactiveIcon />
              )}
            </div>
            {/* Basic Info */}
            <div className="flex flex-col items-center justify-center gap-1">
              <span className="text-base font-semibold break-all">
                {member?.username}
              </span>
              {member?.depth - supernodeTreeParentInfo?.depth <
                Number(FEATURES?.myTreeEmailLevel) && (
                <span className="text-sm font-normal text-[#7D7D7D] break-all">
                  {member?.email}
                </span>
              )}
            </div>
          </div>
        </div>
        {supernodeTreeSearch ? (
          <div
            className="absolute z-10 rounded-full "
            style={{
              bottom: '-10px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <div className="flex items-center justify-center gap-2">
              {/* First Button: Visible for all users at the first level */}
              {member.uplineId &&
                level > 1 &&
                IsIdExistsParent(
                  member._id,
                  supernodeTreeSearch.user.children
                ) && (
                  <button
                    className="flex items-center justify-center w-5 h-5 font-normal leading-none -rotate-90 bg-white border-[#1D417C] border rounded-full cursor-pointer"
                    onClick={async () => {
                      // setLoading(true);
                      if (member) {
                        await getSuperNodeTreeSearch({
                          searchQuery: member?.uplineId?.blockchainId,
                          limit: 200,
                          showParent: true,
                        });
                        dispatch(setTreeExpand(true));
                        // setExpanded(true);
                      }
                      // setLoading(false);
                    }}
                  >
                    <ArrowRightIcon className="w-3 h-3" />
                  </button>
                )}

              {/* Second Button: Hide if the user has nested children */}
              {member?.firstLineNode > 0 && !member?.children?.length && (
                <button
                  className="flex items-center justify-center w-5 h-5 font-normal leading-none rotate-90 bg-white border border-[#1D417C] rounded-full cursor-pointer"
                  onClick={async (e) => {
                    setIsLoading(true);
                    if (collapsed && member?.firstLineNode > 0) {
                      await getSuperNodeTreeChildren({
                        id: member._id,
                        limit: 200,
                        page: 1,
                      });
                    }
                    await toggleCollapse(member);
                    if (collapsed) {
                      dispatch(setTreeExpand(true));
                    }
                    setIsLoading(false);
                  }}
                >
                  <ArrowRightIcon className="w-3 h-3" />
                </button>
              )}
            </div>
          </div>
        ) : (
          <>
            {' '}
            {member?.firstLineNode > 0 ? (
              <div>
                {isLoading ? (
                  <div
                    className="absolute z-10 border-2 border-cardText rounded-full -bottom-[10px] left-[50%] 
                                    -translate-x-[50%] w-6 h-6"
                  >
                    <CircularProgress size={'1.3rem'} />
                  </div>
                ) : (
                  <div
                    className={`absolute z-10 rounded-full`}
                    style={{
                      bottom: '-10px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                    onClick={async (e) => {
                      setIsLoading(true);
                      if (collapsed && member?.firstLineNode > 0) {
                        await getSuperNodeTreeChildren({
                          id: member._id,
                          limit: 200,
                          page: 1,
                        });
                      }
                      await toggleCollapse(member);
                      if (collapsed) {
                        dispatch(setTreeExpand(true));
                      }
                      setIsLoading(false);
                    }}
                  >
                    <div
                      className={`w-6 h-6 rounded-full shadow-lg cursor-pointer`}
                      style={{
                        border: '1px solid #1D417C',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#ffffff',
                      }}
                    >
                      {collapsed ? (
                        <svg
                          width="13"
                          height="12"
                          viewBox="0 0 13 12"
                          fill="none"
                        >
                          <line
                            x1="1.87939"
                            y1="5.57422"
                            x2="11.0078"
                            y2="5.57422"
                            stroke="#1D417C"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <line
                            x1="6.37061"
                            y1="10.2109"
                            x2="6.37061"
                            y2="1.0825"
                            stroke="#1D417C"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="13"
                          height="12"
                          viewBox="0 0 13 12"
                          fill="none"
                        >
                          <line
                            x1="1.87939"
                            y1="5.57422"
                            x2="11.0078"
                            y2="5.57422"
                            stroke="#1D417C"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
      <ParentDialogLG
        visible={userDialog}
        close={handleClose}
        content={
          <div className="flex flex-col items-start w-full gap-1 lg:flex-row">
            <div className="w-full lg:w-[29%] max-h-[80vh] overflow-y-auto small-scroll">
              <SelectedUserInfo data={member} />
            </div>
            <div className="rotate-0 lg:rotate-90">
              <Divider />
            </div>
            <div className="flex items-center justify-center ">
              <div className="max-w-[73vw] flex justify-center flex-col items-center md:max-w-[87vw] lg:max-w-[63vw] xl:max-w-[60vw] overflow-auto">
                <div className="flex flex-col items-center justify-center w-full">
                  <TransformWrapper
                    limitToBounds={false}
                    minScale={0.1}
                    smooth={true}
                  >
                    <div className="relative flex flex-row items-start justify-between w-[73vw] md:w-[87vw] lg:w-[63vw] xl:max-w-[60vw] gap-1">
                      <div className="absolute left-0 z-40 top-3">
                        <div className="flex flex-col items-start justify-center gap-1 p-3 border rounded-lg backdrop-blur-sm bg-slate-100/60">
                          <h1 className="text-xs font-medium">Legend Status</h1>
                          {legendData.map((title, index) => (
                            <div
                              key={index}
                              className="flex items-center justify-center gap-2 text-nowrap"
                            >
                              <ActiveIcon />
                              <InactiveIcon />
                              <span className="text-xs">{title}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="absolute right-0 w-[46.5vw] z-40 flex flex-wrap items-center justify-end gap-3 top-3">
                        <ZoomControls reset={!expand} />
                      </div>
                    </div>
                    <TransformComponent
                      wrapperStyle={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '80vh',
                      }}
                      contentClass="flex flex-col items-center justify-center"
                    >
                      <div className="w-[210px] pt-[40px]">
                        <div
                          className="bg-white rounded-lg shadow relative border-t-[6px] m-2"
                          style={{ borderTop: `6px solid #24288E` }}
                        >
                          <div className="relative cursor-pointer">
                            <div
                              className={`w-10 h-10 overflow-hidden absolute z-10  rounded-full bg-white`}
                              style={{
                                top: '-25px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                border: `2px solid #24288E`,
                              }}
                            >
                              <img
                                src={
                                  member?.uplineId?.profilePicture || profileImg
                                }
                                alt=""
                                className="align-middle border-gray-200 rounded-full shadow"
                              />
                            </div>

                            <div
                              className={`flex flex-col items-center justify-center gap-6 px-6 pt-5 pb-4 rounded-b-xl`}
                            >
                              {/* Basic Info */}
                              <div className="flex flex-col items-center justify-center gap-1">
                                <span className="text-xs font-semibold">
                                  {member?.uplineId?.username}
                                </span>
                                {level < Number(FEATURES?.myTreeEmailLevel) && (
                                  <span className="text-xs font-normal text-[#7D7D7D] break-all">
                                    {member?.uplineId?.email}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-l-2 border-current border-dashed h-9"></div>
                      <ListingMemberDetails
                        content={
                          <ListingMembers
                            data={member}
                            view={'TreeView'}
                            isChild={false}
                            isPopup={true}
                          />
                        }
                        data={member}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default MemberCard;
