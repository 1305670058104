import { MinterListItem, UserMinterLicense } from '../../Common/types';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';
import { ReactNode, useEffect, useState } from 'react';
import ApiRequestCloudk from '../../../../../utils/network/ApiRequestCloudk';
import DataLoader from '../../../../../components/DataLoader/DataLoader';
import {
  capitalizeFirstLetter,
  toFixedFormat,
} from '../../../../../utils/utils';
import Tooltips from '../../../../../components/Tooltips';
import { setDecimalPlaces } from '../../../../../utils/helper';
interface Props {
  minter: MinterListItem;
  onViewDetails: (minter: MinterListItem) => void;
  linkedLicenses?: [];
  onMinterLinkedLicensesLoad: (licenses: UserMinterLicense[]) => void;
  onDelinkLicense: (payload: {
    minterId: string;
    licenseId: string;
  }) => Promise<void>;
}

const SingleMinterItemDetails = ({
  minter,
  linkedLicenses,
  onMinterLinkedLicensesLoad,
  onViewDetails,
  onDelinkLicense,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [licenses, setLicenses] = useState<UserMinterLicense[]>(
    linkedLicenses ?? []
  );
  const [delinkLoadingId, setDelinkLoadingId] = useState<string | null>(null);

  useEffect(() => {
    (async function () {
      if (minter.linkedLicenses.length > 0 && !linkedLicenses) {
        setLoading(true);
        const response = await ApiRequestCloudk().get(
          '/user/minter/linkedLicence',
          { params: { id: minter._id } }
        );
        if (response.data?.data?.list) {
          setLicenses(response.data?.data?.list);
          onMinterLinkedLicensesLoad(response.data?.data?.list);
        }
        setLoading(false);
      }
    })();
  }, []);

  const handleDelink = async (licenseId: string) => {
    setDelinkLoadingId(licenseId);
    await onDelinkLicense({
      minterId: minter._id,
      licenseId,
    });
    setDelinkLoadingId(null);
  };

  return (
    <div className="bg-pageBackground -mx-4 -my-2 px-4 py-2 shadow-inner border-l-4 border-l-[#CCCCCC]">
      <div className="flex items-center justify-between gap-4">
        <LabelValue label="Type" value={capitalizeFirstLetter(minter.type)} />
        <LabelValue
          label="Number of NFTs"
          value={minter.maxNftHold.toString()}
        />

        <LabelValue
          label="LYK-R"
          value={setDecimalPlaces(Number(minter.minterRewards.total_lyk_r || 0), 3)}
        />

        <LabelValue
          label="LYK-W"
          value={setDecimalPlaces(Number(minter.minterRewards.total_lyk_w || 0), 3)} />
        <LabelValue
          label="Linking Status"
          value={(() => {
            const linked = minter.linkedLicenses.length > 0;
            const Icon = linked ? LinkIcon : LinkOffIcon;
            return (
              <span
                className={`flex items-center gap-2 text-${linked ? 'primaryGreen' : 'primaryRed'}`}
              >
                <Icon className="!w-" /> {linked ? 'Linked' : 'Not linked'}
              </span>
            );
          })()}
        />
        <Tooltips
          wrapperClassName="bg-white p-3 rounded-lg shadow text-sm max-w-[180px]"
          placement='top-end'
          content="Please connect your minter to view details."
          isDisabled={minter.status !== 'awaiting'}
        >
          <button
            onClick={() => onViewDetails({ ...minter, minterData: minter })}
            className="text-sm underline text-primary disabled:text-primaryGrey disabled:cursor-text"
            disabled={minter.status === 'awaiting'}
          >
            View minter details
          </button>
        </Tooltips>
      </div>
      {minter.linkedLicenses.length > 0 && (
        <>
          <div className="px-2 mt-4 mb-2 text-xs text-cardSecondaryText">
            Linked Licenses
          </div>
          {loading ? (
            <DataLoader className="flex justify-center pb-4" />
          ) : (
            licenses.map((license) => (
              <div className="px-3 text-sm grid grid-cols-[1.5fr_1fr_1fr_1fr_1fr] items-center justify-center gap-6 bg-white py-1 rounded-lg border border-[#00A5EF1A] bg-[#E8F8FF1A] mb-2">
                <div className="flex items-center flex-shrink-0 gap-2 min-w-48">
                  <img
                    className="h-8"
                    src={license.licenseImage.link}
                    alt={license.licenceName}
                  />
                  <span className="flex-shrink-0 text-secondaryBlack text-nowrap">
                    {license.licenceName}
                  </span>{' '}
                  x2
                </div>
                <div className='flex gap-2 w-full min-w-28'>
                <div className="flex items-center justify-center gap-">
                  <small className="block text-cardSecondaryText text-nowrap">
                    Linked mLYK:
                  </small>
                  
                </div>
                <span className=" text-cardPrimaryText">
                    {toFixedFormat(license.totalTokenAmount, 3)}
                  </span>
                  </div>
                  <div className='flex gap-2 w-full min-w-36'>

                <div className="flex items-center justify-center gap-2">
                  <small className="block text-cardSecondaryText text-nowrap">
                    Capacity:
                  </small>
                 
                </div>
                <span className=" text-cardPrimaryText">
                    {license.lockNft}/{license.nft}
                  </span>
                </div>

                <div className='flex gap-2 w-full min-w-40'>
                <div className="flex items-center justify-center gap-2">
                  <small className="block text-cardSecondaryText text-nowrap">
                    Staking Period:
                  </small>
                  
                </div>
                <span className=" text-cardPrimaryText text-nowrap">
                    {capitalizeFirstLetter(
                      license.collateralLockedPeriod ?? ''
                    )}{' '}
                    {license.collateralLockedPeriod == 'max'
                      ? 'Period'
                      : 'Months'}
                  </span>
</div>

               <div className='flex justify-center items-center'>
                <div className="text-end">
                  <Tooltips
                    placement="top-end"
                    wrapperClassName="bg-white p-3 rounded-lg shadow text-sm max-w-[180px]"
                    content={'Default license cannot be de-linked'}
                    isDisabled={!license.defaultLicense}
                  >
                    <button
                      onClick={() => handleDelink(license.userlicenseId)}
                      disabled={
                        license.defaultLicense ||
                        delinkLoadingId === license._id
                      }
                      className="flex justify-center text-nowrap bg-white border hover:text-primary/90 border-primary rounded-xl py-0.5 px-2 text-xs font-medium text-primary min-w-14 disabled:border-0 disabled:bg-[#C0D5DF]/60 disabled:text-white"
                    >
                      {delinkLoadingId === license.userlicenseId ? (
                        <DataLoader width={15} />
                      ) : (
                        'De-link'
                      )}
                    </button>
                  </Tooltips>
                </div>
                </div>
              </div>
            ))
          )}
        </>
      )}
    </div>
  );
};

export default SingleMinterItemDetails;

const LabelValue = ({
  label,
  value,
}: {
  label: string;
  value: string | ReactNode;
}) => (
  <div className="flex flex-col gap-2 p-2">
    <small className="flex-shrink-0 block text-xs text-cardSecondaryText text-nowrap">
      {label}
    </small>
    <span className="flex-shrink-0 text-sm text-secondaryBlack text-nowrap">
      {value}
    </span>
  </div>
);
