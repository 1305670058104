import { ReactComponent as DashedLine } from '../../../SVGIcons/dots.svg';
import { ReactComponent as HorizontalDashedLine } from '../../../SVGIcons/horizontal-dashed.svg';
import { ReactComponent as CopyIcon } from '../../../SVGIcons/copy-2.svg';
import { ReactComponent as ViewIcon } from '../../../SVGIcons/view.svg';
import { ReactComponent as ViewOffIcon } from '../../../SVGIcons/view-off.svg';
import { useState } from 'react';
import { quantwisePlainImage } from '../../../images/Support';
import { toast } from 'react-toastify';
import useCopyTextHook from '../../../hooks/useCopyTextHook';
import { usePlatformAction } from '../../../store/platform/platformAction';
import { RotatingLines } from 'react-loader-spinner';
import { ENV_VARS } from '../../../utils/constants';
import moment from 'moment';
import { CopyVoucher, QuantWiseLOgo, RedeemVoucher, ViewOffVoucher, ViewOnVoucher } from '../../../images/svgs';
import { voucherBgLines, voucherBottom, voucherLine1, voucherLine2, voucherSeperator, voucherStars, voucherTop } from '../../../images';

interface Props {
    backgroundColor?: string;
    voucher: {
        expiryAt: Date;
        createdAt: Date;
        voucherCode: string;
        purchasedAt: string;
        redeemedAt: string;
        type: string;
        validity: string;
        _id: string;
    };
    redeemBtn: boolean;
    color: any;
}

const VoucherCardNew = ({ backgroundColor, voucher, redeemBtn, color }: Props) => {
    const [visible, setVisible] = useState(false);
    const { writeClipboardText } = useCopyTextHook();
    const { platformLogin } = usePlatformAction();
    const [loading, setLoading] = useState(false);
    const handleCopy = async () => {
        writeClipboardText(voucher.voucherCode);
        toast.success('Copied to clipboard');
    };
    const handlePlatformLogin = async () => {
        setLoading(true);
        const res = await platformLogin(ENV_VARS.QUANTWISE_CONSTANT.externalId);
        if (res?.status === 200) {
            window.open(res?.data?.redirectUri, '_blank');
        }
        setLoading(false);
    };

    return (
        // <div
        //     style={{ backgroundColor: backgroundColor }}
        //     className="card w-full md:w-fit rounded-lg md:px-2.5 text-white relative before:absolute before:size-72 before:-left-32 before:-bottom-32 before:bg-white/30 before:rounded-full overflow-hidden bg-bubbleStar bg-no-repeat bg-right-top"
        // >
        //     <div className="w-1 rounded-lg h-[calc(100%-32px)] bg-white absolute left-0 top-1/2 transform -translate-y-1/2 mx-2 hidden md:block"></div>

        //     <div className="relative z-10 flex flex-col py-4 md:flex-row md:py-0">
        //         <div className="flex flex-col items-center justify-center px-5 lg:px-7 md:py-7">
        //             <img
        //                 src={quantwisePlainImage}
        //                 alt="Quantwise logo"
        //                 className="mx-auto border-2 border-[#97D6FF] bg-white h-9 w-9 mb-2 rounded-lg overflow-hidden object-contain	"
        //             />
        //             <h5 className="mb-1 text-lg font-bold">QUANTWISE</h5>
        //             <div className="block mb-1 text-lg font-bold md:hidden">
        //                 {voucher?.type?.toUpperCase()}&nbsp;
        //                 {voucher?.validity?.toUpperCase()}
        //             </div>
        //             <p className="mb-2 text-[11.5px]  font-light md:hidden block">
        //                 Subscription without our limited-time coupon.
        //             </p>
        //             <div className="mb-2 text-xs font-medium text-black">
        //                 Your Voucher Code
        //             </div>
        //             <div className="flex justify-end items-center px-2 py-2 rounded bg-white/15 voucher-dashed-border mb-3 min-w-[186px] ">
        //                 <div className="text-xs font-medium text-black pl-1 mr-2.5 mx-auto">
        //                     {visible ? voucher.voucherCode : 'XXXXXXXXXXXXXXXX'}
        //                 </div>
        //                 {visible && (
        //                     <button
        //                         className="flex items-center justify-center w-5 h-5 bg-white border rounded-md border-primary/50"
        //                         onClick={handleCopy}
        //                     >
        //                         <CopyIcon />
        //                     </button>
        //                 )}
        //                 <button
        //                     className="bg-white border  border-primary/50 rounded-md flex items-center justify-center h-5 w-5 ml-0.5"
        //                     onClick={() => setVisible(!visible)}
        //                 >
        //                     {visible ? <ViewOffIcon /> : <ViewIcon />}
        //                 </button>
        //             </div>
        //         </div>

        //         <DashedLine className="hidden h-auto shrink-0 md:block " />
        //         <HorizontalDashedLine className="w-full md:hidden" />

        //         <div className="flex flex-col items-center justify-center mt-1 md:items-start md:py-6 md:px-6 md:mt-0 md:w-[450px]">
        //             <div className="hidden mb-1 text-xl font-bold md:block">
        //                 {voucher?.type?.toUpperCase()}&nbsp;
        //                 {voucher?.validity?.toUpperCase()}
        //             </div>
        //             <p className="text-sm font-light md:block">Date of Purchase</p>
        //             <span className="text-xs ">
        //                 {voucher.purchasedAt
        //                     ? moment(voucher.purchasedAt).format('DD-MM-YYYY')
        //                     : '-'}
        //             </span>

        //             <div className="flex justify-between my-3 w-[50%] ">
        //                 <div>
        //                     <p className="text-sm font-light md:block">Validity</p>
        //                     <span className="text-xs">
        //                         {voucher.validity ? voucher.validity : '-'}
        //                     </span>
        //                 </div>

        //                 <div>
        //                     <p className="text-sm font-light md:block">Expiry Date</p>
        //                     <span className="text-xs ">
        //                         {voucher.expiryAt
        //                             ? moment(voucher.expiryAt).format('DD-MM-YYYY')
        //                             : '-'}
        //                     </span>
        //                 </div>
        //             </div>
        //             {voucher.expiryAt && new Date(voucher.expiryAt) > new Date() && (
        //                 <button
        //                     disabled={disableBtn}
        //                     className={`${disableBtn ? 'bg-[#CECECE] border-[#6B6B6B] text-[#6B6B6B]' : 'bg-white text-primary border-primary'} flex justify-center items-center rounded-md border  px-2 py-1.5 text-xs drop-shadow-md mx-auto md:mx-0 min-w-[88px]`}
        //                     onClick={handlePlatformLogin}
        //                 >
        //                     {loading ? (
        //                         <RotatingLines
        //                             visible={true}
        //                             width="15"
        //                             strokeWidth="3"
        //                             animationDuration="0.75"
        //                             strokeColor="#1b9fda"
        //                         />
        //                     ) : disableBtn ? (
        //                         'Redeemed'
        //                     ) : (
        //                         'Redeem now'
        //                     )}
        //                 </button>
        //             )}
        //         </div>
        //     </div>
        //     <div className="w-1 rounded-lg h-[calc(100%-32px)] bg-white absolute right-0 top-1/2 transform -translate-y-1/2 mx-2 hidden md:block"></div>
        // </div>
        <div className='border rounded-3xl bg-[#fafafa] p-6 max-w-[385px] relative'>
            <div className='flex flex-col gap-4 py-8 px-5 items-center relative'
                style={{ backgroundImage: color.primary }}
            >
                <img
                    src={voucherBgLines}
                    alt="Voucher BG Lines"
                    className={`absolute top-0 z-0 w-full`}
                />
                <img
                    src={voucherStars}
                    alt="Voucher starts"
                    className={`absolute top-0 right-0 z-0`}
                />
                <img
                    src={voucherLine1}
                    alt="Voucher Line 1"
                    className={`absolute -top-16 left-8 z-0`}
                />
                <img
                    src={voucherLine2}
                    alt="Voucher Line 2"
                    className={`absolute right-8 top-8 z-0`}
                />
                <img
                    src={voucherTop}
                    alt="Voucher top"
                    className={`absolute top-0 z-10 w-full h-auto px-1`}
                />
                <img
                    src={voucherSeperator}
                    alt="Voucher Seperator"
                    className={`absolute bottom-20 z-20 w-full`}
                />
                <img
                    src={voucherBottom}
                    alt="Voucher bottom"
                    className={`absolute -bottom-[2px] z-10 w-full h-auto px-1`}
                />
                <div className='z-10'>
                    <div className='p-3 rounded-2xl backdrop-blur-34'
                        style={{ backgroundColor: color.mainBox }}>
                        <div className='p-5 rounded-2xl shadow-voucherInnerShadow'
                            style={{ backgroundColor: color.secondBox }}>
                            <QuantWiseLOgo />
                        </div>
                    </div>
                </div>
                <div className='font-baumans font-normal text-lg z-10'>{voucher?.type?.toUpperCase()}</div>
                <div className={`border border-white rounded-3xl flex flex-col w-full p-1 justify-center items-center z-10 ${redeemBtn ? 'gap-1' : ''}`}>
                    <span className='font-normal text-sm text-darkGrey'>Voucher Code</span>
                    <div className='flex flex-wrap gap-1'>
                        <span className={`font-semibold text-sm text-secondaryBlack ${!visible ? 'relative top-1' : ''}`}>{(visible || !redeemBtn) ? voucher.voucherCode : '*********'}</span>
                        {
                            visible && redeemBtn &&
                            <button onClick={handleCopy}>
                                <CopyVoucher className='flex-shrink-0' />
                            </button>
                        }
                        {
                            redeemBtn &&
                            <button onClick={() => setVisible(!visible)}>
                                {visible ? <ViewOffVoucher className='flex-shrink-0' /> : <ViewOnVoucher className='flex-shrink-0' />}
                            </button>
                        }
                    </div>
                </div>
                <div className='flex gap-2 z-10'>
                    <span className='font-normal text-sm text-darkGrey'>Date of purchase</span>
                    <span className='font-semibold text-sm text-secondaryBlack'>{moment(voucher.createdAt).format('DD/MM/YYYY')}</span>
                </div>
                <div className='flex gap-2 z-10'>
                    <span className='font-normal text-sm text-darkGrey'>Validity</span>
                    <span className='font-semibold text-sm text-secondaryBlack'>{voucher?.validity?.toUpperCase()}</span>
                </div>
                <button className={`flex justify-center gap-2 rounded-3xl px-10 py-3 border backdrop-blur-174 drop-shadow-redeem_inset w-full z-10 mt-10 h-11 ${redeemBtn ? 'cursor-pointer' : 'bg-white cursor-default'}`}
                    onClick={redeemBtn ? handlePlatformLogin : undefined}>
                    {loading ? (
                        <RotatingLines
                            visible={true}
                            width="15"
                            strokeWidth="3"
                            animationDuration="0.75"
                            strokeColor={color.secondBox}
                        />
                    ) :
                        (
                            <>
                                <span className={`font-bold text-sm ${redeemBtn ? 'text-darkGrey' : 'text-secondaryGreen'}`}>{redeemBtn ? 'REDEEM' : 'REDEEMED'}</span>
                                {
                                    redeemBtn &&
                                    <RedeemVoucher className='flex-shrink-0' />
                                }
                            </>
                        )}
                </button>
            </div>

        </div >
    );
};

export default VoucherCardNew;
