import { Input } from '@nextui-org/react';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { SearchIcon, Spinner } from '../../images/svgs';

type Props = {
  handleOnSearch: (e: any) => void;
  isLoading?: boolean;
  placeholderText?: string;
  baseClassName?: string;
  inputClassName?: string;
  value: string;
  disabled?: boolean;
};

const GlobalSearch = ({
  placeholderText,
  value,
  handleOnSearch,
  isLoading,
  baseClassName,
  inputClassName,
  disabled = false,
}: Props) => {
  const startContent = <SearchIcon className="w-4 h-4 text-searchColor" />;
  const endContent = <Spinner className="w-4 h-4 animate-spin text-primary" />;
  const isTabletView = useMediaQuery({
    query: '(min-width: 1500px)',
  });

  const isSmallDeviceView = useMediaQuery({
    query: '(min-width: 769px)',
  });
  const isTabletLgView = useMediaQuery({
    query: '(min-width: 1241px)',
  });

  return (
    <motion.div
      className={`relative ${baseClassName}`}
      animate={{
        width: isTabletView
          ? '20%'
          : isTabletLgView
            ? '30%'
            : isSmallDeviceView
              ? '60%'
              : '100%',
      }}
      transition={{ duration: 0.3 }}
    >
      <Input
        classNames={{
          base: `w-full h-10 border text-xs font-medium border-cardBorder px-2 rounded-lg bg-white ${baseClassName}  `,
          mainWrapper: 'h-full',
          input: `text-small text-text-defaultBlack ${inputClassName}`,
          inputWrapper: `h-full font-normal text-defaultBlack bg-default-400/20`,
        }}
        placeholder={placeholderText || 'Type to search...'}
        size="sm"
        startContent={startContent}
        endContent={isLoading ? endContent : null}
        type={isLoading ? 'text' : 'search'}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          handleOnSearch(e);
        }}
      />
    </motion.div>
  );
};

export default GlobalSearch;
