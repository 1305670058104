import { useSelector } from 'react-redux';
import { useSuperNodeActions } from '../../../store/superNode/superNodeActions';
import { selectSuperNodeSlice } from '../../../store/superNode/superNodeSlice';
import TreeCard from './TreeCard';
import { useEffect } from 'react';
import { selectUserSlice } from '../../../store/user/userSlice';

function TreeNodes({ selectedReward }: { selectedReward: any }) {
  const { getBonusReports } = useSuperNodeActions();

  const { superNodeBonusReports, superNodeBonusReportsLoading } =
    useSelector(selectSuperNodeSlice);
  const { userInfo } = useSelector(selectUserSlice);
  const formatDate = () => {
    const date = new Date(selectedReward?.createdAt);
    return date.toISOString().split('T')[0];
  };

  useEffect(() => {
    if (!superNodeBonusReportsLoading) {
      getBonusReports({
        fromUser: selectedReward?.fromUser._id,
        type: selectedReward?.type,
        // cloudkTrx:selectedReward?.cloudkTrx,
        cloudKtrx: selectedReward?.cloudkTrx,
        date: formatDate(),
        page: 1,
        limit: 10,
      });
    }
  }, []);
  const baseReward = superNodeBonusReports?.baseReward;
  return (
    <div className="flex flex-col scroll-bottom h-[63vh] gap-0 px-12 py-8 overflow-y-auto border rounded-md border-primary ">
      {/* {baseReward && (
        <TreeCard
          rewardLevel={baseReward?.rewardData?.currentLevel || null}
          bid={baseReward?.user?.blockchainId || null}
          loss={baseReward?.loss}
          isMachingBonus={baseReward?.isMachingBonus}
          receivable={baseReward?.receivable}
          name={baseReward?.user?.username || ''}
          percentage={baseReward?.rewardData?.percentage}
          amount={baseReward?.tokenAmount}
          level={0}
          length={1}
          number={0}
          cardname={baseReward?.type}
          type={baseReward?.type}
          reason={baseReward?.lostReason}
          profilePicture={baseReward?.user?.profilePicture}
          selectedUserbid={selectedReward?.fromUser?.blockchainId}
          baseLevel={true}
        />
      )} */}
      {superNodeBonusReports &&
        superNodeBonusReports?.result?.map((node, index, reversedArray) => {
          // const reverseIndex = reversedArray.length - 1 - index;
          return (
            <TreeCard
              key={index}
              rewardLevel={node?.rewardData?.currentLevel || null}
              bid={node?.user?.blockchainId || null}
              loss={node?.loss}
              isMachingBonus={node?.isMachingBonus}
              receivable={node?.receivable}
              name={node?.user?.username || ''}
              percentage={node?.rewardData?.percentage}
              nodeKAmount={node?.rewardData?.amount}
              amount={node?.amount}
              level={index}
              length={superNodeBonusReports?.result?.length}
              number={index}
              cardname={node?.type}
              type={node?.type}
              reason={node?.lossData}
              lossReason={node?.lostReason}
              profilePicture={node?.user?.profilePicture}
              selectedUserbid={userInfo?.userProfile?.blockchainId}
              baseLevel={false}
            />
          );
        })}
    </div>
  );
}

export default TreeNodes;
