import CollapsibleTable from '../../../../components/CollapsibleTable';
import LinkIcon from '@mui/icons-material/Link';
import { useCloudkActions } from '../../../../store/cloudk/cloudkAction';
import { useEffect, useState } from 'react';
import { MinterDetails, MinterListItem } from '../Common/types';
import MinterPlaceholder from '../../../../assets/minter-placeholder.png';
import { ReactComponent as ConnectIcon } from '../../../../SVGIcons/connect.svg';
import SingleMinterItemDetails from './components/SingleMinterItemDetails';
import GlobalModal from '../../../../components/Global/GlobalModal';
import HardwareDetailModal from '../CloudOverview/components/HardwareDetailModal/HardwareDetailModal';
import ConnectNewMinter from '../CloudOverview/components/ConnectNewMinter/ConnectNewMinter';
import DataLoader from '../../../../components/DataLoader/DataLoader';
import ConnectThroughQR from '../CloudOverview/components/ConnectThroughQR/ConnectThroughQR';
import LinkedLicense from '../LinkedLicense/LinkedLicense';
import { EmptyWithdrawHistoryGif } from '../../../../images/wallets';
import CloudKSelect from '../Common/CloudKSelect/CloudKSelect';
import CloudKSearchBar from '../Common/CloudKSearchBar/CloudKSearchBar';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@nextui-org/react';
import HardwareDetailsModalBody from '../CloudOverview/components/HardwareDetailsModalV2';
import { setDecimalPlaces } from '../../../../utils/helper';

interface SelectMinterForAction {
  minter: MinterListItem;
  actionType:
  | 'VIEW_DETAILS'
  | 'CONNECT_MINTER'
  | 'CONNECT_PHONE'
  | 'LINK_LICENSE';
}
const CloudKMintersList = () => {
  const navigate = useNavigate();
  const [minters, setMinters] = useState<MinterListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState<any>({
    page: 1,
    limit: 10,
    query: '',
  });

  const [selectedMinter, setSelectedMinter] =
    useState<SelectMinterForAction | null>(null);
  const [loadedMintersLicenses, setLoadedMintersLicenses] = useState<any>({});
  const [minterRebootLoadingId, setMinterRebootLoadingId] = useState<
    string | null
  >();

  const { getAllMintersList, rebootMinter, deLinkLicenseFunc } =
    useCloudkActions();

  useEffect(() => {
    handleFetchMintersList();
  }, [filters]);
  useEffect(() => {
    if (selectedMinter && minters.length > 0) {
      const updatedMinter = minters.find((m: any) => m._id === selectedMinter?.minter._id);
      if (updatedMinter) {
        setSelectedMinter({ minter: { ...updatedMinter, minterData: updatedMinter }, actionType: selectedMinter.actionType } as any);
      }
    }
  }, [minters]);

  const handleFetchMintersList = async () => {
    setLoading(true);
    setLoadedMintersLicenses({});
    const response = await getAllMintersList(filters);
    if (response.data?.data?.list) {
      setMinters(response.data?.data?.list);
      setTotalPages(response.data?.data.totalPages);
    }
    setLoading(false);
  };

  const handleRebootMinter = async (minterId: string) => {
    setMinterRebootLoadingId(minterId);
    await rebootMinter(minterId);
    setMinterRebootLoadingId(null);
  };

  const handleDelink = async (payload: {
    minterId: string;
    licenseId: string;
  }) => {
    const response = await deLinkLicenseFunc(payload);
    if (response?.data?.status) {
      setLoadedMintersLicenses((prev: any) => ({
        ...prev,
        [payload?.minterId]: null,
      }));
      handleFetchMintersList();
    }
  };

  const LinkButton = ({ minter }: { minter: MinterListItem }) => {
    const linkingDisabled = minter.totalLockNft === minter.maxNftHold;
    return (
      <Tooltip
        placement="top-end"
        content={'No available NFT capacity'}
        isDisabled={!linkingDisabled}
        className='bg-white text-xs p-2 shadow-md rounded-lg'
      >
        <button
          disabled={linkingDisabled || minter.status == 'awaiting'}
          className="bg-primary hover:bg-primary/90 h-[22px] rounded-xl py-0 px-2 text-xs font-medium text-white disabled:bg-primaryGrey/60 disabled:cursor-not-allowed"
          onClick={(e) => {
            e.stopPropagation();
            const minterPayload = { ...minter, minterData: minter };
            setSelectedMinter({
              minter: minterPayload,
              actionType: 'LINK_LICENSE',
            });
          }}
        >
          <LinkIcon className="!w-5 text-white" /> Link
        </button>
      </Tooltip>
    );
  };
  const MinterLinkedLicense = ({ minter }: { minter: MinterListItem }) => {
    if (minter.linkedLicenses.length === 0) return '-';
    const license = minter.linkedLicenses?.[0];
    return (
      <div className="">
        <span>{license?.name}</span>
        {minter.linkedLicenses.length > 1 && (
          <>
            ,{' '}
            <button className="text-primary hover:underline">
              +{minter.linkedLicenses?.length - 1} more
            </button>
          </>
        )}
      </div>
    );
  };

  const rows = minters.map((minter: MinterListItem) => {
    return {
      id: minter._id,
      minter: (
        <div className="flex gap-3 items-center min-w-max">
          <img
            className="h-12"
            src={minter.image}
            alt="Minter"
            onError={(e: any) => {
              e.target.src = MinterPlaceholder;
            }}
          />
          <span className="font-medium">{minter.name}</span>
        </div>
      ),
      linkedLicenses: <MinterLinkedLicense minter={minter} />,
      linkedNFTs: (
        <span className="font-medium text-sm">
          {minter.totalLockNft}/{minter.maxNftHold}
        </span>
      ),
      rewards: (
        <div className="flex flex-col gap-0.5 text-[#616161] text-sm">
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 rounded-full " style={{
              backgroundColor: '#7FC9FF'
            }}>
              </div>
            LYK-R:{' '}
            <span className="font-medium text-cardPrimaryText">
              {setDecimalPlaces(Number(minter.minterRewards.total_lyk_r || 0), 3)}

            </span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 rounded-full bg-primaryExtraLight" style={{
              backgroundColor: '#7683FF'
            }}>
            </div>
            LYK-W:{' '}
            <span className="font-medium text-cardPrimaryText">
              {setDecimalPlaces(Number(minter.minterRewards.total_lyk_w || 0), 3)}
            </span>
          </div>
        </div>
      ),
      actions: (
        <>
          {minter.status == 'connected' ? (
            <div className="flex items-center gap-1">
              <LinkButton minter={minter} />
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRebootMinter(minter._id);
                }}
                className="flex items-center justify-center bg-white border border-primary hover:text-primary/90 rounded-xl py-0.5 px-2 text-xs font-medium text-primary disabled:border-primaryGrey/60 disabled:cursor-not-allowed min-w-16"
                disabled={minterRebootLoadingId == minter._id}
              >
                {minterRebootLoadingId == minter._id ? (
                  <DataLoader width={15} />
                ) : (
                  'Reboot'
                )}
              </button>
            </div>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedMinter({
                  minter: { ...minter, minterData: minter },
                  actionType:
                    minter.type === 'minter' ? 'CONNECT_MINTER' : 'CONNECT_PHONE',
                });
              }} // this needs to be refactored as the component need to be more dynamic
              className="flex gap-1 items-center bg-primary hover:bg-primary/90 h-[22px] rounded-xl py-0.5 px-2 text-xs font-medium text-white disabled:bg-primaryGrey/60 disabled:cursor-not-allowed"
            >
              <ConnectIcon className="text-white" /> Connect
            </button>
          )}
        </>
      ),
      collapsedElement: (
        <SingleMinterItemDetails
          linkedLicenses={loadedMintersLicenses?.[minter._id]}
          onMinterLinkedLicensesLoad={(licenses) =>
            setLoadedMintersLicenses((prev: any) => ({
              ...prev,
              [minter._id]: licenses,
            }))
          }
          onDelinkLicense={handleDelink}
          onViewDetails={(minter) =>
            setSelectedMinter({ minter, actionType: 'VIEW_DETAILS' })
          }
          minter={minter}
        />
      ),
    }
  } );

  const handleCloseModal = () => {
    setSelectedMinter(null);
    handleFetchMintersList();
  };

  const mapStatusFilters = () => {
    return [
      { label: 'Awaiting', value: 'awaiting' },
      { label: 'Connected', value: 'connected' },
    ];
  };
  return (
    <div className="">
      <div className="flex flex-wrap items-center mb-11">
        <span
          onClick={() => navigate('/defi-360/cloudk')}
          className="text-xl cursor-pointer font-baumans"
        >
          CloudK Overview {'>'} &nbsp;
        </span>
        <span className="text-2xl font-baumans">Minters</span>
      </div>

      <div className="flex justify-between mb-8 flex-wrap">
        <div className="text-lg font-medium">Minters Table</div>
        <div className="flex items-center gap-2">
          <CloudKSearchBar
            value={filters.query}
            onChange={(query) =>
              setFilters((prev: any) => ({ ...prev, query }))
            }
          />
          <CloudKSelect
            selectedValue={filters.status}
            options={mapStatusFilters()}
            placeholder={'Status'}
            onChange={(option) =>
              setFilters((prev: any) => ({ ...prev, status: option.value }))
            }
          />
          <button
            className="text-primary text-xs font-medium h-10 disabled:text-primaryGrey"
            onClick={() => {
              setFilters((prev: any) => ({ ...prev, status: null, query: '' }));
            }}
            disabled={!filters.status && !filters.query}
          >
            Reset
          </button>
        </div>
      </div>

      <CollapsibleTable
        columns={columns}
        rows={rows}
        loading={loading}
        NoDataComponent={renderNoDateFound()}
        totalPages={totalPages}
        currentPage={filters.page}
        onPageChange={(page) => setFilters((prev: any) => ({ ...prev, page }))}
      />

      <GlobalModal
        isOpen={selectedMinter != null}
        size="3xl"
        childrenClass={
          selectedMinter?.actionType == 'CONNECT_PHONE'
            ? '!px-2 sm:!px-6 !py-6  small-scroll relative'
            : '!px-0 !py-0 small-scroll'
        }
        noHeader={true}
        headerClassName="!p-6  font-medium text-lg text-[#0E173F]"
        onClose={() => setSelectedMinter(null)}
      >
        {renderModalBody()}
      </GlobalModal>
    </div>
  );

  function renderModalBody() {
    if (selectedMinter) {
      const { minter, actionType } = selectedMinter || {};

      switch (actionType) {
        case 'VIEW_DETAILS':
          return <HardwareDetailsModalBody minter={minter as any} onRefresh={handleFetchMintersList} />;
        case 'LINK_LICENSE':
          return (
            <LinkedLicense
              minter={minter}
              handleClose={() => {
                handleCloseModal();
                setLoadedMintersLicenses((prev: any) => ({
                  ...prev,
                  [minter!._id]: null,
                }));
              }}
            />
          );
        case 'CONNECT_MINTER':
          return (
            <ConnectNewMinter
              minter={minter}
              onConnectionSuccess={handleCloseModal}
            />
          );
        case 'CONNECT_PHONE':
          return (
            <ConnectThroughQR
              phoneMinter={minter}
              handleClose={handleCloseModal}
            />
          );
        default:
          return null;
      }
    }
  }

  function renderNoDateFound() {
    return (
      <div className="flex flex-col w-full items-center mt-5">
        <img
          src={EmptyWithdrawHistoryGif}
          alt="empty transactions"
          className="w-44 h-auto"
        />
        <span className="text-primary xxs:text-sm  sm:text-[16px] font-medium mt-2">
          Sorry, No Hardwares Found.
        </span>
      </div>
    );
  }
};

const columns = [
  {
    label: 'Minter',
    key: 'minter',
  },
  {
    label: 'Linked Licenses',
    key: 'linkedLicenses',
  },
  {
    label: 'Linked NFTS',
    key: 'linkedNFTs',
  },
  {
    label: 'Rewards',
    key: 'rewards',
  },
  {
    label: '',
    key: 'actions',
  },
];
export default CloudKMintersList;
