import React, { ReactNode, memo } from 'react';
import { toast } from 'react-toastify';
import { CopyIcon } from '../../images/svgs';
import RangeDatePicker from '../../pages/Wallet/RangeDatePicker';
import { ReactComponent as Spinner } from '../../SVGIcons/spinner.svg';
import { formatDate, isIsoDateString } from '../../utils/helper';
import NoData from '../Table/NoData';
import CopyItemsView from './CopyItemsView';
import GlobalDivider from './GlobalDivider';
import PaginationButtons from './GlobalPaginatedButtons';
import GlobalSearch from './GlobalSearch';
import GlobalSkeleton from './GlobalSkeleton';
import TooltipItemView from './TooltipItemView';
import HoverBorderAnimation from '../HoverBorderAnimation';
import { motion } from 'framer-motion';

type Props = {
  rows: any[];
  isLoading?: boolean | undefined;
  headCells?: {
    id: string;
    label: ReactNode;
    dataClass?: string;
    // rowDataClass?: string;
  }[];
  totalPages: number;
  handlePageChange?: (num: number) => void;
  handleDateDate?: (start: any, end: any) => void;
  tableTitle?: string;
  page?: number;
  paginate?: boolean;
  columnPadding?: string;
  bodyClass?: string;
  rowBorder?: boolean;
  pagination?: boolean;
  showTotalRecords?: boolean;
  filterButtonWrapper?: React.JSX.Element | false;
  DownloadCSV?: React.JSX.Element | false;
  searchFieldPlaceholderText?: string;
  showTopFilterWithButtonsRow?: boolean;
  height?: string;
  headStyles?: string;
  bodyStyles?: string;
  setSearchValue?: any;
  searchValue?: string | any;
  searchIsLoading?: boolean;
  sortMenuItems?: string[];
  sortMenuHandleClick?: (selectionType: string) => void;
  emptyDataMessage?: string;
  paginationPosition: 'center' | 'right' | 'left' | undefined;
  rounded?: boolean;
  borderClassName?: string;
  showSearch?: boolean;
  copyItems?: string[];
  toolTipItems?: string[];
  colorItems?: string[];
  handleRowClick?: (index: number) => void;
  showHeader?: boolean;
  showFilters?: boolean;
  searchClassName?: string;
  filterSearchClassName?: string;
  filterSearchPlaceholderText?: string;
  dropdownButtonClassname?: string;
  setFilterSearchValue?: (value: React.SetStateAction<string>) => void;
  firstDropdownData?: {
    key: string;
    label: string;
  }[];
  secondDropdownData?: {
    key: string;
    label: string;
  }[];
  thirdDropdown?: {
    key: string;
    label: string;
  }[];
  firstDropDownText?: string;
  secondDropDownText?: string;
  thirdDropdownText?: string;
  filterSearchBaseClassName?: string;
  dropDownWithValues?: boolean;
  showAccordion?: string;
  tableContainerClassname?: string;
  containerStyles?: string;
  tableBodyClassnames?: string;
  tableDropdowns?: any;
  showDateFilter?: boolean;
  setAccordion?: React.Dispatch<React.SetStateAction<string>>;
  openModal?: (id: string) => void;
  resetDateFilter?: boolean;
  toDateClassName?: string;
  fromDateClassName?: string;
  tableWidth?: string;
  dateArrowAllow?: boolean;
  mainContainerClassnames?: string;
  isFirstLineUser?: boolean;
  externalLoader?: boolean;
  isMobile?: any;
  isWallet?: boolean;
  selectedButton?: string;
  filterClass?: string;
  isFullHeight?: boolean;
  isCellClass?: boolean;
  productAsACol?: boolean;
  stopHoverEffect?: boolean;
  searchdisable?: boolean;
  fromDateDisable?: boolean;
  toDateDisable?: boolean;
  setDateChanged?: (value: React.SetStateAction<boolean>) => void;
  showMoreDetail?: boolean;
  data?: any;
  linkOrUnLock?: boolean;
  fromDate?: any;
  toDate?: any;
};

const GlobalTable = ({
  rows = [],
  isLoading,
  headCells = [],
  handlePageChange,
  handleDateDate,
  totalPages,
  page,
  columnPadding,
  rowBorder,
  pagination = true,
  showTotalRecords = true,
  filterButtonWrapper = false,
  searchFieldPlaceholderText,
  headStyles,
  bodyStyles,
  bodyClass = '',
  height,
  searchValue,
  searchIsLoading,
  paginationPosition,
  emptyDataMessage,
  rounded,
  borderClassName,
  showSearch = true,
  copyItems,
  toolTipItems,
  colorItems,
  handleRowClick,
  showHeader = true,
  showDateFilter = true,
  filterSearchClassName,
  filterSearchBaseClassName,
  showAccordion,
  tableDropdowns,
  setAccordion,
  tableContainerClassname,
  filterClass = '',
  isFullHeight = true,
  DownloadCSV,
  setSearchValue,
  openModal,
  isCellClass = true,
  containerStyles,
  tableBodyClassnames,
  resetDateFilter = false,
  toDateClassName,
  fromDateClassName,
  tableWidth,
  dateArrowAllow = false,
  mainContainerClassnames,
  isFirstLineUser = false,
  externalLoader = false,
  isWallet = false,
  selectedButton,
  productAsACol,
  isMobile,
  stopHoverEffect = false,
  searchdisable,
  fromDateDisable,
  toDateDisable,
  setDateChanged,
  fromDate,
  toDate,
}: Props) => {
  const writeClipboardText = async (text: string) => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(text);
      } else {
        fallbackWriteClipboardText(text);
      }
    } catch (error) {
      fallbackWriteClipboardText(text);
    }
  };

  const fallbackWriteClipboardText = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999);

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error('Fallback copy to clipboard failed: ', error);
    }

    document.body.removeChild(textArea);
  };

  if (externalLoader && rows.length === 0) {
    return (
      <div className="flex items-center self-center justify-center flex-1 h-full">
        <div className="flex flex-col gap-5">
          <Spinner className="h-6 animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className={`w-full h-full ${mainContainerClassnames}`}>
      <div
        className={`flex items-center flex-wrap justify-between w-full ${filterClass}`}
      >
        {showSearch ||
        showDateFilter ||
        filterButtonWrapper ||
        tableDropdowns ||
        DownloadCSV ? (
          <article className="flex flex-col justify-between w-full gap-2 mb-5 sm:flex-row">
            <div className="flex flex-wrap items-center flex-1 gap-3">
              {showSearch && (
                <GlobalSearch
                  placeholderText={
                    searchFieldPlaceholderText || 'search in the admins...'
                  }
                  value={searchValue ? searchValue : ''}
                  handleOnSearch={(e) => {
                    if (setSearchValue) {
                      setSearchValue(e.target.value);
                    }
                  }}
                  isLoading={searchIsLoading ? true : false}
                  baseClassName={` ${filterSearchBaseClassName}`}
                  inputClassName={filterSearchClassName}
                  disabled={searchdisable}
                />
              )}
              <div className="flex flex-wrap gap-3">
                {showDateFilter && (
                  <RangeDatePicker
                    handleDateDate={handleDateDate}
                    resetFilter={resetDateFilter}
                    toDateClassName={toDateClassName}
                    fromDateClassName={fromDateClassName}
                    dateArrowAllow={dateArrowAllow}
                    fromDateDisable={fromDateDisable}
                    toDateDisable={toDateDisable}
                    setDateChanged={setDateChanged}
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                )}

                {filterButtonWrapper ? filterButtonWrapper : ''}
                {tableDropdowns ? tableDropdowns : ''}
              </div>
            </div>

            <div className="col-span">{DownloadCSV ? DownloadCSV : ''}</div>
          </article>
        ) : (
          ''
        )}
      </div>

      <HoverBorderAnimation
        className={`${height ? height : 'h-full border  '} ${!stopHoverEffect && 'radius-5px rounded-lg'}  w-full col-span-12 bg-white  relative ${containerStyles}`}
        hoverAnimation={!stopHoverEffect}
      >
        <div
          className={`hidden lg:block  flex-col items-center gap-2 overflow-x-auto    ${
            borderClassName ? '' : rowBorder ? 'border border-borderGray' : ''
          } ${rounded ? 'rounded-lg' : ''} ${tableContainerClassname}   overflow-y-hidden`}
        >
          <table
            className={`table-bordered border-collapse  ${tableWidth ? tableWidth : 'min-w-[1000px]'} w-full  ${isFullHeight ? 'h-full' : ''}`}
          >
            {showHeader && (
              <thead className="sticky top-0 z-30 table w-full bg-white table-fixed  ">
                <tr
                  className={`${
                    rows.length > 0 ? 'text-black' : 'text-textSecondary'
                  } w-full ${headStyles}`}
                >
                  {headCells &&
                    headCells.map((cell, index) => (
                      <th
                        key={index}
                        className={`hidden md:table-cell text-left ${productAsACol && cell.id === 'product' && 'md:text-center'} font-semibold text-sm p-3 ${
                          rowBorder ? 'border-b border-borderGray' : ''
                        } ${cell.dataClass} ${headStyles} border-b`}
                      >
                        {cell.label}
                      </th>
                    ))}
                </tr>
              </thead>
            )}

            {rows.length > 0 ? (
              isLoading ? (
                <tbody
                  className={`${tableBodyClassnames ? tableBodyClassnames : 'min-h-[15rem] max-h-[30rem] overflow-y-auto'} small-scroll  small-scroll block w-full table-fixed ${bodyClass} `}
                >
                  {[...new Array(rows.length)].map((item, index) => (
                    <tr
                      key={index}
                      className={`font-medium text-sm cursor-pointer table w-full table-fixed ${bodyStyles} ${
                        rowBorder || ''
                      }  ${showAccordion?.length ? (showAccordion === rows[index].id ? 'bg-[#89D5ED33]' : '') : ''}`}
                    >
                      {headCells &&
                        headCells.map((cell, index) => (
                          <td
                            key={index}
                            className={`table-cell font-normal tracking-[0.1px] text-left text-sm truncate text-ellipsis ${
                              rowBorder ? 'border-b border-borderGray' : ''
                            } ${bodyStyles || 'text-[#AAAAAA]'} p-3 ${
                              isCellClass ? cell.dataClass : ''
                            } ${columnPadding || ''} border-b border-borderGray`}
                          >
                            <GlobalSkeleton
                              animationValue="pulse"
                              height={40}
                              width={100}
                            />
                          </td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody
                  className={`${tableBodyClassnames ? tableBodyClassnames : 'min-h-[15rem] max-h-[30rem] overflow-y-auto'} small-scroll  small-scroll block w-full table-fixed ${bodyClass} `}
                >
                  {rows.map((row, index) => {
                    return (
                      <div>
                        {' '}
                        <tr
                          key={index}
                          className={`font-medium text-sm cursor-pointer table w-full table-fixed ${bodyStyles} ${
                            rowBorder || ''
                          }  ${showAccordion?.length ? (showAccordion === rows[index].id ? 'bg-[#89D5ED33]' : '') : ''}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRowClick && handleRowClick(index);
                            setAccordion &&
                              setAccordion((prev) =>
                                prev !== rows[index].id ? rows[index].id : ''
                              );

                            openModal && openModal(rows[index].id);
                          }}
                        >
                          {headCells &&
                            headCells.map((cell, index) => {
                              let color = '';
                              if (
                                row[cell.id] === 'success' ||
                                row[cell.id] === 'approved'
                              )
                                color = 'text-primaryGreen';
                              else if (row[cell.id] === 'pending')
                                color = 'text-primary';
                              else if (row[cell.id] === 'failed')
                                color = 'text-primaryRed';

                              const cellValue = row[cell.id];

                              return (
                                <td
                                  key={index}
                                  className={`table-cell font-normal tracking-[0.1px] text-left content-center text-sm truncate text-ellipsis ${
                                    rowBorder
                                      ? 'border-b border-borderGray'
                                      : ''
                                  } ${bodyStyles || 'text-[#AAAAAA]'} p-3 ${
                                    isCellClass
                                      ? cell.dataClass
                                      : `${index === 0 ? '!pl-6' : ''}`
                                  } ${columnPadding || ''} border-b border-borderGray`}
                                >
                                  {copyItems?.includes(headCells[index].id) ? (
                                    <CopyItemsView cellValue={cellValue} />
                                  ) : toolTipItems?.includes(
                                      headCells[index].id
                                    ) ? (
                                    <TooltipItemView cellValue={cellValue} />
                                  ) : (
                                    <>
                                      {colorItems?.includes(
                                        headCells[index].id
                                      ) ? (
                                        <span className={`${color}`}>
                                          {' '}
                                          {!cellValue ? '-' : cellValue}
                                        </span>
                                      ) : (
                                        <>
                                          {isIsoDateString(cellValue)
                                            ? formatDate(cellValue)
                                            : !cellValue
                                              ? '-'
                                              : cellValue}
                                        </>
                                      )}
                                    </>
                                  )}
                                </td>
                              );
                            })}
                        </tr>
                      </div>
                    );
                  })}
                </tbody>
              )
            ) : (
              ''
            )}
          </table>
        </div>

        {rows?.length < 1 ? (
          <NoData
            visible={rows?.length < 1 && !isLoading}
            text={emptyDataMessage}
            textClassName="!text-defaultBlack"
            isWallet={isWallet}
            selectedButton={selectedButton}
          />
        ) : (
          <div className="flex lg:hidden flex-col gap-3 md:-0 p-4 xxs:overflow-auto small-scroll xxs:h-[420px]">
            <div className="flex flex-col w-full gap-2 text-sm text-black">
              {isLoading &&
                headCells &&
                headCells.map((cell, index) => (
                  <div key={index} className={`w-full flex items-start `}>
                    <div className="w-1/3">{cell.label}:</div>
                    <div className="w-2/3">
                      <GlobalSkeleton animationValue="pulse" height={40} />
                    </div>
                  </div>
                ))}
              {rows.map((row, index) => (
                <div
                  key={index}
                  className={`flex flex-col gap-2 `}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRowClick && handleRowClick(index);
                    openModal && openModal(rows[index]?.id);
                  }}
                >
                  {headCells.map((cell, index) => {
                    let color = '';
                    if (
                      row[cell.id] === 'success' ||
                      row[cell.id] === 'approved'
                    )
                      color = 'text-primaryGreen';
                    else if (row[cell.id] === 'pending') color = 'text-primary';
                    else if (row[cell.id] === 'failed')
                      color = 'text-primaryRed';

                    const cellValue = row[cell.id];

                    return (
                      <div
                        key={index}
                        className={`w-full flex items-start ${isFirstLineUser && `xxs:flex-col  md:flex-row xxs:gap-2 md:gap-0`} ${isMobile && productAsACol && cell.id === 'product' && 'flex-col '}`}
                      >
                        <div
                          className={`w-1/3 ${isFirstLineUser && 'text-primaryGrey'}`}
                        >
                          {isFirstLineUser && cell.label
                            ? cell.label.toString().replace(/\s+/g, '') ===
                              'View'
                              ? ''
                              : cell.label
                            : cell.label}
                        </div>
                        <div
                          className={`w-2/3 flex ${isFirstLineUser ? 'xxs:text-[20px]' : 'overflow-x-scroll xxs:text-[14px]'}  xxs:font-[500] md:font-[400] ${isFirstLineUser ? 'xxs:justify-start' : 'xxs:justify-end'} md:justify-start`}
                        >
                          {copyItems?.includes(headCells[index].id) ? (
                            <div className="flex items-center">
                              {cellValue?.length > 5
                                ? cellValue.slice(0, 4) +
                                  '......' +
                                  cellValue.slice(cellValue?.length - 4)
                                : !cellValue
                                  ? '-'
                                  : cellValue}
                              <CopyIcon
                                className="ml-3 cursor-pointer"
                                onClick={() => {
                                  // navigator.clipboard.writeText(cellValue);
                                  writeClipboardText(row[cell.id] || '');
                                  toast.success('Copied!');
                                }}
                              />
                            </div>
                          ) : toolTipItems?.includes(headCells[index].id) ? (
                            <TooltipItemView cellValue={cellValue} />
                          ) : (
                            <>
                              {colorItems?.includes(headCells[index].id) ? (
                                <span className={`${color}`}>
                                  {!cellValue ? '-' : cellValue}
                                </span>
                              ) : (
                                <>
                                  {isIsoDateString(cellValue)
                                    ? formatDate(cellValue)
                                    : !cellValue
                                      ? '-'
                                      : cellValue}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <GlobalDivider className="my-3 text-black" />
                </div>
              ))}
            </div>
          </div>
        )}

        {pagination && totalPages > 1 && rows.length > 0 ? (
          <div className="self-end px-4 mt-5 rounded-lg">
            <PaginationButtons
              totalPage={totalPages}
              page={page}
              onChange={handlePageChange}
              paginationPosition={paginationPosition}
            />
          </div>
        ) : (
          ''
        )}

        {showTotalRecords && rows?.length ? (
          <div className="px-4 text-base font-normal text-secondaryGray">
            Showing {rows?.length} of{' '}
            {totalPages < 1 ? rows?.length : rows?.length * totalPages} records
          </div>
        ) : (
          ''
        )}
      </HoverBorderAnimation>
    </div>
  );
};

export default memo(GlobalTable);
