import { useEffect, useRef, useState } from 'react'
import GlobalModal from '../Global/GlobalModal'
import { FeedBackIcon, FlowerIcon, Spinner } from '../../images/svgs';
import GlobalButton from '../Global/GlobalButton';
import confetti from 'canvas-confetti';
import { useGeneralActions } from '../../store/general/generalActions';
import useUserCheck from '../../hooks/useUserCheck';
interface Props {
    isOpen: boolean;
    onClose: () => void;
    goToViewProducts?: () => void;
    title: string;
    message: string;
    showConfetti?: boolean;
    viewProducts?: boolean;
    subMessage: string;
}
const TrustPilotModal = ({ isOpen, onClose, title, message, showConfetti = true, viewProducts = false, goToViewProducts, subMessage }: Props) => {
    const { redirectToTrustPilot } = useGeneralActions()
    const { user } = useUserCheck();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [widgetLoaded, setWidgetLoaded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setWidgetLoaded(false)
        if ((window as any).Trustpilot) {
            (window as any).Trustpilot.loadFromElement(ref.current, true);
        }
        let timeout: NodeJS.Timeout;
        timeout = setTimeout(() => {
            setWidgetLoaded(true);
        }, 800);
        if (isOpen && showConfetti) {
            const x = window.innerWidth / 2;
            const y = window.innerHeight / 2;
            confetti({
                origin: {
                    x: x / window.innerWidth,
                    y: y / window.innerHeight,
                },
                zIndex: 9999999
            });
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isOpen]);
    const handleNavigation = async () => {
        setIsLoading(true)
        let response = await redirectToTrustPilot(user)
        setIsLoading(false)
        window.open(response?.url, "_blank", "noopener,noreferrer")
        onClose()
    }
    return (
        <GlobalModal
            isOpen={isOpen}
            onClose={onClose}
            size='xl'
        >
            <div className='flex justify-center'>
                <div className='relative'>
                    <div className='rounded-full bg-yellow-500 h-12 w-12 absolute -right-4 -top-2 flex items-center justify-center'>
                        <FlowerIcon />
                    </div>
                    <FeedBackIcon />
                </div>
            </div>
            <div className='flex flex-col gap-3 items-center'>
                <div className='text-xl font-medium'>{title}</div>
                <div className='text-base font-medium'>{message}</div>
            </div>
            <hr />
            <div className='mb-2 flex justify-center'>
                {!widgetLoaded && <Spinner className="h-6 animate-spin" />}
                <div ref={ref} className={`trustpilot-widget pointer-events-none ${widgetLoaded ? 'block' : 'hidden'}`} data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="66b7c4e61fa29c02898a53cb" data-style-height="115px" data-style-width="100%">
                    <a target="_blank" rel="noopener">Trustpilot</a>
                </div>
            </div>
            <div className='flex justify-center text-black text-sm font-normal text-center'>
                {subMessage}
            </div>
            <div className='flex justify-end gap-1 mb-3'>
                {
                    !viewProducts &&
                    <GlobalButton
                        classNames="bg-white border border-primary rounded text-primary shadow-btnBlueShadow"
                        text="Later"
                        onClick={onClose}
                    />
                }
                {
                    viewProducts &&
                    <GlobalButton
                        classNames="bg-white border border-primary rounded text-primary shadow-btnBlueShadow"
                        text="View Products"
                        onClick={goToViewProducts}
                    />
                }
                <GlobalButton
                    text="Review Us"
                    onClick={handleNavigation}
                    classNames={`bg-primary shadow-btnBlueShadow`}
                    disabled={isLoading}
                    loading={isLoading}
                />
            </div>
        </GlobalModal>
    )
}

export default TrustPilotModal