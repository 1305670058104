import DatePicker from 'react-datepicker';
import { ReactComponent as ArrowDownIcon } from '../../../../../SVGIcons/arrowDown.svg';
import moment from 'moment';

interface Props {
  date?: Date;
  placeholder?: string;
  onDateChange: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  startDate?: Date;
  endDate?: Date;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  disabled?: boolean

}
const CloudkDatePicker = ({
  date,
  placeholder,
  onDateChange,
  minDate,
  maxDate,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  disabled = false
}: Props) => {

  return (
    <DatePicker
      enableTabLoop={false}
      dateFormat="yyyy-MM-dd"
      className={`${disabled ? 'opacity-60' : ''}`}
      disabled={disabled}
      customInput={
        <div
          className={`flex gap-x-5 justify-between items-center w-full px-3 py-1.5 text-gray-700 bg-white border border-blueGrey rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none
                    `}
        >
          <span className="text-gray-500 text-sm">{placeholder ?? 'Date'}</span>
          <span className="flex items-center gap-2 text-secondaryBlack text-sm">
            {date ? moment(date).format('ll') : 'Any'}
            <ArrowDownIcon className={`transform transition-transform h-3 `} />
          </span>
        </div>
      }
      selected={date}
      onChange={onDateChange}
      minDate={minDate}
      maxDate={maxDate}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      startDate={startDate}
      endDate={endDate}
    />
  );
};

export default CloudkDatePicker;
