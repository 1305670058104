import React from 'react'
import { InactiveCross, TickCheck } from '../../../images/svgs';

export interface StatusCheckProps {
    name: string;
    status: boolean;
}
function StatusCheck({ name, status }: StatusCheckProps) {
    return (
        <div className={`text-xs lg:text-sm flex justify-between gap-8 items-center ${status === true ? 'bg-[#B2E084] text-[#458902] border-b-2 border-[#458902]' : 'bg-[#E8ABAB] text-[#890202] border-b-2 border-[#890202]'}  px-3 py-0.5 rounded-md`}>
            {name}
            {status === true ? <TickCheck /> : <InactiveCross />}
        </div>
    )
}

export default StatusCheck
