import { useEffect } from 'react';
import AllHardwares from './components/AllHardwares';
import AllLicenses from './components/AllLicenses';
import Heading from './components/Heading';
import RewardAnalytics from './components/RewardAnalytics';
import RewardGenerated from './components/RewardGenerated';
import TopMachines from './components/TopMachines';
import TotalLicenses from './components/TotalLicenses';
import TotalLinkedMlyk from './components/TotalLinkedMlyk';
import TotalRewardGenerated from './components/TotalRewardGenerated';
import TransactionHistory from './components/TransactionHistory/TransactionHistory';
import { useCloudkActions } from '../../../../store/cloudk/cloudkAction';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../store/cloudk/cloudkSlice';
import ActivityHistoryTable from '../shared/ActivityHistoryTable';

const CloudkOverview = () => {
  const { claimableRewardData, totalLicenses, totalStacked } = useSelector(selectCloudSlice)
  const { getActiveLicensesCountFunc, getTotalLinkedFunc, cloudKClaimableReward, } = useCloudkActions()
  useEffect(() => {
    if (!claimableRewardData) {
      cloudKClaimableReward()
    }
    if (!totalLicenses) {
      getActiveLicensesCountFunc()
    }

    if (Object.keys(totalStacked).length === 0) {
      getTotalLinkedFunc()
    }
  }, [])

  return (
    <div className="flex flex-col gap-6">
      <Heading />
      <article className="space-y-5 lg:space-y-0 lg:grid grid-cols-12 gap-5">
        <RewardAnalytics />
        <RewardGenerated />
      </article>

      <article className="space-y-5 lg:space-y-0 lg:grid grid-cols-12 gap-5">
        <TotalLicenses />
        <TotalRewardGenerated />
        <TotalLinkedMlyk />
        <TopMachines />
        {/* <TotalLinked /> */}
        {/* <TotalStaked /> */}
      </article>
      <article className="space-y-5 lg:space-y-0 lg:grid grid-cols-12 gap-5">
        <AllLicenses />
      </article>

      <article className="space-y-5 lg:space-y-0 lg:grid grid-cols-12 gap-5">
        <AllHardwares />
      </article>
      {/* <TransactionHistory /> */}
      <ActivityHistoryTable limit={5} />
    </div>
  );
};

export default CloudkOverview;
