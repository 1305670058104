/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GlobalSelectMui from '../../../../components/Global/GlobalSelectMui';
import { RootState } from '../../../../store';
import {
  DEPOSIT_SLICE_NAME,
  depositSliceAction,
} from '../../../../store/wallets/depositSlice';
import DepositStepper from './DepositStepper';
import { selectWalletData } from '../../../../store/walletData/walletDataSlice';

const DepositStep2 = ({
  depositStakeTabSelected,
}: {
  depositStakeTabSelected: boolean;
}) => {
  const dispatch = useDispatch();
  const { selectedNetwork, selectedCoin }: any = useSelector(
    (state: RootState) => state[DEPOSIT_SLICE_NAME]
  );
  const { isOnChainWallet } = useSelector(selectWalletData);

  const updateValue = (value: any) =>
    dispatch(
      depositSliceAction.setDepositSlice({
        ...value,
      })
    );

  useEffect(() => {
    if (selectedNetwork) {
      updateValue({ currentStep: 3 });
    }
  }, [selectedNetwork]);

  // useEffect(() => {
  //   if (depositSettings?.length > 0) {
  //     updateValue({
  //       selectedNetwork: {
  //         ...depositSettings[0]?.networks[0],
  //         value: depositSettings[0]?.networks[0].name,
  //       },
  //     });
  //   }
  // }, [depositSettings]);

  return (
    <div className="flex">
      <div className="w-10 lg:w-14">
        <DepositStepper
          step={2}
          enableBackground={selectedNetwork}
          lineEnable={depositStakeTabSelected ? true : selectedNetwork}
        />
      </div>

      <div className="flex-1">
        <div className="flex flex-col gap-2">
          <div className="text-defaultBlack text-sm font-medium">
            Select Network <span className="text-redDanger">*</span>
          </div>
          {selectedCoin && (
            <>
              <GlobalSelectMui
                required={true}
                options={
                  selectedCoin?.networks?.length
                    ? selectedCoin?.networks?.map((item: any) => ({
                        ...item,
                        ...item.networksData,
                        value: item.networksData?.name,
                      }))
                    : []
                }
                selectedOption={selectedNetwork}
                setSelectedOption={(e: any) => {
                  updateValue({ selectedNetwork: e });
                }}
                // isError={error?.fromWallet}
                disabled={selectedCoin ? false : true}
                placeHolder={'Select a Network'}
                firstAutoSelected={false}
              />

              <div className="text-primaryGrey text-xs -mt-1">
                The minimum deposit amount should be{' '}
                {selectedCoin?.minDisplayAmount}
                <span className="uppercase">
                  {['usdt', 'usdk-w'].includes(selectedCoin?.symbol)
                    ? selectedCoin?.symbol === 'usdt'
                      ? ' USDT'
                      : ' USDK'
                    : ` ${selectedCoin?.valueType}`}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DepositStep2;
