import { ReactElement, useCallback, useEffect, useState } from 'react';
import GlobalHeading from '../../components/Global/GlobalHeading';
import SuperNodeMetrics from './_components/SuperNodeMetrics ';
import TotalNodes from '../../images/supernode/total-nodes.svg';
import TotalNodesIFL from '../../images/supernode/total-nodes-IFL.svg';
import TotalNodesWM from '../../images/supernode/total-nodes-WM.svg';
import TotalNodesWOM from '../../images/supernode/total-nodes-WOM.svg';
import TotalActiveNodes from '../../images/supernode/total-active-nodes.svg';
import TotalInactiveNodes from '../../images/supernode/total-inactive-nodes.svg';
import SuperNodeMainCard from './_components/SuperNodeMainCard';
import {
  YesterdayRwardsImg,
  TotalGaskImg,
  ClaimableRewardsImg,
} from '../../images/supernode/index';
import RewardsMainCard from './_components/RewardsMainCard';
import BonusMainCard from './_components/BonusMainCard';
import CurrentLossRewardsCard from './_components/CurrentLossRewardsCard';
import { useSuperNodeActions } from '../../store/superNode/superNodeActions';
import { useSelector } from 'react-redux';
import { selectSuperNodeSlice } from '../../store/superNode/superNodeSlice';
import {
  toFixedWithoutRoundingOff,
  truncateToSixDecimals,
} from '../../utils/utils';
import SupernodeMyReports from './_components/MyReports';
import { selectUserSlice } from '../../store/user/userSlice';
import { useUserActions } from '../../store/user/userActions';
import StatusCheck from './_components/StatusCheck';
import Tabs from '../../components/Tabs';

const tabs = [
  { label: 'Total Rewards', key: 'total-reward' },
  { label: 'Base Referral', key: 'base-referral' },
  { label: 'Builder Generational', key: 'builder-generational' },
  // { label: 'Builder Referral', key: 'builder-referral' },
  { label: 'Matching Bonus', key: 'matching-bonus' },
];

function SuperNode2(): ReactElement {
  const {
    superNodeRewardsAnalytics,
    getSuperNodeRewardLoading,
    getRewardAnalyticsLoading,
    getTotalGaskLoading,
    getCurrentLossOfRewardsLoading,
    currentLossOfRewardsLossFilter,
    totalProductionSummaryRewards,
    getTotalProductionSummaryLoading,
    currentLossOfRewardsLoss,
    totalRewardSummary,
    totalRewardSummaryFilter,
    getTotalRewardSummaryLoading,
    totalGask,
    lastDayGask,
    dailySuperNodeRewards,
    RewardSummaryBuilderGenerational,
    superNodeSuperNodeRewardsLoading,
    RewardSummaryBaseReferral,
    RewardSummaryBaseReferralFilter,
    getRewardSummaryBuilderGenerationalLoading,
    getRewardSummaryBaseReferralLoading,
    superNodeSuperNodeRewards,
    totalProductionSummaryRewardsFilter,
    RewardSummaryBuilderGenerationalFilter,
    superNodeTotalRewardsClaimed,
    getSuperNodeTotalRewardsClaimedLoading,
  } = useSelector(selectSuperNodeSlice);

  const { getUserInfo } = useUserActions();

  const { userInfo } = useSelector(selectUserSlice);

  const profileData = userInfo?.userProfile;

  const {
    getCurrentLossOfRewards,
    getTotalGask,
    getRewardAnalytics,
    getSuperNodeDailyRewards,
    getTotalProductionSummary,
    getTotalRewardSummary,
    getRewardSummary,
    getSuperNodeTotalRewardsClaimed,
  } = useSuperNodeActions();

  const [selectedButton, setSelectedButton] = useState<string>('total-reward');

  const SuperNodeMainCardData = [
    {
      name: 'Yesterday Rewards',
      value:
        Number(
          toFixedWithoutRoundingOff(dailySuperNodeRewards?.totalToken || 0, 6)
        ) || 0,
      image: YesterdayRwardsImg,
      token: dailySuperNodeRewards?.rewardToken?.name,
      previous: 'Previous Rewards',
      previousValue:
        Number(
          toFixedWithoutRoundingOff(
            dailySuperNodeRewards?.lastDay?.totalToken || 0,
            6
          )
        ) || 0,
    },
    {
      name: 'Total GASK',
      value: Number(toFixedWithoutRoundingOff(totalGask || 0, 2)) || 0,
      image: TotalGaskImg,
      token: null,
      previous: 'Previous GASK',
      previousValue:
        Number(toFixedWithoutRoundingOff(lastDayGask || 0, 2)) || 0,
    },
    {
      name: 'Claimable Rewards',
      value:
        Number(
          toFixedWithoutRoundingOff(
            superNodeRewardsAnalytics?.claimableRewards || 0,
            6
          )
        ) || 0,
      image: ClaimableRewardsImg,
      token: superNodeRewardsAnalytics?.rewardToken?.name,
      previous: 'Previous Balance',
      previousValue:
        Number(
          toFixedWithoutRoundingOff(
            superNodeRewardsAnalytics?.lastDay?.claimableRewards || 0,
            6
          )
        ) || 0,
    },
  ];

  const currentLossRewardsData = {
    mainTitle: 'Current Loss of Rewards',
    statisticsTop: [
      {
        title: 'Insufficient GASK',
        tooltipContent:
          'Loss occurs when your GASK balance is exhausted, stopping SuperNode rewards, stake more to increase your GASK',
        amount: currentLossOfRewardsLoss?.insufficientGask || 0,
        statusPercentage: 60,
        token: null,
      },
      {
        title: 'Daily Cap',
        tooltipContent:
          'Indicates loss from reaching the daily cutoff limit. Upgrade to higher-value machines to avoid this.',
        amount: currentLossOfRewardsLoss?.dailyCapping || 0,
        statusPercentage: 25,
        token: null,
      },
      {
        title: 'Inactive First Line',
        tooltipContent:
          'Shows loss from inactive first-line users, reducing referral bonuses. Encourage your first line users to minimize loss.',
        amount: Number(
          toFixedWithoutRoundingOff(
            currentLossOfRewardsLoss?.inActiveFirstUser || 0,
            2
          )
        ),
        statusPercentage: 25,
        token: null,
      },
    ],
    statisticsBottom: [
      {
        title: 'Total Loss',
        tooltipContent: '',
        amount: Number(
          toFixedWithoutRoundingOff(currentLossOfRewardsLoss?.netTotal || 0, 2)
        ),
        statusPercentage: 75,
        token: currentLossOfRewardsLoss?.token?.name,
      },
    ],
    layoutId: 'CurrentLossRewards-active',
  };

  const rewardsSuperNodeData = {
    mainTitle: 'Supernode Rewards',
    statistics: [
      {
        title: 'Total Rewards',
        tooltipContent: 'Total rewards earned by all the nodes',
        amount:
          Number(
            truncateToSixDecimals(totalRewardSummary?.totalReward ?? 0, 2)
          ) || 0,
        statusPercentage: 25,
      },
      {
        title: 'Total Base Referral',
        tooltipContent: 'Total Base Referral rewards earned by all the nodes',
        amount:
          Number(
            truncateToSixDecimals(
              totalRewardSummary?.totalBaseReferralReward ?? 0,
              2
            )
          ) || 0,
        statusPercentage: 50,
      },
      {
        title: 'Total Builder Generational',
        tooltipContent:
          'Total Builder Generational rewards earned by all the nodes',
        amount:
          Number(
            truncateToSixDecimals(
              totalRewardSummary?.totalBuilderGenReward ?? 0,
              2
            )
          ) || 0,
        statusPercentage: 80,
      },
    ],
    layoutId: 'supernode-rewards-active',
  };

  const rewardsProductionData = {
    mainTitle: 'Total Production',
    statistics: [
      {
        title: 'My Production',
        tooltipContent:
          'Displays the total rewards you have earned from staking in NodeK.',
        amount:
          Number(
            truncateToSixDecimals(
              totalProductionSummaryRewards?.myProduction ?? 0,
              2
            )
          ) || 0,
        statusPercentage: 25,
      },
      {
        title: 'Team Production',
        tooltipContent:
          'Shows the total rewards your entire team has earned as a result of staking in NodeK.',
        amount:
          Number(
            truncateToSixDecimals(
              totalProductionSummaryRewards?.teamProduction ?? 0,
              2
            )
          ) || 0,
        statusPercentage: 50,
      },
      {
        title: 'First Line Production',
        tooltipContent:
          'Represents the total rewards obtained by your first line users from staking in NodeK.',
        amount:
          Number(
            truncateToSixDecimals(
              totalProductionSummaryRewards?.firstLineProduction ?? 0,
              2
            )
          ) || 0,
        statusPercentage: 80,
      },
    ],
    layoutId: 'prodcution-active',
  };

  const baseReferalData = {
    key: 'base-referral',
    mainTitle: 'Base Referral',
    statisticsUser: [
      {
        title: 'Total Users',
        tooltipContent:
          'Total number of users from where im getting Base Referral.',
        amount:
          Number(
            truncateToSixDecimals(RewardSummaryBaseReferral?.totalUsers ?? 0, 2)
          ) || 0,
        statusPercentage: 60,
      },
      {
        title: 'Total Users First Line',
        tooltipContent: 'Total number of users in my First Line',
        amount:
          Number(
            truncateToSixDecimals(
              RewardSummaryBaseReferral?.firstLineUsers ?? 0,
              2
            )
          ) || 0,
        statusPercentage: 25,
      },
    ],
    statisticsRewards: [
      {
        title: 'Total Rewards',
        tooltipContent: 'Total Base Referral rewards earned by all the nodes',
        amount:
          Number(
            truncateToSixDecimals(
              RewardSummaryBaseReferral?.totalRewards ?? 0,
              2
            )
          ) || 0,
        statusPercentage: 75,
      },
      {
        title: 'Total Rewards First Line',
        tooltipContent:
          'Total Base Referral rewards earned by First Line Nodes',
        amount:
          Number(
            truncateToSixDecimals(
              RewardSummaryBaseReferral?.firstLineRewards ?? 0,
              2
            )
          ) || 0,
        statusPercentage: 50,
      },
    ],
    layoutId: 'basereferal-active',
  };

  const builderGenerationalData = {
    key: 'builder-generational',
    mainTitle: 'Builder Generational',
    statisticsUser: [
      {
        title: 'Total Users',
        tooltipContent:
          'Total number of users from where im getting Builder Generational',
        amount:
          Number(
            truncateToSixDecimals(
              RewardSummaryBuilderGenerational?.totalUsers ?? 0,
              2
            )
          ) || 0,
        statusPercentage: 60,
      },
      {
        title: 'Total Users First Line',
        tooltipContent: 'Total number of users in my First Line',
        amount:
          Number(
            truncateToSixDecimals(
              RewardSummaryBuilderGenerational?.firstLineUsers ?? 0,
              2
            )
          ) || 0,
        statusPercentage: 25,
      },
    ],
    statisticsRewards: [
      {
        title: 'Total Rewards',
        tooltipContent:
          'Total Builder Generational rewards earned by all the nodes',
        amount:
          Number(
            truncateToSixDecimals(
              RewardSummaryBuilderGenerational?.totalRewards ?? 0,
              2
            )
          ) || 0,
        statusPercentage: 75,
      },
      {
        title: 'Total Rewards First Line',
        tooltipContent:
          'Total Builder Generational rewards earned by First Line Nodes',
        amount:
          Number(
            truncateToSixDecimals(
              RewardSummaryBuilderGenerational?.firstLineRewards ?? 0,
              2
            )
          ) || 0,
        statusPercentage: 50,
      },
    ],
    layoutId: 'builderGenerational-active',
  };

  const superNodesMetricData = [
    {
      name: 'Total Nodes',
      value: profileData?.totalNode || 0,
      iconName: TotalNodes,
    },
    {
      name: 'Total Nodes In First line',
      value: profileData?.firstLineNode || 0,
      iconName: TotalNodesIFL,
    },
    {
      name: 'Total Nodes With  Membership',
      value: profileData?.totalUserwithMembership || 0,
      iconName: TotalNodesWM,
    },
    {
      name: 'Total Nodes Without Membership',
      value: profileData?.totalUserwithoutMembership || 0,
      iconName: TotalNodesWOM,
    },
    {
      name: 'Total Nodes With Machines',
      value: profileData?.totalUserwithMachine || 0,
      iconName: TotalActiveNodes,
    },
    {
      name: 'Total Nodes Without Machines',
      value: profileData?.totalUserwithoutMachine || 0,
      iconName: TotalInactiveNodes,
    },
  ];

  const handleButtonClick = useCallback(
    async (buttonText: string): Promise<void> => {
      setSelectedButton(buttonText);
    },
    []
  );

  useEffect((): void => {
    if (!getTotalGaskLoading && !totalGask) {
      getTotalGask();
    }
    if (!getSuperNodeRewardLoading && !dailySuperNodeRewards) {
      getSuperNodeDailyRewards();
    }
    if (!getRewardAnalyticsLoading && !superNodeRewardsAnalytics) {
      getRewardAnalytics();
    }
    if (!getCurrentLossOfRewardsLoading && !currentLossOfRewardsLoss) {
      getCurrentLossOfRewards({ period: 'day' });
    }
    if (!getTotalProductionSummaryLoading && !totalProductionSummaryRewards) {
      getTotalProductionSummary({ period: 'day' });
    }
    if (!getTotalRewardSummaryLoading && !totalRewardSummary) {
      getTotalRewardSummary({ period: 'day' });
    }
    if (!getRewardSummaryBaseReferralLoading && !RewardSummaryBaseReferral) {
      getRewardSummary({ type: 'base-referral', period: 'day' });
    }
    if (
      !getRewardSummaryBuilderGenerationalLoading &&
      !RewardSummaryBuilderGenerational
    ) {
      getRewardSummary({ type: 'builder-generational', period: 'day' });
    }
    if (
      !getSuperNodeTotalRewardsClaimedLoading &&
      !superNodeTotalRewardsClaimed
    ) {
      getSuperNodeTotalRewardsClaimed();
    }

    // if (!userInfo) {
    //   getUserInfo();
    // }
    getUserInfo();
  }, []);

  return (
    <>
      <div className="w-full bg-supperNode_banner bg-cover bg-no-repeat bg-center mb-[70px] relative">
        <div className="px-4 pt-10 space-y-8 md:px-10 sm:px-8">
          <GlobalHeading heading={'Super Node'} className="flex-1 text-white" />
          <div className="flex flex-wrap items-center justify-between pb-5 space-y-3 lg:space-y-0">
            <div className="flex flex-col w-auto gap-5 sm:flex-row sm:items-center">
              <div className="text-lg text-white">My Status</div>
              <div className="flex items-center gap-3">
                <StatusCheck
                  name="Base Referral"
                  status={profileData?.isBaseReferralActive}
                />
                <StatusCheck
                  name="Builder Generational"
                  status={profileData?.isBuilderGenerationActive}
                />
              </div>
            </div>
            {/* <SuperNodeFilterTabs layoutID="banner-active" /> */}
          </div>
        </div>
        <div className="mx-4 backdrop-blur-sm rounded-t-xl bg-white shadow-lg shadow-[##00000080] bg-opacity-50 pt-6 px-6">
          <div className="grid grid-cols-1 gap-3 pb-32 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
            {superNodesMetricData &&
              superNodesMetricData?.map(
                (data, index): ReactElement => (
                  <SuperNodeMetrics key={index} {...data} />
                )
              )}
          </div>
          <div className="left-0 right-0 grid w-full grid-cols-1 gap-3 -translate-y-10 md:grid-cols-2 lg:grid-cols-3 lg:absolute md:-translate-y-14 lg:-translate-y-1/2 lg:px-6">
            {SuperNodeMainCardData &&
              SuperNodeMainCardData?.map(
                (data, index): ReactElement => (
                  <SuperNodeMainCard key={index} {...data} />
                )
              )}
          </div>
        </div>
      </div>
      <section className="px-4 md:px-10 sm:px-8">
        <div className="pt-10">
          <p className="pb-5 text-lg font-medium text-black">My Rewards</p>
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-1 xl:grid-cols-2">
            <RewardsMainCard
              {...rewardsProductionData}
              getData={getTotalProductionSummary}
              isLoading={getTotalProductionSummaryLoading}
              selectedFilterTab={totalProductionSummaryRewardsFilter}
            />
            <RewardsMainCard
              {...rewardsSuperNodeData}
              getData={getTotalRewardSummary}
              isLoading={getTotalRewardSummaryLoading}
              selectedFilterTab={totalRewardSummaryFilter}
            />
          </div>
        </div>
        <div className="pt-10">
          <p className="pb-5 text-lg font-medium text-black">My Bonuses</p>
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
            <BonusMainCard
              {...baseReferalData}
              getData={(event: string): Promise<any> =>
                getRewardSummary({ type: 'base-referral', period: event })
              }
              isLoading={getRewardSummaryBaseReferralLoading}
              selectedFilterTab={RewardSummaryBaseReferralFilter}
            />
            <BonusMainCard
              {...builderGenerationalData}
              getData={(event: string): Promise<any> =>
                getRewardSummary({
                  type: 'builder-generational',
                  period: event,
                })
              }
              isLoading={getRewardSummaryBuilderGenerationalLoading}
              selectedFilterTab={RewardSummaryBuilderGenerationalFilter}
            />
            <CurrentLossRewardsCard
              {...currentLossRewardsData}
              getData={getCurrentLossOfRewards}
              isLoading={getCurrentLossOfRewardsLoading}
              selectedFilterTab={currentLossOfRewardsLossFilter}
            />
          </div>
        </div>
        <div className="pt-10 mb-3">
          <p className="mb-2 text-lg font-medium text-black">My Reports</p>
          <div className="flex mt-5 gap-x-3 text-primaryGrey">
            <Tabs
              selectedButton={selectedButton}
              handleButtonClick={handleButtonClick}
              tabs={tabs}
              parentClassName="!px-0 !py-0 !text-nowrap !text-xs"
              className="sm:!py-2"
            />
          </div>
        </div>

        <SupernodeMyReports type={selectedButton} />
      </section>
    </>
  );
}

export default SuperNode2;
