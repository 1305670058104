import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import BlackCircleInfoIcon from '../../SVGIcons/Wallet/BlackCircleInfoIcon';
import LineChart from '../../components/Charts/LineChart';
import {
  TChartDataDefs,
  TChartDataFill,
} from '../../components/types/Charts/lineChart.types';
import {
  selectWalletData,
  setSelectedOptionForLineGraph,
} from '../../store/walletData/walletDataSlice';
import { useEffect, useState } from 'react';
import GlobalSkeleton from '../../components/Global/GlobalSkeleton';
import HoverBorderAnimation from '../../components/HoverBorderAnimation';

const WalletLineChartComp = ({
  totalBalance,
  selected,
  setSelected,
}: {
  totalBalance: number;
  selected: string;
  setSelected: (value: string) => void;
}) => {
  const dispatch = useDispatch();
  const [zeroScreen, setZeroScreen] = useState<boolean>(false);
  const { walletGraphData, graphLoading } = useSelector(selectWalletData);
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const TotalRewardsChartDefs: TChartDataDefs = [
    {
      id: 'gradient1',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: zeroScreen ? '#E6E6E6' : '#D5C3FF' },
        {
          offset: 100,
          color: zeroScreen ? '#E6E6E6' : '#FFFFFF',
        },
      ],
    },
  ];
  const TotalRewardsChartFill: TChartDataFill = [
    { match: '*', id: 'gradient1' },
  ];
  const timeOptions = [
    { title: '7d', value: 'weekly' },
    { title: '1m', value: 'monthly' },
    { title: '6m', value: 'yearly' },
  ];

  const uniqueYValues: any = Array.from(
    new Set(walletGraphData?.map((dataPoint: any) => dataPoint.y))
  ).sort((a: any, b: any) => a - b);

  const yScaleMin = Math.floor(Math.min(...uniqueYValues)); // Floor the min value
  const yScaleMax = Math.ceil(Math.max(...uniqueYValues)); // Ceil the max value

  const tickCount = 5; // Number of ticks
  const step = (yScaleMax - yScaleMin) / (tickCount - 1); // Calculate step based on the range
  const gridYValues = Array.from(
    { length: tickCount },
    (_, i) => Math.round(yScaleMin + i * step) // Generate grid values based on min, max, and step
  );

  // Calculate the width needed for Y-axis labels to avoid cutting them off
  const maxLabelLength = Math.max(...gridYValues).toString().length;
  // Get the length of the longest Y-axis label
  const leftMargin = maxLabelLength * 8 + 12; // Calculate left margin based on label length, 8px per character as an estimate
  useEffect(() => {
    if (!walletGraphData) {
      setZeroScreen(true);
    } else {
      setZeroScreen(false);
    }
  }, [walletGraphData]);

  return (
    <HoverBorderAnimation
      className="xl:col-span-8 col-span-12 rounded-[20px] bg-white border border-[#E6E6E6] h-80 
    flex flex-col relative radius-22px"
    >
      {zeroScreen && (
        <div
          className={`absolute inset-0 rounded-[20px] ${'grayscale opacity-50'}`}
          style={{
            background: `#E6E6E6`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: 0,
          }}
        ></div>
      )}

      <div
        className={`w-full flex items-center ${zeroScreen ? 'justify-between flex-wrap' : 'justify-end'} `}
      >
        {zeroScreen && (
          <div className="flex items-center gap-3 pt-1 ml-4">
            <BlackCircleInfoIcon />

            <span className=" xxs:text-[10px] sm:text-sm">
              This is only for representation purpose
            </span>
          </div>
        )}
        <div className="p-2 bg-[#F3F3F3] gap-3 flex self-end ml-auto rounded-tr-[20px]">
          {timeOptions.map((option) => (
            <button
              key={option.value}
              className={` px-2 md:px-4 py-1 md:py-1 rounded text-[9px] md:text-sm  ${
                selected === option.value ? 'bg-white' : 'bg-[#F3F3F3]'
              }`}
              onClick={() => {
                dispatch(setSelectedOptionForLineGraph(option.value));
                setSelected(option.value);
              }}
            >
              {option.title}
            </button>
          ))}
        </div>
      </div>

      <div className="flex-1">
        {/* {graphLoading ? (
        <div className="flex items-center justify-center flex-1 h-full">
          <div className="items-center self-center justify-center">
            <div className="flex flex-col gap-5">
              <Spinner className="h-6 animate-spin" />
            </div>
          </div>
        </div>
      ) : ( */}
        <div className="h-full chart-container md:py-2 md:px-8">
          {graphLoading && !walletGraphData ? (
            <div className="grid h-full grid-cols-12 ">
              <div className="col-span-2 flex flex-col justify-between  p-4 h-[90%]">
                <GlobalSkeleton
                  animationValue="wave"
                  variant="text"
                  sx={{ fontSize: '20px' }}
                />
                <GlobalSkeleton
                  animationValue="wave"
                  variant="text"
                  sx={{ fontSize: '20px' }}
                />
              </div>
              <div className="flex flex-col col-span-10 gap-3 p-4">
                <GlobalSkeleton
                  animationValue="wave"
                  variant="rectangular"
                  width={'100%'}
                  height={'90%'}
                />
                <div className="flex justify-between gap-3 ">
                  <GlobalSkeleton
                    animationValue="wave"
                    variant="text"
                    sx={{ fontSize: '20px' }}
                    width={'20%'}
                  />
                  <GlobalSkeleton
                    animationValue="wave"
                    variant="text"
                    sx={{ fontSize: '20px' }}
                    width={'20%'}
                  />
                  <GlobalSkeleton
                    animationValue="wave"
                    variant="text"
                    sx={{ fontSize: '20px' }}
                    width={'20%'}
                  />
                  <GlobalSkeleton
                    animationValue="wave"
                    variant="text"
                    sx={{ fontSize: '20px' }}
                    width={'20%'}
                  />
                </div>
              </div>
            </div>
          ) : (
            <LineChart
              // data={TotalRewardsChartData}
              // data={RewardsCalculatorChartData}
              data={[
                {
                  id: 'WalletGraph',
                  data: walletGraphData ? walletGraphData : [],
                },
              ]}
              curve={'basis'}
              defs={TotalRewardsChartDefs}
              fill={TotalRewardsChartFill}
              margin={{
                top: 20,
                right: leftMargin - maxLabelLength * 8,
                bottom: 50,
                left: leftMargin,
              }} // Set equal margins for left and right
              isInteractive={true}
              enablePoints={false}
              enableGridX={false}
              enableGridY={true}
              enableArea={true}
              gridYValues={gridYValues}
              colors={zeroScreen ? ['#E6E6E6'] : ['#D5C3FF']}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0,
              }}
              legends={[]}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickValues: gridYValues,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0,
                format: (value: string | number) => `${value}`,
              }}
              tootlipColor={'#292929'}
              showSub={true}
              theme={{
                text: {
                  fontSize: '12px',
                  fill: '#AAAAAA',
                },
                grid: {
                  line: {
                    stroke: '#E0E0E0',
                    strokeWidth: 1,
                    strokeDasharray: '0',
                  },
                  top: {
                    line: {
                      stroke: 'transparent',
                      strokeWidth: 0,
                    },
                  },
                },
                axis: {
                  ticks: {
                    line: {
                      stroke: '#FFFFFF40',
                    },
                    text: {
                      fontSize: isDesktop ? '11px' : '9px',
                      fill: '#000000B2',
                      fontFamily: 'Rubik, sans-serif',
                      fontWeight: 400,
                      // fill: '#7D7D7D',
                    },
                  },
                },
              }}
            />
          )}
        </div>
        {/* )} */}
      </div>
    </HoverBorderAnimation>
  );
};

export default WalletLineChartComp;
