import { homnifiLogo } from '../../images';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserActions } from '../../store/user/userActions';
import { selectUserSlice } from '../../store/user/userSlice';
import { useSelector } from 'react-redux';
import { FEATURES, PLATFORM_NAME } from '../../utils/constants';
const AuthHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userLogout } = useUserActions();
  const { user } = useSelector(selectUserSlice);

  return location.pathname === '/auth/signin' ? (
    // For Signup
    <div className="flex flex-col items-center justify-between w-full gap-4 px-12 text-sm bg-white border-b 2xl:justify-around 2xl:px-0 py-7 sm:flex-row border-christmasBorder">
      <div className="relative">
        <img
          src={homnifiLogo}
          alt={`${PLATFORM_NAME} Logo`}
          className="cursor-pointer w-36"
          onClick={() => (window.location.href = 'https://homnifi.com')}
        />
      </div>
      {FEATURES.showSignup && (
        <div className="w-full xs:w-auto">
          Don't have any account yet?{' '}
          <button
            onClick={() => navigate('/auth/signup')}
            className="font-medium text-primary"
          >
            Sign Up
          </button>
        </div>
      )}
    </div>
  ) : (
    // For Login
    <div className="flex flex-col justify-between w-full gap-4 px-12 py-8 text-sm bg-white border-b 2xl:justify-around 2xl:px-0 sm:flex-row border-christmasBorder">
      <div className="relative">
        <img
          src={homnifiLogo}
          alt={`${PLATFORM_NAME} Logo`}
          className="cursor-pointer w-36"
          onClick={() => (window.location.href = 'https://homnifi.com')}
        />
      </div>
      {!user ? (
        <div className="w-full xs:w-auto">
          Already have an account?{' '}
          <button
            onClick={() => {
              // userLogout();
              navigate('/auth/signin');
            }}
            className="font-medium text-primary"
          >
            Sign In
          </button>
        </div>
      ) : (
        <div className="w-full xs:w-auto">
          Not {user?.email}?{' '}
          <button onClick={userLogout} className="font-medium text-primary">
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default AuthHeader;
