import { useCallback, useEffect, useRef, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ZoomControls from '../../components/MyTree/ZoomControls';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMyTreeSlice,
  setMyTreeSlice,
  setTreeExpand,
} from '../../store/myTree/myTreeSlice';
import ListingMembers from '../../components/MyTree/ListingMembers';
import Tabs from '../../components/Tabs';
import GlobalButton from '../../components/Global/GlobalButton';
import GlobalHeading from '../../components/Global/GlobalHeading';
import { ReactComponent as ActiveIcon } from '../../SVGIcons/MyTree/active.svg';
import { ReactComponent as InactiveIcon } from '../../SVGIcons/MyTree/inactive.svg';
import { useMyTreeActions } from '../../store/myTree/myTreeActions';
import { Spinner } from '../../images/svgs';
import { NoDataImage } from '../../images/Dashboard';
import GlobalSearch from '../../components/Global/GlobalSearch';
import { FEATURES } from '../../utils/constants';

const tabs: { key: string; label: string }[] = [
  { key: 'ListView', label: 'List View' },
  { key: 'TreeView', label: 'Tree View' },
];

const legendData = ['Membership', 'Base Referral', 'Builder Generational'];

const SuperNodeMyTree = () => {
  const containerRef = useRef(null);

  const dispatch = useDispatch();

  const {
    expand,
    supernodeTreeChildren,
    getSuperNodeTreeChildrenLoading,
    getSuperNodeTreeSearchLoading,
    supernodeTreeSearch,
  } = useSelector(selectMyTreeSlice);

  const { getSuperNodeTreeChildren, getSuperNodeTreeSearch } =
    useMyTreeActions();

  const [searchQuery, setSearchQuery] = useState('');
  const [expandedFlag, setExpandedFlag] = useState<boolean>(false);
  const [selectedButton, setSelectedButton] = useState<string>('ListView');
  const [currentPage, setCurrentPage] = useState<number>(
    supernodeTreeChildren?.page ?? 1
  );

  const [currentSearchPage, setCurrentSearchPage] = useState<number>(
    supernodeTreeSearch?.page ?? 1
  );

  const handleButtonClick = useCallback(
    async (buttonText: string): Promise<void> => {
      setSelectedButton(buttonText);
    },
    []
  );

  function debounce<T extends (...args: any[]) => void>(fn: T, delay: number) {
    let timer: ReturnType<typeof setTimeout> | null = null;
    return function (this: unknown, ...args: Parameters<T>) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  }

  const delaySaveToDb = useCallback(
    debounce((val) => {
      getSuperNodeTreeSearch({ searchQuery: val, limit: 200 });
    }, 1000),
    []
  );

  const handleChange = (e: any) => {
    setSearchQuery(e.target.value);
    delaySaveToDb(e.target.value);
  };

  const handleScroll = () => {
    const container: any = containerRef.current;
    if (
      container.scrollHeight - container.scrollTop ===
      container.clientHeight
    ) {
      if (
        supernodeTreeSearch &&
        currentSearchPage < supernodeTreeSearch?.totalPages
      ) {
        setCurrentSearchPage(currentSearchPage + 1);
        getSuperNodeTreeSearch({
          searchQuery: searchQuery,
          limit: 200,
          page: currentSearchPage + 1,
        });
      } else if (
        supernodeTreeChildren &&
        currentPage < supernodeTreeChildren?.totalPages
      ) {
        setCurrentPage(currentPage + 1);
        getSuperNodeTreeChildren({
          id: null,
          limit: 200,
          page: currentPage + 1,
        });
      }
    }
  };

  const resetFilter = () => {
    setSearchQuery('');
    dispatch(
      setMyTreeSlice({
        supernodeTreeSearch: null,
      })
    );
  };

  useEffect(() => {
    if (!getSuperNodeTreeChildrenLoading) {
      getSuperNodeTreeChildren({
        id: null,
        limit: 200,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(setTreeExpand(false));
  }, [selectedButton, searchQuery]);

  useEffect(() => {
    resetFilter();
  }, [selectedButton]);

  return (
    <div>
      <GlobalHeading
        heading={`My Tree`}
        className="flex-1 !text-defaultBlack"
      />
      <div className="flex flex-col items-start justify-between sm:items-center sm:flex-row">
        <Tabs
          selectedButton={selectedButton}
          handleButtonClick={handleButtonClick}
          tabs={tabs}
          parentClassName="!px-0"
        />
        {FEATURES.showMyTreeSearch && (
          <div className="flex items-center justify-center gap-3">
            <GlobalSearch
              placeholderText={'Search'}
              value={searchQuery}
              handleOnSearch={handleChange}
              isLoading={getSuperNodeTreeSearchLoading}
              baseClassName="!w-full"
              disabled={getSuperNodeTreeSearchLoading}
            />
            <button
              className={`${!searchQuery || getSuperNodeTreeSearchLoading ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium`}
              type="button"
              onClick={() => {
                resetFilter();
              }}
              disabled={!searchQuery || getSuperNodeTreeSearchLoading}
            >
              Reset
            </button>
          </div>
        )}
      </div>
      <div
        ref={containerRef}
        onScroll={handleScroll}
        className={`w-full h-screen px-4 pb-8 mt-3 overflow-auto bg-white border rounded-lg ${!supernodeTreeChildren && !getSuperNodeTreeChildrenLoading && 'flex flex-col justify-center items-center '}`}
      >
        <div className=" min-w-full md:max-w-[550px] lg:max-w-[1000px] ">
          {selectedButton === 'TreeView' ? (
            <div className="flex flex-col items-center justify-center">
              <TransformWrapper
                limitToBounds={false}
                minScale={0.1}
                smooth={true}
              >
                <div className="relative flex flex-row w-full gap-1">
                  <div className="absolute left-0 z-40 top-3">
                    <div className="flex flex-col items-start justify-center gap-1 p-3 border rounded-lg backdrop-blur-sm bg-slate-100/60">
                      <h1 className="text-xs font-medium">Legend Status</h1>
                      {legendData.map((title, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-center gap-2 text-nowrap"
                        >
                          <ActiveIcon />
                          <InactiveIcon />
                          <span className="text-xs">{title}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="absolute right-0 w-[46.5vw] z-40 flex flex-wrap items-center justify-end gap-3 top-3">
                    <ZoomControls reset={!expand} />
                    <div className="hidden w-px h-10 bg-gray-400 xl:block" />
                    <GlobalButton
                      outlined={expand}
                      text={'Collapse All'}
                      disabled={!expand || supernodeTreeSearch}
                      classNames={`${!expand ? 'bg-primaryGrey' : ''} `}
                      onClick={() => {
                        !expandedFlag && setExpandedFlag(true);
                        dispatch(setTreeExpand(false));
                      }}
                    />
                  </div>
                </div>
                <TransformComponent
                  wrapperStyle={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '96vh',
                  }}
                >
                  {supernodeTreeChildren && (
                    <ListingMembers
                      data={
                        supernodeTreeSearch
                          ? supernodeTreeSearch.user?.children
                          : supernodeTreeChildren?.user
                      }
                      view={selectedButton}
                      key={1}
                    />
                  )}
                  {!supernodeTreeChildren &&
                    !getSuperNodeTreeChildrenLoading && (
                      <div className="flex flex-col justify-center items-center h-full min-h-[250px] gap-2">
                        <img
                          src={NoDataImage}
                          alt="No Data"
                          className="h-auto max-w-full"
                        />
                        <span className={`text-sm text-gray-500 `}>
                          No Data
                        </span>
                      </div>
                    )}
                  {getSuperNodeTreeChildrenLoading &&
                    !supernodeTreeChildren && (
                      <div className="flex justify-center w-full my-9">
                        <Spinner className="w-8 h-8 animate-spin text-primary" />
                      </div>
                    )}
                </TransformComponent>
              </TransformWrapper>
            </div>
          ) : (
            <div>
              {supernodeTreeChildren &&
                supernodeTreeChildren?.user?.children?.length > 0 && (
                  <ListingMembers
                    data={
                      supernodeTreeSearch
                        ? supernodeTreeSearch.user?.children
                        : supernodeTreeChildren?.user?.children
                    }
                    view={selectedButton}
                    key={1}
                    searchQuery={searchQuery}
                  />
                )}
              {supernodeTreeChildren &&
                supernodeTreeChildren?.user?.children?.length <= 0 &&
                !getSuperNodeTreeChildrenLoading && (
                  <div className="flex flex-col justify-center items-center h-full min-h-[250px] gap-2">
                    <img
                      src={NoDataImage}
                      alt="No Data"
                      className="h-auto max-w-full"
                    />
                    <span className={`text-sm text-gray-500 `}>No Data</span>
                  </div>
                )}
            </div>
          )}
        </div>
        {getSuperNodeTreeChildrenLoading && (
          <div className="flex justify-center w-full my-9">
            <Spinner className="w-8 h-8 animate-spin text-primary" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SuperNodeMyTree;
