/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { Suspense } from 'react';
import { OrbitControls, PerspectiveCamera, useGLTF } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import Model from './Model';

useGLTF.preload('/3dmodels/LayerKmodel.glb');

export default function ModelWithCanvas() {
  const [onDrag, setOnDrag] = React.useState(false);

  return (
    <Canvas
      style={{
        height: '100%',
        width: '100%',
        overflow: 'visible !important',
        cursor: onDrag ? 'grabbing' : 'grab',
      }}
      onMouseDown={() => setOnDrag(true)}
      onMouseUp={() => setOnDrag(false)}
    >
      <Suspense fallback={null}>
        <PerspectiveCamera makeDefault position={[1, 1.5, 5]} />
        <OrbitControls enableZoom={false} enableRotate={true} autoRotate />
        <Model scale={[1.8, 1.8, 1.8]} />
        <ambientLight args={['#ffffff', 2]} />
        <directionalLight args={['#ffffff', 6]} position={[0, 4, 1]} />
      </Suspense>
    </Canvas>
  );
}
