import React, { useEffect, useState } from 'react';
import {
  capitalizeFirstLetter,
  toFixedWithoutRoundingOff,
  truncateToSixDecimals,
} from '../../../utils/utils';
import { profileImg } from '../../../images';
import Tooltips from '../../../components/Tooltips';
import { InfoIcon } from '../../../images/svgs';

export interface TreeCardProps {
  cardname?: string;
  name: string;
  level: number | null;
  rewardLevel: number | null;
  bid: number | null;
  type?: string | null;
  amount?: number;
  percentage?: number;
  loss?: number;
  receivable?: boolean;
  reason?: string[];
  lossReason?: string;
  length?: number;
  number?: number;
  selectedUserbid: number;
  isMachingBonus?: boolean;
  profilePicture?: string | null;
  baseLevel?: boolean;
  nodeKAmount?: number;
}
function TreeCard({
  cardname,
  length,
  name,
  level,
  rewardLevel,
  bid,
  type,
  amount,
  percentage,
  receivable,
  loss = 0,
  reason,
  lossReason,
  isMachingBonus = false,
  number,
  selectedUserbid,
  profilePicture,
  baseLevel,
  nodeKAmount,
}: TreeCardProps) {
  return (
    <div className="flex items-center gap-0">
      <div className="flex flex-col items-center h-full">
        <div
          className={`flex-grow w-[2px] bg-primary py-10  md:py-4 lg:py-2.5  ${number === 0 ? 'invisible' : ''}`}
        >
          <span className="opacity-0 select-none">|</span>
        </div>

        <div
          className={`bg-white border border-borderGray relative p-1 h-10 w-10 md:w-12 md:h-12 ${baseLevel === true && 'border-white'} rounded-full`}
        >
          {level === 0 ? (
            <div className="absolute top-0 flex items-center justify-center p-1 bg-white border rounded-lg -left-7">
              <div className="px-5 py-1 text-white border rounded-lg bg-primary">
                LEVEL
              </div>
            </div>
          ) : (
            <div
              className={`flex items-center justify-center bg-primary rounded-full text-white text-center w-full h-full p-3`}
            >
              <p className="text-xs font-medium md:text-lg">{level}</p>
            </div>
          )}
        </div>

        <div
          className={`flex-grow w-[2px] bg-primary py-10 md:py-4 lg:py-2.5 ${
            length === (level && level + 1) ? 'invisible' : ''
          }`}
        >
          <span className="opacity-0 select-none">|</span>
        </div>
      </div>

      <div
        className={`w-16 h-[2px] ${
          level === 0
            ? 'bg-[#5BAC0A]'
            : isMachingBonus && receivable
              ? 'bg-[#913BFF]'
              : (level && level + 1) === length
                ? 'bg-primary'
                : loss > 0 && !receivable
                  ? 'bg-[#b3b3b3]'
                  : !receivable && loss === 0
                    ? 'bg-[#b3b3b3]'
                    : isMachingBonus && receivable
                      ? 'bg-[#913BFF]'
                      : 'bg-primaryLight'
        }`}
      ></div>

      <div
        className={`rounded-md 
                ${
                  level === 0
                    ? 'bg-[#5BAC0A]'
                    : isMachingBonus && receivable
                      ? 'bg-[#913BFF]'
                      : (level && level + 1) === length
                        ? 'bg-primary'
                        : loss > 0 && !receivable
                          ? 'bg-[#b3b3b3]'
                          : !receivable && loss === 0
                            ? 'bg-[#b3b3b3]'
                            : isMachingBonus && receivable
                              ? 'bg-[#913BFF]'
                              : 'bg-primaryLight'
                } 
                flex-grow`}
      >
        <div
          className={`${
            level === 0
              ? 'bg-[#5BAC0A]'
              : isMachingBonus && receivable
                ? 'bg-[#913BFF]'
                : (level && level + 1) === length
                  ? 'bg-primary'
                  : loss > 0 && !receivable
                    ? 'bg-[#b3b3b3]'
                    : !receivable && loss === 0
                      ? 'bg-[#b3b3b3]'
                      : isMachingBonus && receivable
                        ? 'bg-[#913BFF]'
                        : 'bg-primaryLight'
          } rounded-md p-[1px]`}
        >
          <p className="text-sm text-white px-4 py-1.5 font-medium w-auto uppercase">
            {level === 0
              ? `Me -  ${
                  cardname &&
                  capitalizeFirstLetter(
                    cardname
                      .replace(/-/g, ' ')
                      .replace(/\b\w/g, (char) => char.toUpperCase()) ?? ''
                  )
                }`
              : (level && level + 1) === length
                ? 'REWARD GENERATED'
                : !receivable && loss === 0
                  ? 'INACTIVE'
                  : loss > 0 && !receivable
                    ? 'Not Eligible'
                    : isMachingBonus && receivable
                      ? 'MATCHING BONUS'
                      : cardname &&
                        capitalizeFirstLetter(
                          cardname
                            .replace(/-/g, ' ')
                            .replace(/\b\w/g, (char) => char.toUpperCase()) ??
                            ''
                        )}
          </p>

          <div
            className={`flex flex-wrap items-stretch justify-between bg-white rounded-md`}
          >
            <div className="flex items-center gap-2 p-4 w-[200px]">
              <img
                // src={profilePicture ? profilePicture : profileImg}
                src={profileImg}
                alt="user"
                className="w-12 h-12 rounded-full"
              />
              <div className="flex flex-col gap-2">
                <span className="text-xs">{name}</span>
                <span className="text-xs text-primaryGrey">BID: {bid}</span>
              </div>
            </div>
            {loss > 0 && !receivable ? (
              ''
            ) : (
              <div className="flex flex-col justify-center gap-2 px-4 py-2">
                {length === (level && level + 1) && (
                  <div className="flex items-center gap-3">
                    <p className="text-xs">NodeK Reward</p>
                    <p className="text-xs md:text-sm font-medium text-left text-[#707787] min-w-[100px]">
                      $ {Number(truncateToSixDecimals(nodeKAmount ?? 0, 6))}
                    </p>
                  </div>
                )}
                {level === 0 || (level && level + 1) === length ? (
                  <>
                    <div className="flex items-center gap-3">
                      <p className="text-xs">Percentage</p>
                      <p className="text-xs md:text-sm font-medium text-left text-[#707787] min-w-[100px]">
                        {percentage}%
                      </p>
                    </div>

                    <div className="flex flex-wrap items-center gap-2">
                      <div className="flex items-center gap-3">
                        <p className="text-xs">
                          {loss > 0 && !receivable
                            ? 'Amount'
                            : baseLevel === true
                              ? 'Nodek Reward'
                              : isMachingBonus
                                ? 'Matching Bonus'
                                : cardname &&
                                  capitalizeFirstLetter(
                                    cardname
                                      .replace(/-/g, ' ')
                                      .replace(/\b\w/g, (char) =>
                                        char.toUpperCase()
                                      ) ?? ''
                                  )}
                        </p>
                        <p className="text-xs md:text-sm font-medium  text-left text-[#707787] min-w-[100px]">
                          $ {truncateToSixDecimals(amount ?? 0)}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                {/* Tag and Value Row */}
              </div>
            )}

            {loss > 0 && !receivable && reason && (
              <div className="flex flex-col justify-center px-4">
                {lossReason && (
                  <div className="flex items-center gap-3 py-2">
                    <p className="text-xs">Reason</p>
                    <p className="text-xs font-medium text-left text-[#707787] text-wrap w-[150px] capitalize">
                      {lossReason}
                    </p>
                  </div>
                )}

                {reason && reason?.length > 1 && (
                  <div className="flex items-center gap-3 py-2">
                    <Tooltips
                      placement="right-end"
                      content={
                        <div className="p-4 bg-white rounded-lg">
                          <span className="text-xs font-semibold">
                            Other Reasons:
                          </span>
                          {reason.map((d, i) => (
                            <li key={i} className="text-xs capitalize">
                              {d?.replace(/-/g, ' ')}
                            </li>
                          ))}
                        </div>
                      }
                    >
                      <div className="flex items-center gap-2">
                        <p className="text-xs">Other Reasons</p>{' '}
                        <InfoIcon className="w-5 h-5" />
                      </div>
                    </Tooltips>
                  </div>
                )}
              </div>
            )}
            {length === (level && level + 1) && (
              <div
                className={`${
                  level === 0
                    ? 'bg-gradient-primary-green'
                    : isMachingBonus && receivable
                      ? 'bg-gradient-primary-purple'
                      : (level && level + 1) === length
                        ? 'bg-gradient-primary-tree-blue'
                        : 'bg-gradient-primary-tree-blueLight'
                } text-white px-3 py-1.5 text-center rounded-br-md rounded-tr-md flex flex-col justify-center w-full md:w-auto ${!receivable || (!receivable && loss > 0) || level === 0 ? 'hidden' : ''}`}
              >
                <p className="text-xs md:text-sm">Reward Level</p>
                <p className="text-xs md:text-xl">{rewardLevel}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TreeCard;
