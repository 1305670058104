import { memo, useEffect, useState } from 'react';
import CloudKTable from '../../../../Common/CloudKTable/CloudKTable';
import { MinterDetails } from '../../../../Common/types';
import { useCloudkActions } from '../../../../../../../store/cloudk/cloudkAction';
import { id } from 'date-fns/locale';
import {
  capitalizeFirstLetter,
  toFixedFormat,
} from '../../../../../../../utils/utils';
import moment from 'moment';
import { mapStatusColor } from '../../../../../../../utils/statusColors';
import { useNavigate } from 'react-router-dom';
import { EmptyHardwareList } from '../../../../../../../images/wallets';

const columns = [
  { label: 'License Name', key: 'licenseName' },
  { label: 'NFT Weightage', key: 'licenseNftWeightage' },
  { label: 'Status', key: 'status' },
  { label: 'Date', key: 'date' },
];

const MinterConnections = ({
  minter,
  refreshConnections,
}: {
  minter: MinterDetails;
  refreshConnections: any;
}) => {
  const [loading, setLoading] = useState(true);
  const [connections, setConnections] = useState([]);
  const { getCloudKHardwareTransactionHistoryV2 } = useCloudkActions();
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      setLoading(true);
      const response = await getCloudKHardwareTransactionHistoryV2(
        'hardware_connections',
        { limit: 4 },
        minter.identifier
      );
      if (response.data?.data?.list) {
        setConnections(response.data?.data?.list);
      }
      setLoading(false);
    })();
  }, [refreshConnections]);

  const rows = connections.map((connection: any) => ({
    id: connection._id,
    licenseName: connection.licenseName,
    licenseNftWeightage: connection.licenseNftWeightage,
    status: (
      <span
        className="px-3 py-1 text-xs font-medium rounded-lg"
        style={mapStatusColor(connection.status)}
      >
        {capitalizeFirstLetter(connection.status)}
      </span>
    ),
    date: moment(connection.actionDate).format('lll'),
  }));

  const NoDataCompoent = (
    <div className="flex flex-col items-center w-full mt-3">
      <img
        src={EmptyHardwareList}
        alt="empty transactions"
        className="h-auto w-28"
      />
      <span className="mt-4 text-cardSecondaryText">No connections found.</span>
    </div>
  );
  return (
    <div className="">
      <CloudKTable
        loading={loading}
        columns={columns}
        rows={rows}
        onlyTable
        wrapperClassNames="!border-0 !p-0 !shadow-none"
        skeletonRows={3}
        renderNoData={NoDataCompoent}
      />
      <div className="mt-5 text-end">
        <button
          onClick={() =>
            navigate(
              `/defi-360/cloudk/reward-history?type=Hardware_Connections&minterId=${minter.identifier}`
            )
          }
          className="text-sm underline text-primary"
        >
          View all connections history
        </button>
      </div>
    </div>
  );
};

export default memo(MinterConnections);
