import { memo, useEffect, useState } from 'react';
import CloudKSelect from '../../../../Common/CloudKSelect/CloudKSelect';
import moment from 'moment';
import { useCloudkActions } from '../../../../../../../store/cloudk/cloudkAction';
import classNames from 'classnames';
import GlobalSkeleton from '../../../../../../../components/Global/GlobalSkeleton';

const months = moment.months().map((month, index) => ({
  value: (index + 1).toString(),
  label: month,
}));
const currentYear = moment().year();
const currentMonth = moment().month() + 1;

const years = Array.from({ length: 11 }, (_, i) => ({
  value: (currentYear - i).toString(),
  label: (currentYear - i).toString(),
}));

interface Activity {
  day: number;
  status: string;
}
const MinterDailyActivities = ({ minter }: any) => {
  const [month, setMonth] = useState(currentMonth.toString());
  const [year, setYear] = useState(currentYear.toString());
  const [activities, setActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState(false);
  const { getDailyMinterActivityFunc } = useCloudkActions();

  useEffect(() => {
    (async function () {
      setLoading(true);
      const response = await getDailyMinterActivityFunc({
        month: month,
        year: year,
        id: minter._id,
      });
      if (response.data.data?.[0]) {
        setActivities(response.data.data?.[0]?.daysInMonth);
      }
      setLoading(false);
    })();
  }, [month, year]);

  const renderLoading = Array(31)
    .fill(null)
    .map((_) => (
      <GlobalSkeleton
        width={24}
        height={24}
        className="bg-borderGray !transform-none"
        animationValue="wave"
      />
    ));
  return (
    <div className="p-4 border rounded-xl">
      <div className="flex flex-wrap items-start justify-between">
        <div className="">
          <div className="mb-2 text md:mb-8 text-cardSecondaryText">
            Minter's Daily Activity
          </div>
          <div className="flex text-[#939393] text-xs gap-8">
            <div className="flex items-center gap-1">
              <div className="w-3 h-3 rounded-sm bg-borderGray" />
              Non Eligible
            </div>
            <div className="flex items-center gap-1">
              <div className="w-3 h-3 bg-[#D4FBB9] rounded-sm" />
              Eligible
            </div>
          </div>
        </div>
        <div className="flex gap-2 mt-3 md:mt-0">
          <CloudKSelect
            placeholder="Month"
            options={months}
            size="sm"
            selectedValue={month}
            onChange={(op) => setMonth(op.value)}
          />
          <CloudKSelect
            placeholder="Year"
            options={years}
            size="sm"
            selectedValue={year}
            onChange={(op) => setYear(op.value)}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-x-4 gap-y-2.5 pt-4">
        {loading
          ? renderLoading
          : activities.map((activity, index) => (
              <div
                key={index}
                className={classNames(
                  'w-6 h-6 text-sm rounded flex items-center justify-center text-secondaryBlack bg-borderGray',
                  {
                    '!bg-[#D4FBB9]':
                      activity.status?.toLowerCase() === 'active',
                  }
                )}
              >
                {activity.day}
              </div>
            ))}
      </div>
    </div>
  );
};

export default memo(MinterDailyActivities);
