import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const Divider = ({ delay = 2000 }: { delay?: number }) => {
  const [randomDelay, setRandomDelay] = useState(0);

  useEffect(() => {
    const setNewRandomDelay = () => {
      setRandomDelay(Math.random() * 3000 + 2000);
    };

    const interval = setInterval(() => {
      setNewRandomDelay();
    }, delay);

    return () => clearInterval(interval);
  }, [randomDelay]);

  return (
    <div className="relative w-full h-[1px] bg-gray-200 overflow-hidden">
      <motion.div
        className="absolute top-0 left-0 h-full w-5 bg-primary/40"
        initial={{ x: '100%' }}
        animate={{ x: ['850px', '-25px'] }}
        transition={{
          duration: 3.5,
          delay: delay / 1000,
          repeat: Infinity,
          repeatType: 'loop',
          ease: 'easeInOut',
        }}
      />
    </div>
  );
};

export default Divider;
