//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LicenseType,
  UserlinkLicenseData,
  UserunlockLicenseData,
} from './cloudkTypes';

export interface CloudkState {
  activeRewardTab: any;
  activeRewardTime: any;
  isGenerationSwitch: boolean;
  totalLicenses: number;
  activeLicenses: number;
  inActiveLicenses: number;
  expiredLicenses: number;
  totalLinked: number;
  activeLinked: number;
  availableLinkedCapacity: number;
  totalStacked: any;
  availableCloudKBalance: number;
  claimableRewardLoading: boolean;
  claimRewardLoading: boolean;
  specificLicenseLoading: boolean;
  rewardAnalyticsGraphLoading: boolean;
  allLicienceLoading: boolean;
  allHardwareLoading: boolean;
  // transactionHistoryLoading: boolean;
  machineRewardHistoryLoading: boolean;
  detailDataLoading: boolean;
  upgradeAbleMachinesLoading: boolean;
  createMintingTokenLoading: boolean;
  connectNewMinterLoading: boolean;
  dailyActivityLoading: boolean;
  deLinkLoading: boolean;
  hardwareClaimLoading: boolean;
  claimableRewardAutoLinkLoading: boolean;
  MinterRewardLoading: boolean;
  allLicencesData: {
    currentPage: number;
    list: Array<LicenseType> | null;
    totalCount: number;
    totalPages: number;
  };
  totalCountLoading: boolean;
  linkLicenseWithMinterLoading: boolean;
  minterLinkedLicensesLoading: boolean;
  allLiciencesData: any[];
  allHArdwaresData: any;
  rewardAnalyticsGraphData: any;
  transactionHistoryData: any;
  machineRewardHistoryData: any;
  upgradeAblesMachinesData: any;
  detailedData: any[];
  dailyActivityData: any;
  claimableRewardData: any;
  allUserLinkLicencesData: {
    totalCount: number;
    list: UserlinkLicenseData[];
    totalPages: number;
    currentPage: number;
  };
  allUserUnLockLicencesData: {
    totalCount: number;
    list: UserunlockLicenseData[];
    totalPages: number;
    currentPage: number;
  };
  allUserUnLockLicienceLoading: boolean;
  allUserLinkLicienceLoading: boolean;
  minterRewardGeneratedData: any;
  rewardGeneratedTokens: any;
  minterLinkedLicenses: any;
  allLiciencesIds: any;
  allLiciencesStatus: any;
  MinterRewards: any;
  machineRewardsHistoryLicensesDropdown: any[];
  allMachinesRewardHistoryLoading: boolean;
  allMachinesRewardHistoryData: any[];
  selectedMintingTokenLicense: any;
  transactionHistoryLoading: boolean;
  hardwareHistoryLoading: boolean;
  transactionHistoryLicenseIdsFilter: [] | null;
  hardwareTransactionHistoryFilters: null | {} | any;
  transactionHistoryStatusesFilter: [] | null;
  hardwareTransactionHistoryStatusesFilter: [] | null;
  transactionHistory: [] | null;
  hardwareHistory: [] | null;
  transactionHistoryTotalPages: number;
  HardwareTransactionHistoryTotalPages: number;
  transactionHistoryType: string | null;
  hardwareHistoryType: string | null;
  transactionHistoryFilters: {} | null;
  hardwareHistoryFilters: {} | null;
  singleLicenseAutoLinkLoadingId: string | null;
}

export const CLOUDK_SLICE_NAME = 'cloudk';

const initialState: CloudkState = {
  activeRewardTab: { key: 'license', label: 'Licenses' },
  activeRewardTime: { key: 'quarterly', label: '3M' },
  claimableRewardData: null,
  isGenerationSwitch: false,
  totalLicenses: 0,
  activeLicenses: 0,
  inActiveLicenses: 0,
  expiredLicenses: 0,
  totalLinked: 0,
  activeLinked: 0,
  availableLinkedCapacity: 50,
  totalStacked: {},
  availableCloudKBalance: 20,
  claimableRewardLoading: false,
  claimRewardLoading: false,
  specificLicenseLoading: false,
  rewardAnalyticsGraphLoading: false,
  allLicienceLoading: false,
  allHardwareLoading: false,
  // transactionHistoryLoading: false,
  machineRewardHistoryLoading: false,
  allMachinesRewardHistoryLoading: false,
  detailDataLoading: false,
  upgradeAbleMachinesLoading: false,
  createMintingTokenLoading: false,
  connectNewMinterLoading: false,
  dailyActivityLoading: false,
  deLinkLoading: false,
  hardwareClaimLoading: false,
  claimableRewardAutoLinkLoading: false,
  allUserUnLockLicienceLoading: false,
  allUserLinkLicienceLoading: false,
  MinterRewardLoading: false,
  MinterRewards: {},
  allLicencesData: {
    currentPage: 0,
    list: null,
    totalCount: 0,
    totalPages: 0,
  },
  allUserLinkLicencesData: {
    currentPage: 0,
    list: [],
    totalCount: 0,
    totalPages: 0,
  },
  allUserUnLockLicencesData: {
    currentPage: 0,
    list: [],
    totalCount: 0,
    totalPages: 0,
  },

  totalCountLoading: false,
  linkLicenseWithMinterLoading: false,
  allLiciencesData: [],
  allHArdwaresData: null,
  rewardAnalyticsGraphData: null,
  transactionHistoryData: [],
  machineRewardHistoryData: [],
  allMachinesRewardHistoryData: [],
  upgradeAblesMachinesData: [],
  detailedData: [],
  dailyActivityData: {},
  minterLinkedLicenses: [],
  minterLinkedLicensesLoading: false,
  minterRewardGeneratedData: [],
  rewardGeneratedTokens: [],
  allLiciencesIds: [],
  allLiciencesStatus: [],
  machineRewardsHistoryLicensesDropdown: [],
  selectedMintingTokenLicense: null,
  // Transaction history
  transactionHistory: null,
  hardwareHistory: null,
  transactionHistoryTotalPages: 1,
  HardwareTransactionHistoryTotalPages: 1,
  transactionHistoryType: null,
  hardwareHistoryType: null,
  transactionHistoryFilters: null,
  hardwareHistoryFilters: null,
  transactionHistoryLoading: false,
  hardwareHistoryLoading: false,
  transactionHistoryLicenseIdsFilter: null,
  transactionHistoryStatusesFilter: null,
  hardwareTransactionHistoryFilters: null,
  hardwareTransactionHistoryStatusesFilter: null,
  singleLicenseAutoLinkLoadingId: null
};

const cloudkSlice = createSlice({
  name: CLOUDK_SLICE_NAME,
  initialState,
  reducers: {
    setCloudkSlice: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setSelectedMintingTokenLicense(state, action: PayloadAction<LicenseType | null>) {
      state.selectedMintingTokenLicense = action.payload;
    },
  },
});

export const cloudkSliceAction = cloudkSlice.actions;
export const { setCloudkSlice, setSelectedMintingTokenLicense } = cloudkSlice.actions;
export const selectCloudSlice = (state: { [CLOUDK_SLICE_NAME]: CloudkState }) =>
  state[CLOUDK_SLICE_NAME];

export default cloudkSlice.reducer;
