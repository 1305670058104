type status =
  | 'completed'
  | 'credited'
  | 'claimed'
  | 'active'
  | 'auto-debit'
  | 'auto-swap'
  | 'unlock'
  | 'claim'
  | 'claimed-collateral'
  | 'failed'
  | 'canceled'
  | 'expired'
  | 'awaiting'
  | 'de-linked'
  | 'linked'
  | 'connected'
  ;
interface statusColor {
  color: string;
  backgroundColor: string;
}
export function mapStatusColor(status: status): statusColor {
  switch (status) {
    case 'completed':
    case 'connected':
    case 'credited':
    case 'active':
    case 'claimed-collateral':
      return { color: '#5BAC0A', backgroundColor: '#5BAC0A1A' };
    case 'linked':
      return { color: '#5BAC0A', backgroundColor: '#5BAC0A1A' };
    case 'auto-debit':
    case 'auto-swap':
      return { color: '#8E510A', backgroundColor: '#FFE6C9' };

    case 'claimed':
    case 'unlock':
      return { color: '#1B9FDA', backgroundColor: '#DAEFFF' };
    case 'awaiting':
      return { color: '#1B9FDA', backgroundColor: '#DAEFFF' };
    case 'claim':
      return { color: '#284f01', backgroundColor: '#cef8a4' };
    case 'expired':
      return { color: '#af4139', backgroundColor: '#F238381A' };

    case 'failed':
    case 'canceled':
      return { color: '#F23838', backgroundColor: '#F238381A' };
    case 'de-linked':
      return { color: '#F23838', backgroundColor: '#F238381A' };

    default:
      return { color: '#000000', backgroundColor: '#f1f5f9' };
  }
}
