import React from 'react';
import { CommunityIconStroke, PercentageUp } from '../../../images/svgs';

function SuperNodeMetrics({
  name,
  value,
  iconName,
}: {
  name: string;
  value: number | string;
  iconName: any;
}) {
  return (
    <div className="min-h-full px-4 py-5 bg-white rounded-lg">
      <div className="flex flex-col justify-between h-full mt-1 space-y-3">
        <img src={iconName} alt={name} className="w-12" />
        <h4 className="font-rubik text-[13px] font-normal">{name}</h4>
        <div className="flex flex-wrap items-center gap-3 text-wrap">
          <CommunityIconStroke className="w-10 md:w-[20%] h-10" />
          <h2 className="font-rubik w-[90px] text-[20px] text-wrap flex-wrap text-center md:text-[1.7vw] font-normal flex items-center">
            {value}
          </h2>
        </div>

        {/* <p className='flex gap-1 items-center font-rubik text-[12px]  font-normal'><span><PercentageUp className='w-7' /></span><span className="text-[#00B69B]">8.5%</span><span className="text-primaryGrey"> Up from Yesterday</span></p> */}
      </div>
    </div>
  );
}
//for red color #F93C65
export default SuperNodeMetrics;
