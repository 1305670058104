// superNodeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IActiveFirstLineUserObject,
  ISuperNodeState,
} from '../../types/superNodes.type';

const initialState: ISuperNodeState = {
  getTotalRewardsLoading: false,
  totalRewards: null,
  rewardChart: [],
  rewardTableData: [],
  getMyFriendsHistoryLoading: false,
  claimRewardLoading: false,
  rewardTreeLoading: false,
  getMyTreeLoading: false,
  myTree: null,
  totalBonus: null,
  getProductionLoading: false,
  getSuperNodeRewardLoading: false,
  superNodeBonusReports: null,
  superNodeBonusReportsLoading: false,
  detailedUser: {
    _id: '',
    blockchainId: '',
    builderReferralBonusEligibility: false,
    createdAt: '',
    dateJoined: '',
    email: '',
    firstName: '',
    isBaseReferralActive: false,
    isBaseReferralEnabled: false,
    isBuilderGeneralEnabled: false,
    isBuilderGenerationActive: false,
    isBuilderGenerationEnabled: false,
    isBuilderReferralEnabled: false,
    isSupernodeActive: false,
    lastLogin: '',
    lastName: '',
    profilePicture: '',
    rewardMultiplier: 0,
    updatedAt: '',
    username: '',
  },
  superNodeProductData: null,
  myTreeData: null,
  getCurrentLossOfRewardsLoading: false,
  currentLossOfRewardsLoss: null,
  currentLossOfRewardsLossFilter: null,
  getRewardAnalyticsLoading: false,
  getTotalProductionSummaryLoading: false,
  getTotalRewardSummaryLoading: false,
  totalRewardSummary: null,
  totalRewardSummaryFilter: null,
  dailySuperNodeRewards: null,
  getSuperNodeTotalRewardsClaimedLoading: false,
  getSngpScoreLoading: false,
  getUserDailyRewardLoading: false,
  getUserChartDetailLoading: false,
  getUserRewardHistoryLoading: false,
  isUserLoading: false,
  RewardSummaryBaseReferral: null,
  RewardSummaryBaseReferralFilter: null,
  RewardSummaryBuilderGenerational: null,
  RewardSummaryBuilderGenerationalFilter: null,
  getRewardSummaryBuilderGenerationalLoading: false,
  getRewardSummaryBaseReferralLoading: false,
  userRewardHistory: {
    currentPage: 1,
    list: [],
    totalCount: 0,
    totalPages: 0,
  },
  userChartDetail: [
    {
      x: '',
      y: null,
    },
  ],
  userDailyReward: {
    totalReward: 0,
    dailyRewards: 0,
    dailyProduction: 0,
    totalProduction: 0,
    token: {
      _id: '',
      name: '',
      symbol: '',
      type: '',
      withdrawType: '',
      color: '',
      networks: [],
      iconUrl: '',
    },
  },
  superNodeRewardsAnalytics: null,
  userActiveStatus: null,
  getTotalGaskLoading: false,
  totalGask: null,
  lastDayGask: null,
  sngpScoreResponse: null,
  getDailySuperNodeRewardsLoading: false,
  superNodeTotalRewardsClaimed: null,
  totalProductionSummaryRewards: null,
  totalProductionSummaryRewardsFilter: null,
  getActiveFirstLineUsersLoading: false,
  activeFirstLineUsers: null,
  activeFirstLineBuilderUsers: null,
  superNodeSuperNodeRewardsLoading: false,
  superNodeSuperNodeRewards: null,
  superNodeTotalRewadsData: null,
  getTotalBonusLoading: false,

  superNodeFilter: {
    limit: 10,
    page: 1,
    type: null,
    fromDate: null,
    toDate: null,
    query: null,
    totalPages: 0,
    totalCount: 0,
  },
};

export const SUPERNODE_SLICE_NAME = 'superNode';

const superNodeSlice = createSlice({
  name: SUPERNODE_SLICE_NAME,
  initialState,
  reducers: {
    setSuperNodeSlice: (
      state,
      action: PayloadAction<Partial<ISuperNodeState>>
    ) => {
      return { ...state, ...action.payload };
    },

    setActiveFirstLineUser: (state, action: PayloadAction<any>) => {
      return { ...state, activeFirstLineUsers: action.payload };
    },
    setActiveFirstLineBuilderUser: (state, action: PayloadAction<any>) => {
      return { ...state, activeFirstLineBuilderUsers: action.payload };
    },
    setsuperNodeTotalRewadsData: (state, action: PayloadAction<any>) => {
      return { ...state, superNodeTotalRewadsData: action.payload };
    },
    setSuperNodeFilter: (state, action: PayloadAction<any>) => {
      return { ...state, superNodeFilter: action.payload };
    },
  },
});

export const {
  setSuperNodeSlice,
  setActiveFirstLineUser,
  setActiveFirstLineBuilderUser,
  setsuperNodeTotalRewadsData,
  setSuperNodeFilter,
} = superNodeSlice.actions;
export const selectSuperNodeSlice = (state: {
  [SUPERNODE_SLICE_NAME]: ISuperNodeState;
}) => state[SUPERNODE_SLICE_NAME];
export default superNodeSlice.reducer;
