import { useEffect, useState } from 'react';
import GlobalButton from '../../../../../components/Global/GlobalButton';
import GlobalSelectMui from '../../../../../components/Global/GlobalSelectMui';
import ApiRequestCloudk from '../../../../../utils/network/ApiRequestCloudk';
import {
  LICENSE_LOCKUP_PERIOD,
  USER_LICENSES_STAKE_UPGRADE_PERIOD,
} from '../../../../../utils/network/ApiEndpoints';
import { toast } from 'react-toastify';
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction';
import { INVALID_TOKEN, UNAUTHORIZED } from '../../../../../utils/constants';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice';
import UpgradeStakeButton from '../../Common/UpgradeStakeButton';
import { mapColumns } from '../../shared/ActivityHistoryTable/utils';

interface lockupPeriodFilterType {
  value: string;
  name: string;
}
const UpgradeStakePeriods = ({
  closeFunc,
  allLicensesOrLinkLicense,
  page,
}: any) => {
  const {
    selectedMintingTokenLicense,
    transactionHistoryType,
    transactionHistoryFilters,
  } = useSelector(selectCloudSlice);
  const [licenseLockupPeriod, setLicenseLockupPeriod] = useState<
    string[] | null
  >(null);
  const [selectedOption, setSelectedOption] =
    useState<lockupPeriodFilterType | null>(null); // Updated type
  const [upgradeStakePeriodLoading, setUpgradeStakePeriodLoading] =
    useState(false);

  const {
    getAllLicenses,
    getAllUserLinkLicenses,
    getSetCloudkSelectedLicense,
    getCloudKTransactionHistoryV2,
  } = useCloudkActions();

  const lockupOptions = licenseLockupPeriod
    ?.filter(
      (period) =>
        period !== selectedMintingTokenLicense?.lockedPeriod &&
        period > selectedMintingTokenLicense?.lockedPeriod
    ) // Exclude the locked period
    ?.map((period) => ({
      value: period,
      name: period === 'max' ? 'Max Period' : `${period} Months`,
    })) as lockupPeriodFilterType[];

  const tabs = Object.keys(mapColumns).map((key) => ({
    label: key.replace('_', ' '),
    key,
  }));

  const getLicenseLockupPeriod = () => {
    return ApiRequestCloudk()
      .request({
        method: 'GET',
        url: LICENSE_LOCKUP_PERIOD,
      })
      .then((response) => {
        const lockupPeriod = response.data?.data.lockupPeriod;
        setLicenseLockupPeriod(lockupPeriod);
        return lockupPeriod;
      })
      .catch((error) => {
        toast.error('Error fetching license lockup Period:', error);
        return error;
      });
  };

  useEffect(() => {
    getLicenseLockupPeriod();
  }, []);

  const upgradeLicenseStakePeriod = async (id: string) => {
    try {
      setUpgradeStakePeriodLoading(true);

      const data = {
        collateralLockedPeriod: selectedOption?.value,
      };

      // API call to upgrade the stake period
      const response = await ApiRequestCloudk().request({
        method: 'PUT',
        url: `${USER_LICENSES_STAKE_UPGRADE_PERIOD}/${id}`,
        data,
      });

      // Fetch all licenses
      try {
        const licensesResponse = await getAllLicenses();
        const allLicenses = licensesResponse?.data?.data?.list;

        // Find the specific license
        const filteredLicense = allLicenses.find(
          (item: any) => item._id === selectedMintingTokenLicense?._id
        );
        getSetCloudkSelectedLicense(filteredLicense);

        getCloudKTransactionHistoryV2(
          transactionHistoryType ?? tabs[0].key,
          transactionHistoryFilters ?? {
            page: 1,
            limit: 10,
            licenseName: '',
            sort: {},
          }
        );
      } catch (licensesError) {
        console.error('Error fetching licenses:', licensesError);
        setUpgradeStakePeriodLoading(false);
      }

      // Fetch user link licenses
      if (!allLicensesOrLinkLicense) {
        await getAllUserLinkLicenses({
          limit: 10,
          page: page,
          status: '',
          date: '',
          query: '',
          fromDate: null,
          toDate: null,
        });
      }

      // Notify success
      if (response.data.status) {
        toast.success('Staking period upgraded successfully!');
      }
      closeFunc();
      setUpgradeStakePeriodLoading(false);
      return response;
    } catch (error: any) {
      // Handle errors
      setUpgradeStakePeriodLoading(false);
      console.error('Error in upgradeLicenseStakePeriod:', error);

      const errorMessage =
        error?.data?.message || 'Something went wrong. Please try again.';
      if (
        error?.response?.status === 401 &&
        (error?.response?.statusText === UNAUTHORIZED ||
          error?.response?.data?.detail === INVALID_TOKEN)
      ) {
        return;
      }
      toast.error(errorMessage);
      setUpgradeStakePeriodLoading(false);
      return error;
    }
  };

  const handleUpdateLockedupPeriod = async () => {
    await upgradeLicenseStakePeriod(selectedMintingTokenLicense?._id)
      .then((response) => {
        if (response.data.status) {
          closeFunc();
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  //   const { availableCloudKBalance } = useSelector(selectCloudSlice);

  return (
    <div className="flex flex-col gap-2  p-6">
      <span className="font-medium text-[#292929]">Selected License</span>
      <span className="font-medium text-[#121212] text-2xl">
        {selectedMintingTokenLicense?.name}
      </span>

      <div className="flex flex-col gap-4 mt-5">
        <div className="flex-1 flex items-start justify-between flex-col gap-5">
          <div className="flex flex-col gap-2 w-full">
            <div className="text-defaultBlack text-sm font-medium">
              Lockup Period :{/* <span className="text-redDanger">*</span> */}
              <span className="text-defaultBlack text-sm ">
                {selectedMintingTokenLicense?.lockedPeriod &&
                  (selectedMintingTokenLicense.lockedPeriod === 'max'
                    ? 'Max period'
                    : `${selectedMintingTokenLicense.lockedPeriod} Months`)}
              </span>
            </div>
            {
              <>
                <GlobalSelectMui
                  required={true}
                  className={`!rounded-[10px]`}
                  options={
                    lockupOptions
                      ? lockupOptions?.map((item: any) => ({
                          ...item,
                          value: item.value,
                          widget: (
                            <div className="flex items-center gap-4">
                              {item.name}{' '}
                              {selectedMintingTokenLicense.lockedPeriod ===
                                '12' && (
                                <div className="flex items-center gap-4">
                                  <div> -</div>
                                  <UpgradeStakeButton text={'Get 60% more'} />
                                </div>
                              )}
                            </div>
                          ),
                        }))
                      : []
                  }
                  selectedOption={selectedOption}
                  setSelectedOption={(option: any) => {
                    setSelectedOption(option);
                  }}
                  placeHolder={'Select a lockup period'}
                  firstAutoSelected={false}
                />
              </>
            }
          </div>
        </div>

        <GlobalButton
          text="Update Changes"
          loading={upgradeStakePeriodLoading}
          type="button"
          disabled={upgradeStakePeriodLoading || !selectedOption}
          classNames={`!w-full rounded-[10px] !text-base !py-[10px] px-5 min-w-max place-item-end 
           ${!upgradeStakePeriodLoading && selectedOption ? '!bg-updateBtnGredient' : 'bg-[#C0D5DF]'} font-medium !text-[#FFFFFF]`}
          onClick={() => handleUpdateLockedupPeriod()}
        />
      </div>
    </div>
  );
};

export default UpgradeStakePeriods;
