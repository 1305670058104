import { useEffect } from 'react';
import { useCloudkActions } from '../../../../store/cloudk/cloudkAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { hardwareMapColumns } from '../shared/ActivityHistoryTable/utils';
import HardwareTransactionHistory from '../CloudOverview/components/HarwareTransactionHistory/HardwareTransactionHistory';

const RewardHistory = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const type = queryParams?.get('type');
   

  const { getMinterLicenses } = useCloudkActions();
  const navigate = useNavigate();
  useEffect(() => {
    getMinterLicenses({ minterId: undefined });
  }, []);

  return (
    <div>
      <div className="mb-11 flex flex-wrap items-center">
        <span onClick={() => navigate('/defi-360/cloudk')} className="text-xl font-baumans cursor-pointer">
          CloudK Overview {'>'} &nbsp;
        </span>
        <span className="text-2xl font-baumans">Hardware Transaction History</span>
      </div>

      {/* <CloudKTable
        // @ts-ignore
        rows={rows || []}
        title={'Hardware Transaction History'}
        columns={hardwareMapColumns[selectedTab]}
        loading={hardwareHistoryLoading}
        sorting={filters.sort}
        onSortChange={(values) =>
         setFilters((prev: any) => ({ ...prev, sort: values }))
       }
        // loading={true}
        // @ts-ignore
        totalPages={HardwareTransactionHistoryTotalPages}
        // @ts-ignore
        currentPage={filters.page}
        // @ts-ignore
        onPageChange={page =>  setFilters((prev: any) => ({ ...prev, page:page }))}
        renderNoData={noDataUI}
        dataRowClass='h-[130px]'
        filters={[
          <GlobalSearch
          placeholderText={'search'}
          value={filters.searchValue || ''}
          handleOnSearch={(e) =>
            setFilters((prev: any) => ({ ...prev, searchValue:e.target.value }) )
          }
          // isLoading={!!searchIsLoading}
          // baseClassName={filterSearchBaseClassName}
          // inputClassName={filterSearchClassName}
          // disabled={searchdisable}
        />,
        <CloudKSelect
        options={rows?.length ?  machineRewardsHistoryLicensesDropdown.map((item) => ({
          label: item.identifier,
          value: item.userlicenseId,
        })) : []}
        placeholder={'Type'}
        onChange={handleTypeChange} // Handle the option change
        selectedOption={filters.selectedType}
      />,
          // <CloudKSelect
          //   options={machineRewardsHistoryLicensesDropdown.map((item) => ({
          //     label: item.identifier,
          //     value: item.userlicenseId,
          //   }))}
          //   placeholder={'License'}
          //   onChange={handleLicenseChange} // Handle the option change
          //   selectedOption={filters.selectedLicense}
          // />,
          <CloudkDatePicker
          placeholder="From Date"
          date={filters.fromDate}
          maxDate={filters.toDate}
          selectsStart={true}
          startDate={filters.fromDate}
          endDate={filters.toDate}
          onDateChange={(date :any) =>
            setFilters((prev: any) => ({ ...prev, fromDate: date }))
          }
        />,
        <CloudkDatePicker
          placeholder="To Date"
          date={filters.toDate}
          minDate={filters.fromDate}
          selectsEnd={true}
          startDate={filters.fromDate}
          endDate={filters.toDate}
          onDateChange={(date :any) =>
            setFilters((prev: any) => ({ ...prev, toDate: date }))
          }
        />,

           <CsvDownload
                             className="flex items-center gap-1.5 text-white bg-primary hover:bg-primary/90 px-3 py-1.5 rounded-lg text-sm cursor-pointer"
                             datas={handleDownloadCSV}
                             filename={selectedTab+'.csv'}
                           >
                             <DownloadIcon className="text-white w-4 h-4" /><span>CSV</span> 
                           </CsvDownload>,
                                 <button
                                 className="text-primary text-xs font-medium h-10 disabled:text-primaryGrey"
                                 onClick={handleResetFilters}
                                 disabled={
                                   !filters.fromDate &&
                                   !filters.licenseName &&
                                   !filters.status &&
                                   !filters.sort
                                 }
                               >
                                 Reset
                               </button>,
        ]}
         tabsFilter={
                          <div className="flex gap-6 lg:-mt-4 mb-10 overflow-auto">
                            {tabs.map((tab) => (
                              <button
                                key={tab.key}
                                className={classNames(
                                  'text-sm border-b-2 border-b-transparent text-primaryGrey flex-shrink-0 text-nowrap',
                                  tab.key == selectedTab &&
                                    'text-secondaryBlack !border-b-primary font-medium'
                                )}
                                onClick={() => {
                                  setSelectedTab(tab.key);
                                  setFilters((prev: any) => ({
                                    ...prev,
                                    status: '',
                                    fromDate:null,
                                    toDate:null,
                                    sort: {},
                                  }));
                                }}
                              >
                                {tab.label}
                              </button>
                            ))}
                          </div>
                        }
      // paginationContainerStyle={'!justify-end'}
      // navigationText={`View All Rewards History`}
      // navigationStyle={``}
      // navigationShow={true}
      // navigationContainerStyle={``}
      /> */}

      <HardwareTransactionHistory
        tabsData={hardwareMapColumns}
        limit={10}
        type={type}
      />
    </div>
  );
};

export default RewardHistory;
