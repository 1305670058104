//API-REQUEST
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import {
  /**/
  WALLET_DATA_WALLETS_LIST,
  WALLET_DATA_NETWORKS_LIST,
  /**/
  WALLET_DATA_WITHDRAW_HISTORY_GET,
  WALLET_DATA_TRANSFER_HISTORY_GET,
  WALLET_DATA_SWAP_REQUEST_GET,
  WALLET_DATA_DEPOSIT_HISTORY_GET,
  /**/
  WALLET_DATA_WITHDRAW_HISTORY_POST,
  WALLET_DATA_TRANSFER_HISTORY_POST,
  WALLET_DATA_SWAP_REQUEST_POST,
  WALLET_DATA_DEPOSIT_HISTORY_POST,
  /**/
  WALLET_DATA_TRX_SETTING,
  WALLET_DATA_ALL_TOKENS,
  WALLET_DATA_FAQS,
  GET_DEPOSIT_ADDRESS,
  WALLET_DATA_SETTINGS,
  VALIDATE_ADDRESS,
  VALIDATE_USER,
  WALLET_GRAPH_DATA,
  WALLET_DATA_SPECIAL_SWAP_REQUEST_POST,
  WALLET_DATA_SPECIAL_SWAP_REQUEST_GET,
  WALLET_DATA_COMBINED_SWAP_REQUEST_GET,
  GET_DEPOSIT_ADDRESS_COUNT,
  WALLET_STAKE_AVAILABLE,
  WALLET_DATA_WALLETS_BALANCE,
} from '../../utils/network/ApiEndpoints';
//REDUX-TOOLKIT
import {
  /**/
  setWalletDataWalletsList,
  setWalletDataNetworksList,
  /**/
  setWalletDataWithdrawHistory,
  setWalletDataTransferHistory,
  setWalletDataWithdrawAndTransferHistory,
  setWalletDataSwapHistory,
  setWalletDataDepositHistory,
  /**/
  setWalletDataTrxSettings,
  setWalletDataAllTokens,
  /**/
  setWalletDataWithdrawFaqs,
  setWalletDataTransferFaqs,
  setWalletDataSwapFaqs,
  setWalletDataDepositFaqs,
  setDepositAddress,
  setCurrentAttemptDepositAddress,
  setMaxAttemptsOfDepositAddress,
  setWithdrawSettings,
  setSwapSettings,
  setDepositSettings,
  setLoading,
  setWalletDataSlice,
  setQRLoader,
  setWalletDataSpecialSwapFaqs,
  setSpecialSwapSettings,
  setWalletDataCombinedSwapHistory,
  setWalletDataSpecialSwapHistory,
  setWalletDataBasedOnSymbol,
  setwalletStakeAvailableLoading,
  setWalletStakeAvailable,
  setDepositStackSettings,
  setIsOnChainWallet,
} from './walletDataSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './../index';
//AXIOS
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import ApiRequestBID from '../../utils/network/ApiRequestBID';
import { constructUrl } from '../../utils/helper';
import { INVALID_TOKEN, ENV_VARS, UNAUTHORIZED } from '../../utils/constants';

type paginateType = {
  page: number;
  limit: number;

  search?: string;
  query?: string | null;
  coin?: string;
  date?: string;
  status?: string;
  toDate?: any;
  fromDate?: any;
  storeRedux?: boolean;
};

export const useWalletDataAction = () => {
  const dispatch = useDispatch<AppDispatch>();

  const getListOfWalletByUserbId = async (bId: string = '') => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${ENV_VARS.WALLEK_SERVICE_URI}/user/${bId}/wallek`,
        headers: {
          Authorization: `Bearer ${ENV_VARS.WALLEK_API_KEY}`,
        },
      })
      .then((response: AxiosResponse) => response)
      .catch((error: any) => error);
  };

  const getWalletDataWalletsList = async () => {
    dispatch(setLoading(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_DATA_WALLETS_LIST,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataWalletsList(response.data.data));
        dispatch(setLoading(false));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getWalletStakeAvailable = async (_id: string) => {
    dispatch(setwalletStakeAvailableLoading(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_STAKE_AVAILABLE,
        params: { wallet: _id },
      })
      .then((response: any) => {
        dispatch(setWalletStakeAvailable(response.data.data));
        dispatch(setwalletStakeAvailableLoading(false));
      })
      .catch((error: any) => error);
  };

  const getWalletDataBasedOnSymbol = async (symbol?: string) => {
    dispatch(setWalletDataSlice({ walletDataBasedOnSymbolloading: true }));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_WALLETS_BALANCE}/${symbol}`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataBasedOnSymbol(response.data.data));
        dispatch(setWalletDataSlice({ walletDataBasedOnSymbolloading: false }));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        dispatch(setWalletDataSlice({ walletDataBasedOnSymbolloading: false }));
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getWalletGraphData = async (timeline = 'monthly') => {
    dispatch(setWalletDataSlice({ graphLoading: true }));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_GRAPH_DATA,
        params: {
          timeline,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataSlice({ walletGraphData: response.data.data }));
        dispatch(setWalletDataSlice({ graphLoading: false }));
        return response;
      })
      .catch((error: any) => {
        dispatch(setWalletDataSlice({ graphLoading: false }));
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getWalletDataNetworksList = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_DATA_NETWORKS_LIST,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataNetworksList(response.data.data));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  // const getWalletDataWithdrawHistory = async ({
  //   page = 1,
  //   limit = 10,
  //   query,
  //   coin,
  //   date,
  // }: paginateType) => {
  //   dispatch(
  //     setWalletDataSlice({
  //       getWalletDataLoading: true,
  //     })
  //   );
  //   return await ApiRequestHomnifi()
  //     .request({
  //       method: 'GET',
  //       url: constructUrl(WALLET_DATA_WITHDRAW_HISTORY_GET, {
  //         page,
  //         limit,
  //         query,
  //         coin,
  //         date,
  //       }),
  //     })
  //     .then((response: AxiosResponse) => {
  //       dispatch(setWalletDataWithdrawHistory(response.data.data));
  //       dispatch(
  //         setWalletDataSlice({
  //           getWalletDataLoading: false,
  //         })
  //       );
  //       return response;
  //     })
  //
  //     .catch((error: any) => {
  //       dispatch(
  //         setWalletDataSlice({
  //           getWalletDataLoading: false,
  //         })
  //       );
  //       if (
  //         error?.response?.status === 401 &&
  //         (error?.response?.statusText === UNAUTHORIZED ||
  //           error?.response?.data?.detail === INVALID_TOKEN)
  //       )
  //         return;
  //       toast.error(error?.data?.message || 'Something went wrong');
  //       return error;
  //     });
  // };

  // const getWalletDataTransferHistory = async ({
  //   page = 1,
  //   limit = 10,
  //   query,
  //   coin,
  //   date,
  // }: paginateType) => {
  //   return await ApiRequestHomnifi()
  //     .request({
  //       method: 'GET',
  //       url: constructUrl(WALLET_DATA_TRANSFER_HISTORY_GET, {
  //         page,
  //         limit,
  //         query,
  //         coin,
  //         date,
  //       }),
  //     })
  //     .then((response: AxiosResponse) => {
  //       dispatch(setWalletDataTransferHistory(response.data.data));
  //       return response;
  //     })
  //     .catch((error: any) => {
  //       if (
  //         error?.response?.status === 401 &&
  //         (error?.response?.statusText === UNAUTHORIZED ||
  //           error?.response?.data?.detail === INVALID_TOKEN)
  //       )
  //         return;
  //       toast.error(error?.data?.message || 'Something went wrong');
  //       return error;
  //     });
  // };
  //
  const getWalletDataWithdrawAndTransferHistory = async ({
    page = 1,
    limit = 10,
    query,
    status,
    date,
    fromDate,
    toDate,
    storeRedux = true,
  }: paginateType) => {
    if (storeRedux) {
      dispatch(
        setWalletDataSlice({
          setWalletDataWithdrawAndTransferHistoryLoading: true,
        })
      );
    }
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_DATA_WITHDRAW_HISTORY_GET,
        params: {
          page,
          limit,
          query,
          status,
          date,
          fromDate,
          toDate,
        },
      })
      .then((response: AxiosResponse) => {
        if (storeRedux) {
          dispatch(setWalletDataWithdrawAndTransferHistory(response.data.data));
          dispatch(
            setWalletDataSlice({
              setWalletDataWithdrawAndTransferHistoryLoading: false,
            })
          );
        }

        dispatch(
          setWalletDataSlice({
            setWalletDataWithdrawAndTransferHistoryLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(
          setWalletDataSlice({
            setWalletDataWithdrawAndTransferHistoryLoading: false,
          })
        );

        return error;
      });
  };
  const getWalletDataSwapHistory = async ({
    page = 1,
    limit = 10,
    query,
    fromDate,
    toDate,
    status,
    date,
    storeRedux = true,
  }: paginateType) => {
    if (storeRedux) {
      dispatch(
        setWalletDataSlice({
          getWalletDataSwapHistoryLoading: true,
        })
      );
    }
    dispatch(
      setWalletDataSlice({
        getWalletDataLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_SWAP_REQUEST_GET}`,
        params: {
          page,
          limit,
          query,
          fromDate,
          toDate,
        },
      })
      .then((response: AxiosResponse) => {
        if (storeRedux) {
          dispatch(setWalletDataSwapHistory(response.data.data));
        }
        dispatch(
          setWalletDataSlice({
            getWalletDataSwapHistoryLoading: false,
          })
        );
        dispatch(
          setWalletDataSlice({
            getWalletDataLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(
          setWalletDataSlice({
            getWalletDataSwapHistoryLoading: false,
          })
        );
        dispatch(
          setWalletDataSlice({
            getWalletDataLoading: false,
          })
        );
        return error;
      });
  };
  const getWalletDataSpecialSwapHistory = async ({
    page = 1,
    limit = 10,
    query,
    fromDate,
    toDate,
    status,
    date,
  }: paginateType) => {
    dispatch(setWalletDataSlice({ specialSwapHistoryLoading: true }));

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_SPECIAL_SWAP_REQUEST_GET}`,
        params: {
          page,
          limit,
          query,
          fromDate,
          toDate,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataSpecialSwapHistory(response.data.data));
        dispatch(setWalletDataSlice({ specialSwapHistoryLoading: false }));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(setWalletDataSlice({ specialSwapHistoryLoading: false }));

        dispatch(
          setWalletDataSlice({
            getWalletDataSwapHistoryLoading: false,
          })
        );
        dispatch(
          setWalletDataSlice({
            getWalletDataLoading: false,
          })
        );
        return error;
      });
  };
  const getWalletDataCombinedSwapHistory = async ({
    page = 1,
    limit = 10,
    query,
    fromDate,
    toDate,
    status,
    date,
  }: paginateType) => {
    dispatch(
      setWalletDataSlice({
        getWalletDataSwapHistoryLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_COMBINED_SWAP_REQUEST_GET}`,
        params: {
          page,
          limit,
          query,
          fromDate,
          toDate,
        },
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataCombinedSwapHistory(response.data.data));
        dispatch(
          setWalletDataSlice({
            getWalletDataSwapHistoryLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(
          setWalletDataSlice({
            getWalletDataSwapHistoryLoading: false,
          })
        );
        dispatch(
          setWalletDataSlice({
            getWalletDataLoading: false,
          })
        );
        return error;
      });
  };
  const getWalletDataDepositHistory = async ({
    page = 1,
    limit = 10,
    query,
    fromDate,
    toDate,
    status,
    date,
    storeRedux = true,
  }: paginateType) => {
    if (storeRedux) {
      dispatch(
        setWalletDataSlice({
          getWalletDataDepositHistoryLoading: true,
        })
      );
    }
    dispatch(
      setWalletDataSlice({
        getWalletDataLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_DEPOSIT_HISTORY_GET}`,
        params: {
          page,
          limit,
          query,
          status,
          date,
          fromDate,
          toDate,
        },
      })
      .then((response: AxiosResponse) => {
        if (storeRedux) {
          dispatch(setWalletDataDepositHistory(response.data.data));
        }
        dispatch(
          setWalletDataSlice({
            getWalletDataDepositHistoryLoading: false,
          })
        );
        dispatch(
          setWalletDataSlice({
            getWalletDataLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(
          setWalletDataSlice({
            getWalletDataDepositHistoryLoading: false,
          })
        );
        dispatch(
          setWalletDataSlice({
            getWalletDataLoading: false,
          })
        );
        return error;
      });
  };

  const postWalletDataWithdrawHistory = async (data: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: WALLET_DATA_WITHDRAW_HISTORY_POST,
        data,
      })
      .then((response: AxiosResponse) => response)
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const postWalletDataTransferHistory = async (data: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: WALLET_DATA_TRANSFER_HISTORY_POST,
        data,
      })
      .then((response: AxiosResponse) => response)
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const postWalletSwap = async (data: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: WALLET_DATA_SWAP_REQUEST_POST,
        data,
      })
      .then((response: AxiosResponse) => response)
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        throw error;
      });
  };
  const postWalletSpecialSwap = async (data: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: WALLET_DATA_SPECIAL_SWAP_REQUEST_POST,
        data,
      })
      .then((response: AxiosResponse) => response)
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        throw error;
      });
  };
  const postWalletDataDepositHistory = async (data: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: WALLET_DATA_DEPOSIT_HISTORY_POST,
        data,
      })
      .then((response: AxiosResponse) => response)
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getWalletDataTrxSettingsByTokenId = async ({
    id,
    type,
  }: {
    id: string;
    type: string;
  }) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `/tokens/${id}${WALLET_DATA_TRX_SETTING}?type=${type}`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataTrxSettings(response.data.data));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getWalletDataAllTokens = async () => {
    dispatch(
      setWalletDataSlice({
        allTokensLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: WALLET_DATA_ALL_TOKENS,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setWalletDataSlice({
            allTokensLoading: false,
          })
        );
        dispatch(setWalletDataAllTokens(response.data.data));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(
          setWalletDataSlice({
            allTokensLoading: false,
          })
        );
        return error;
      });
  };

  const getWalletDataWithdrawFaqs = async () => {
    dispatch(setWalletDataSlice({ withdrawFaqsLoading: true }));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_FAQS}/withdraw`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataWithdrawFaqs(response.data));
        dispatch(setWalletDataSlice({ withdrawFaqsLoading: false }));

        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(setWalletDataSlice({ withdrawFaqsLoading: false }));

        return error;
      });
  };
  const getWalletDataTransferFaqs = async () => {
    dispatch(setWalletDataSlice({ transferFaqsLoading: true }));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_FAQS}/transfer`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataTransferFaqs(response.data));
        dispatch(setWalletDataSlice({ transferFaqsLoading: false }));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(setWalletDataSlice({ transferFaqsLoading: false }));
        return error;
      });
  };
  const getWalletDataSwapFaqs = async () => {
    dispatch(setWalletDataSlice({ swapFaqsLoading: true }));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_FAQS}/swap`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataSwapFaqs(response.data));
        dispatch(setWalletDataSlice({ swapFaqsLoading: false }));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(setWalletDataSlice({ swapFaqsLoading: false }));
        return error;
      });
  };
  const getWalletDataSpecialSwapFaqs = async () => {
    dispatch(setWalletDataSlice({ specialSwapFaqsLoading: true }));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_FAQS}/special-swap`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataSpecialSwapFaqs(response.data));
        dispatch(setWalletDataSlice({ specialSwapFaqsLoading: false }));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(setWalletDataSlice({ specialSwapFaqsLoading: false }));
        return error;
      });
  };
  const getWalletDataDepositFaqs = async () => {
    dispatch(setWalletDataSlice({ depositFaqsLoading: true }));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_FAQS}/deposit`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setWalletDataSlice({ depositFaqsLoading: false }));
        dispatch(setWalletDataDepositFaqs(response.data));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        dispatch(setWalletDataSlice({ depositFaqsLoading: false }));
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getDepositAddress = async ({
    token,
    network,
  }: {
    token: string;
    network: string;
  }) => {
    dispatch(setQRLoader(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${GET_DEPOSIT_ADDRESS}?token=${token}&network=${network}`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setDepositAddress(response.data.data));
        dispatch(setQRLoader(false));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getDepositAddressCount = async ({
    token,
    network,
  }: {
    token: string;
    network: string;
  }) => {
    dispatch(
      setWalletDataSlice({
        maxAttemptsOfDepositAddressLoading: true,
        currentAttemptOfDepositAddressLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${GET_DEPOSIT_ADDRESS_COUNT}?token=${token}&network=${network}`,
      })
      .then((response: AxiosResponse) => {
        const data = response?.data?.data;
        dispatch(
          setWalletDataSlice({
            maxAttemptsOfDepositAddressLoading: false,
            currentAttemptOfDepositAddressLoading: false,
          })
        );
        dispatch(setCurrentAttemptDepositAddress(data?.numberOfAttempts));
        dispatch(setMaxAttemptsOfDepositAddress(data?.totalAttemtps));
        dispatch(setIsOnChainWallet(data?.isOnChainWallet));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(
          setWalletDataSlice({
            maxAttemptsOfDepositAddressLoading: false,
            currentAttemptOfDepositAddressLoading: false,
          })
        );
        return error;
      });
  };

  const postDepositAndStakeRequest = async ({
    token,
    network,
    machine,
  }: {
    token: string;
    network: string;
    machine: string;
  }) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: `/wallets/deposit-and-stake`,
        data: {
          token,
          network,
          machineDetails: [
            {
              machine,
            },
          ],
        },
      })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getWalletDataWithdrawSettings = async (type: string) => {
    dispatch(setWalletDataSlice({ withdrawSettingsLoading: true }));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_SETTINGS}/withdraw/settings?type=${type}`,
      })
      .then((response: AxiosResponse) => {
        if (type === 'external') {
          dispatch(
            setWithdrawSettings({
              withdrawExternalSettings: response.data.data,
            })
          );
        } else {
          dispatch(
            setWithdrawSettings({
              withdrawInternalSettings: response.data.data,
            })
          );
        }

        dispatch(setWalletDataSlice({ withdrawSettingsLoading: false }));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(setWalletDataSlice({ withdrawSettingsLoading: false }));
        return error;
      });
  };
  const getWalletDataSwapSettings = async () => {
    dispatch(setWalletDataSlice({ swapSettingsLoading: true }));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_SETTINGS}/swap/settings`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setSwapSettings(response.data.data));
        dispatch(setWalletDataSlice({ swapSettingsLoading: false }));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(setWalletDataSlice({ swapSettingsLoading: false }));
        return error;
      });
  };
  const getWalletDataSpecialSwapSettings = async (countryCode = '') => {
    dispatch(setWalletDataSlice({ specialSwapSettingsLoading: true }));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_SETTINGS}/special-swap/settings?countryCode=${countryCode}`,
      })
      .then((response: AxiosResponse) => {
        dispatch(setSpecialSwapSettings(response.data.data));
        dispatch(setWalletDataSlice({ specialSwapSettingsLoading: false }));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        dispatch(setWalletDataSlice({ specialSwapSettingsLoading: false }));
        return error;
      });
  };
  const getWalletDataDepositSettings = async (
    type: 'deposit' | 'deposit-and-stake'
  ) => {
    dispatch(
      setWalletDataSlice({
        isWalletDataDepositSettingsLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_SETTINGS}/${type}/settings`,
      })
      .then((response: AxiosResponse) => {
        if (type === 'deposit') {
          dispatch(setDepositSettings(response.data.data));
        } else {
          dispatch(setDepositStackSettings(response.data.data));
        }

        // dispatch(setDepositSettings(response.data.data));
        dispatch(
          setWalletDataSlice({
            isWalletDataDepositSettingsLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        dispatch(
          setWalletDataSlice({
            isWalletDataDepositSettingsLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getWalletDepositStakeRequest = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${WALLET_DATA_SETTINGS}/deposit-and-stake`,
      })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error.status != 404 && error.status != 422) {
          toast.error(error?.data?.message || 'Something went wrong');
        }
        return error;
      });
  };
  const cancelWalletDepositStakeRequest = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'PUT',
        url: `${WALLET_DATA_SETTINGS}/deposit-and-stake/cancel`,
      })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

  const getValidateAddress = async ({
    address,
    network,
  }: {
    address: string;
    network: string;
  }) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${VALIDATE_ADDRESS}?address=${address}&network=${network}`,
      })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        toast.error(error?.data?.message || 'Something went wrong');

        return error;
      });
  };

  const getValidateUser = async (userID: string) => {
    const token = ENV_VARS.TWO_ACCESS_TOKEN;
    return await ApiRequestBID()
      .request({
        method: 'GET',
        url: `${VALIDATE_USER}/${userID}`,
        headers: {
          Authorization: token,
        },
      })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        toast.error(error.response.data.detail || 'User Not Found');
        return error;
      });
  };

  return {
    getListOfWalletByUserbId,
    /**/
    getWalletDataWalletsList,
    getWalletGraphData,
    getWalletDataNetworksList,
    getWalletDataBasedOnSymbol,
    /**/
    // getWalletDataWithdrawHistory,
    // getWalletDataTransferHistory,
    getWalletDataWithdrawAndTransferHistory,
    getWalletDataSwapHistory,
    getWalletDataSpecialSwapHistory,
    getWalletDataCombinedSwapHistory,
    getWalletDataDepositHistory,
    /**/
    postWalletDataWithdrawHistory,
    postWalletDataTransferHistory,
    postWalletSwap,
    postWalletSpecialSwap,
    postWalletDataDepositHistory,
    /**/
    getWalletDataTrxSettingsByTokenId,
    getWalletDataAllTokens,
    /**/
    getWalletDataWithdrawFaqs,
    getWalletDataSpecialSwapFaqs,
    getWalletDataTransferFaqs,
    getWalletDataSwapFaqs,
    getWalletDataDepositFaqs,
    /**/
    getDepositAddress,
    postDepositAndStakeRequest,
    getWalletDepositStakeRequest,
    cancelWalletDepositStakeRequest,
    getDepositAddressCount,
    /**/
    getWalletDataWithdrawSettings,
    getWalletDataSwapSettings,
    getWalletDataSpecialSwapSettings,
    getWalletDataDepositSettings,
    /**/
    getValidateAddress,
    getValidateUser,
    getWalletStakeAvailable,
  };
};
