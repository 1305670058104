import { useEffect, useState } from 'react';
import CloudKTable from '../../../Common/CloudKTable/CloudKTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCloudSlice,
  setCloudkSlice,
} from '../../../../../../store/cloudk/cloudkSlice';
import { EmptyHardwareList } from '../../../../../../images/wallets';
import GlobalSearch from '../../../../../../components/Global/GlobalSearch';
import CloudKSelect from '../../../Common/CloudKSelect/CloudKSelect';
import CloudkDatePicker from '../../../Common/CloudKDatepicker/CloudkDatepicker';
import CsvDownload from 'react-csv-downloader';
import { DownloadIcon } from '../../../../../../images/svgs';
import classNames from 'classnames';
import ApiRequestCloudk from '../../../../../../utils/network/ApiRequestCloudk';
import { CLOUDK_HARDWARE_TRANSACTION_HISTORY_V2 } from '../../../../../../utils/network/ApiEndpoints';
import { useCloudkActions } from '../../../../../../store/cloudk/cloudkAction';
import moment from 'moment';
import { formatStatusToDispaly } from '../../../../../../utils/utils';
import { useParams } from 'react-router-dom';
import useQueryParams from '../../../../../../hooks/useQueryParams';
import { mapColumns } from '../../../shared/ActivityHistoryTable/utils';

const HardwareTransactionHistory = ({
  limit = 10,
  tabsData,
  minter = {},
  singleMinter = false,
  navigationText = '',
  detailModal = false,
  dataRowClass = '',
  type = '',
}: any) => {
  const dispatch = useDispatch();
  const [
    hardwareTransactionHistoryFilters,
    setHardwareTransactionHistoryFilters,
  ] = useState<any | {}>({});
  const [hardwareHistory, setHardwareHistory] = useState([]);
  const [hardwareHistoryLoading, setHardwareHistoryLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const { minterId } = useQueryParams(['minterId']);
  const tabs = Object.keys(tabsData).map((key) => ({
    label: key.replace('_', ' '),
    key,
  }));

  const {
    getCloudKHardwareTransactionHistoryV2,
    getCloudKHardwareTransactionHistoryFilters,
  } = useCloudkActions();
  const [filters, setFilters] = useState<any>({
    page: 1,
    limit: 10,
    sort: {},
    query: '',
    minterName: minterId,
  });
  const [selectedTab, setSelectedTab] = useState(type ? type : tabs[0].key);
  // const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    // Mark the component as mounted
    // setDidMount(true);
    return () => {
      setFilters({
        ...filters,
        limit: 10,
      });
    };
  }, []);

  useEffect(() => {
    // if (didMount) {
    const fetchFilters = async () => {
      const res = await getCloudKHardwareTransactionHistoryFilters(
        selectedTab.toLowerCase()
      );
      setHardwareTransactionHistoryFilters(res?.data?.data);
    };
    fetchFilters();
    // }
  }, [selectedTab]);
  useEffect(() => {
    // if ( didMount) {
    const fetchData = async () => {
      let obj;
      if (selectedTab === 'Hardware_Activities') {
        obj = {
          status: null,
          hardwareStatus: filters?.status?.value,
        };
      } else {
        obj = {
          hardwareStatus: null,
          status: filters?.status?.value,
        };
      }
      setHardwareHistoryLoading(true);
      const res = await getCloudKHardwareTransactionHistoryV2(
        selectedTab,
        {
          ...filters,
          licenseName: filters?.licenseName?.value,
          ...obj,
        },
        singleMinter ? minter?.identifier : ''
      );

      setHardwareHistory(res?.data?.data?.list);
      setTotalPages(res?.data?.data?.totalPages);
      setHardwareHistoryLoading(false);
    };
    // setHardwareTransactionHistoryFilters(filters)
    // dispatch(setCloudkSlice({ hardwareHistoryFilters: filters }));

    fetchData();
    // }
  }, [selectedTab, filters]);
  const handleDownloadCSV = async () => {
    const type = selectedTab.toLocaleLowerCase();
    let obj;
    if (selectedTab === 'Hardware_Activities') {
      obj = {
        status: null,
        hardwareStatus: filters?.status?.value,
      };
    } else {
      obj = {
        hardwareStatus: null,
        status: filters?.status?.value,
      };
    }
    const response = await ApiRequestCloudk().get(
      `${CLOUDK_HARDWARE_TRANSACTION_HISTORY_V2}/${type}`,
      {
        params: {
          ...filters,
          licenseName: filters?.licenseName?.value,
          ...obj,
          limit: 999999999,
          page: 1,
        },
      }
    );

    let data = [];

    const tableColumns = tabsData[selectedTab];
    // renderRowObject(value)

    data = response.data?.data?.list?.map((value: any) => {
      const row: any = {};
      tableColumns?.forEach((column: any) => {
        // Use mapContent if it exists, otherwise use the key directly
        row[column?.label] = value[column.key];
      });
      return row;
    });

    if (data) {
      return Promise.resolve(data);
    }
  };
  const handleTypeChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    setFilters((prev: any) => ({
      ...prev,
      status: selectedOption,
    }));
  };
  const handleLicenseChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    setFilters((prev: any) => ({
      ...prev,
      licenseName: selectedOption,
    }));
  };
  const handleMinterChange = (selectedOption: {
    label: string;
    value: string;
  }) => {
    setFilters((prev: any) => ({
      ...prev,
      minterName: selectedOption.value,
    }));
  };
  function mapLicenseFilterOptions() {
    return (hardwareTransactionHistoryFilters?.licenseList ?? []).map(
      (license: any) => ({
        label: formatStatusToDispaly(license.identifier),
        value: license.identifier,
      })
    );
  }

  function mapMinterFilterOptions() {
    return (hardwareTransactionHistoryFilters?.minterList ?? []).map(
      (minter: any) => ({
        label: formatStatusToDispaly(minter.identifier),
        value: minter.identifier,
      })
    );
  }

  function mapStatusFilterOptions() {
    return (hardwareTransactionHistoryFilters?.status ?? []).map(
      (stat: any) => ({
        label: formatStatusToDispaly(stat.replace('_', ' ').replace('-', ' ')),
        value: stat,
      })
    );
  }

  const handleResetFilters = () => {
    setFilters({ page: 1, limit: limit });
    dispatch(
      setCloudkSlice({
        hardwareHistoryFilters: { page: 1, limit: 10 },
      })
    );
  };
  const rows = (hardwareHistory ?? [])
    .slice(0, limit)
    .map((transaction: any) => renderRowObject(transaction));
  function renderRowObject(transaction: any): any {
    const tableColumns = tabsData[selectedTab];
    const rowObject = { ...transaction, id: transaction._id };

    tableColumns?.forEach((column: any) => {
      const key = column.key;
      const mapContent = column.mapContent;

      // Check if mapContent exists for the column, and apply it if so
      if (mapContent && transaction[key]) {
        rowObject[key] = mapContent(transaction[key], rowObject);
      } else {
        rowObject[key] = transaction?.[key] ?? '-'; // Just assign the value if no mapContent function
      }
    });
    return rowObject;
  }
  const noDataUI = (
    <div className="flex items-center justify-center flex-1 w-full mt-5">
      <div className="flex flex-col items-center gap-2 ">
        <div className="h-auto w-36">
          <img src={EmptyHardwareList} alt="empty Hardware" />
        </div>
        <div className="flex flex-col items-center gap-1">
          <span className="text-primary xxs:text-sm  sm:text-[16px] font-medium">
            Sorry, No Hardware History Found.
          </span>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <CloudKTable
        title={'Hardware Transaction History'}
        dataRowClass={dataRowClass}
        loading={hardwareHistoryLoading}
        currentPage={filters.page}
        totalPages={limit < 10 ? 0 : totalPages}
        sorting={filters.sort}
        onSortChange={(values) =>
          setFilters((prev: any) => ({ ...prev, sort: values }))
        }
        onPageChange={(page) =>
          setFilters((prev: any) => ({ ...prev, page: page }))
        }
        // @ts-ignore
        rows={rows || []}
        columns={tabsData[selectedTab]}
        renderNoData={noDataUI}
        tableBodyStyles={`${detailModal ? 'max-h-80 ' : ''} `}
        // tableBodyStyles={`${detailModal ? '' : ''} overflow-y-scroll`}
        filters={[
          <GlobalSearch
            placeholderText={'search'}
            value={filters.query || ''}
            handleOnSearch={(e: any) =>
              setFilters((prev: any) => ({ ...prev, query: e.target.value }))
            }
            disabled={hardwareHistory?.length || filters.query ? false : true}
            baseClassName="max-w-44"

            // isLoading={!!searchIsLoading}
            // baseClassName={filterSearchBaseClassName}
            // inputClassName={filterSearchClassName}
            // disabled={searchdisable}
          />,
          <CloudKSelect
            options={mapStatusFilterOptions() || []}
            placeholder={'Status'}
            onChange={handleTypeChange} // Handle the option change
            selectedOption={filters.status}
            disabled={hardwareHistory?.length || filters.status ? false : true}
          />,
          <CloudKSelect
            options={mapLicenseFilterOptions() || []}
            placeholder={'License'}
            onChange={handleLicenseChange} // Handle the option change
            selectedOption={filters.licenseName}
            disabled={
              hardwareHistory?.length || filters.licenseName ? false : true
            }
          />,
          singleMinter || (
            <CloudKSelect
              options={mapMinterFilterOptions() || []}
              placeholder={'Minter'}
              onChange={handleMinterChange} // Handle the option change
              selectedValue={filters.minterName}
              disabled={
                hardwareHistory?.length || filters.minterName ? false : true
              }
            />
          ),
          //   <CloudKSelect
          //   options={rows?.length ?  machineRewardsHistoryLicensesDropdown.map((item) => ({
          //     label: item.identifier,
          //     value: item.userlicenseId,
          //   })) : []}
          //   placeholder={'License'}
          //   onChange={handleLicenseChange} // Handle the option change
          //   selectedOption={filters.selectedLicense}
          // />,
          <CloudkDatePicker
            placeholder="From Date"
            date={filters.fromDate}
            maxDate={filters.toDate}
            selectsStart={true}
            startDate={filters.fromDate}
            endDate={filters.toDate}
            onDateChange={(date: any) => {
              setFilters((prev: any) => ({
                ...prev,
                fromDate: new Date(date),
                sort: {
                  [mapColumns[selectedTab]?.find((tab: any) =>
                    tab.label.includes('Date')
                  ).key]: 'asc',
                },
              }));
            }}
            disabled={
              hardwareHistory?.length || filters.fromDate ? false : true
            }
          />,
          <CloudkDatePicker
            placeholder="To Date"
            date={filters.toDate}
            minDate={filters.fromDate}
            selectsEnd={true}
            startDate={filters.fromDate}
            endDate={filters.toDate}
            onDateChange={(date: any) =>
              setFilters((prev: any) => ({
                ...prev,
                toDate: moment(date).endOf('day').toISOString(),
              }))
            }
            disabled={hardwareHistory?.length || filters.toDate ? false : true}
          />,

          // <GetCSVData
          //   disabled={machineRewardHistoryData?.totalPages <= 0}
          //   transactionData={downloadTransaction}
          //   fileName={`CloudkHardwareTransactionhistory.csv`}
          //   classes="flex justify-end"
          //   onclick={() => {
          //     if (machineRewardHistoryData?.list?.length > 0) {
          //       getFilteredApiData().then((data: any) => {
          //         filterDownloadData(data);
          //       });
          //     }
          //   }}
          //   onDownloadReady={() => {
          //     // Callback function to handle any additional logic if needed
          //   }}
          // />,

          <button
            className="h-10 text-xs font-medium text-primary disabled:text-primaryGrey"
            onClick={handleResetFilters}
            disabled={
              !filters.fromDate &&
              !filters.licenseName &&
              !filters.status &&
              !filters.sort &&
              !filters.toDate &&
              !filters.fromDate &&
              !filters.hardwareStatus &&
              !filters.minterName
            }
          >
            Reset
          </button>,
          <CsvDownload
            className={`flex items-center gap-1.5 text-white bg-primary hover:bg-primary/90 px-3 py-1.5 rounded-lg text-sm cursor-pointer ${hardwareHistory?.length ? '' : '!cursor-not-allowed opacity-60'}`}
            datas={handleDownloadCSV}
            filename={selectedTab + '.csv'}
            disabled={hardwareHistory?.length ? false : true}
          >
            <DownloadIcon className="w-4 h-4 text-white" />
            <span>Download</span>
          </CsvDownload>,
        ]}
        tabsFilter={
          <div className="flex gap-6 mb-10 overflow-auto lg:-mt-4">
            {tabs.map((tab) => (
              <button
                key={tab.key}
                className={classNames(
                  'text-sm border-b-2 border-b-transparent text-primaryGrey flex-shrink-0 text-nowrap',
                  tab.key == selectedTab &&
                    'text-secondaryBlack !border-b-primary font-medium'
                )}
                onClick={() => {
                  setSelectedTab(tab.key);
                  handleResetFilters();
                  setFilters((prev: any) => ({
                    ...prev,
                    status: '',
                    fromDate: null,
                    toDate: null,
                    sort: {},
                  }));
                }}
              >
                {tab.label}
              </button>
            ))}
          </div>
        }
        // paginationContainerStyle={'!justify-end'}
        // navigationText={`View All Rewards History`}
        // navigationStyle={``}
        // navigationShow={true}
        // navigationContainerStyle={``}
        footerNavigation={{
          text: navigationText,
          url: '/defi-360/cloudk/reward-history',
        }}
      />
    </>
  );
};

export default HardwareTransactionHistory;
