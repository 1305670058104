import React from 'react';

export default React.memo(function GlobalPageWrapper({
  children,
  containerClass = '',
}: {
  children: React.ReactNode;
  containerClass?: string;
}) {
  return (
    <div
      className={`w-full sm:py-8 mb-20 lg:p-8 p-4  bg-white  mt-12 rounded-xl border border-borderBlue ${containerClass}`}
    >
      {children}
    </div>
  );
});
