import React, { useState } from 'react';
import { ReactComponent as Spinner } from '../../../../../../SVGIcons/spinner.svg';
import { formatNumberWithCommas, toFixedWithoutRoundingOff } from '../../../../../../utils/utils';
import Tooltips from '../../../../../../components/Tooltips';
import LicensePlaceholder from '../../../../../../assets/license-placeholder.png'
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../../../store/cloudk/cloudkSlice';
import { useCloudkActions } from '../../../../../../store/cloudk/cloudkAction';
import { mapColumns } from '../../../shared/ActivityHistoryTable/utils';

type LicenseItemProps = {
  license: any;
  deLinkLoading: boolean;
  deLinkLicenseFunc: (data: { minterId: string; licenseId: string }) => void;
};

const LicenseItem: React.FC<LicenseItemProps> = ({
  license,
  deLinkLoading,
  deLinkLicenseFunc,
}) => {
  const { transactionHistoryType, transactionHistoryFilters } = useSelector(selectCloudSlice)
  const { getCloudKTransactionHistoryV2 } = useCloudkActions();
  const isExpired = new Date(license.licenceExpriedDate) <= new Date();
  const isStakingPeriodExpired = new Date(license.licenceStakeEndDate) <= new Date();
  const tabs = Object.keys(mapColumns).map((key) => ({
    label: key.replace('_', ' '),
    key,
  }));


  const isDefaultLicense = license.defaultLicense;
  const [loading, setLoading] = useState(false)
  const isButtonDisabled =
    isExpired || isStakingPeriodExpired || isDefaultLicense || license.type !== 'LINKED' || (deLinkLoading && loading);
  const handleDeLink = async () => {
    if (license.type === 'LINKED' && !isButtonDisabled) {
      // Set loading to true when the de-linking process starts
      setLoading(true);

      // Prepare data for the de-link operation
      const data = {
        minterId: license?.userMinterId,
        licenseId: license?.userlicenseId,
      };

      try {
        // Call the async de-link function
        await deLinkLicenseFunc(data);
        getCloudKTransactionHistoryV2(transactionHistoryType ?? tabs[0].key, transactionHistoryFilters ?? {
          page: 1,
          limit: 10,
          licenseName: '',
          sort: {},
        })
      } catch (error) {
        console.error('Error during de-linking:', error);
      } finally {
        // Set loading to false after the async operation finishes (success or error)
        setLoading(false);
      }
    }
  };
  return (
    <div className="border border-[#D9E4F1] rounded-lg relative p-3 flex flex-col gap-3">
      <div className="flex justify-center w-full mt-3">
        <img
          src={license.licenseImage?.link || LicensePlaceholder}
          alt={license.identifier}
          className="h-[87px] w-[63px]"
        />
      </div>
      <div className="flex flex-col items-center justify-center w-full gap-1">
        <span className="font-medium text-[#121212] text-xs">{license.licenceName}</span>
        <span className="text-xs text-[#939393]">{license.identifier}</span>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col gap-1">
          <span className="font-medium text-[#121212] text-xs">
            {formatNumberWithCommas(
              Number(toFixedWithoutRoundingOff(Number(license?.totalTokenAmount || 0), 3))
            )}
          </span>
          <span className="text-xs text-[#939393]">Available mLYK</span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="font-medium text-[#121212] text-xs">{license.lockNft}</span>
          <span className="text-xs text-[#939393]">Number of NFTs</span>
        </div>
      </div>

      {/* Loading Spinner */}
      {deLinkLoading && loading ? (
        <div className="flex items-center justify-center flex-1 w-full">
          <Spinner className="h-6 animate-spin" />
        </div>
      ) : (
        <div className="flex items-center justify-center flex-1 w-full">
          <Tooltips
            placement="top"
            containerClass='w-full'

            content={
              isExpired ? (
                <div className="p-3 text-xs font-normal bg-white rounded-lg shadow-md w-52">
                  License expired
                </div>
              ) : isStakingPeriodExpired ? (<div className="p-3 text-xs font-normal bg-white rounded-lg shadow-md w-52">
                Staking period expired
              </div>) : isDefaultLicense ? (
                <div className="p-3 text-xs font-normal bg-white rounded-lg shadow-md w-52">
                  Default license cannot be de-linked
                </div>
              ) : (
                <></>
              )
            }
            btnClass='w-full'
          >
            <button
              onClick={handleDeLink}
              disabled={isButtonDisabled}
              type="button"
              className={`p-4 h-6 rounded-[16px] w-full flex justify-center items-center text-xs gap-1 text-[#FFFFFF]  
          ${isButtonDisabled ? 'bg-[#C0D5DF] cursor-not-allowed' : 'bg-[#00A5EF]'}`}
            >
              {isExpired ? 'Expired' : 'De-link'}
            </button>
          </Tooltips>
        </div>
      )}
    </div>
  );
};

export default LicenseItem;
