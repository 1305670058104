import { useEffect, useState } from 'react';

export const useRandomColor = () => {
  const [randomColor, setRandomColor] = useState<string>('');

  useEffect(() => {
    const generateRandomColor = () => {
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    };
    setRandomColor(generateRandomColor());
  }, []);

  return randomColor;
};
