import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';

interface Props {
  tabs: { key: string; label: string }[];
  onTabChange?: (key: string) => void;
  tabContnets?: { [key: string]: ReactNode };
  minHeight?: number;
}

const CloudKTabs = ({ tabs, onTabChange, tabContnets, minHeight }: Props) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);

  useEffect(() => {
    onTabChange?.(selectedTab);
  }, [selectedTab]);

  return (
    <div className="">
      <div className="flex gap-6 lg:-mt-4 mb-10 overflow-auto">
        {tabs.map((tab) => (
          <button
            key={tab.key}
            className={classNames(
              'text-sm border-b-2 border-b-transparent text-primaryGrey flex-shrink-0 text-nowrap hover:text-secondaryBlack',
              tab.key == selectedTab &&
                'text-secondaryBlack !border-b-primary font-medium'
            )}
            onClick={() => {
              setSelectedTab(tab.key);
            }}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div style={{ minHeight: minHeight || 0 }}>
      {tabContnets && tabContnets[selectedTab]}
      </div>
      
    </div>
  );
};

export default CloudKTabs;
