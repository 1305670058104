import React from 'react';
import { StarIcon } from '../images/svgs';

interface StarRatingProps {
    rating: number;
}

const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
    const stars = Array.from({ length: 5 }, (_, index) => {
        const isFull = index + 1 <= Math.floor(rating);
        const isHalf = !isFull && index + 0.5 <= rating;

        return (
            <div
                key={index}
                className={`w-6 h-6 mr-1 ${isFull ? 'bg-trustPiotGreen' : isHalf ? 'bg-[linear-gradient(to_right,_#00b67a_50%,_#e5e7eb_50%)]' : 'bg-gray-200'
                    } flex items-center justify-center`}
            >
                <StarIcon />
            </div>
        );
    });

    return <div className="flex">{stars}</div>;
};

export default StarRating;
